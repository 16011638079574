import { useAuth0 } from '@auth0/auth0-react';
import { useAbility } from '@casl/react';
import {
    faChurch,
    faGear,
    faRightFromBracket,
    faRightToBracket,
    faSchool,
    faUsers,
} from '@fortawesome/pro-light-svg-icons';
import {
    faBars,
    faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    Link,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import MCOWhiteLogoSvg from '../../../public/svg/MyCommunityOnline_Logo-white.svg';
import POLWhiteLogoSvg from '../../../public/svg/ParishesOnline_Logo-white.svg';

import {
    canViewManage,
    handleLogIn,
} from '../../auth/authHelpers';
import { AbilityContext } from '../../auth/Can';
import * as routes from '../../constants/routes';
import {
    COLORS,
    POL_THEME,
    ThemeContext,
} from '../../constants/theme';
import { Titles } from '../../constants/titles';
import { AppContext } from '../../context/AppContext';
import { useWindowSize } from '../../utils/hooks';

import Button from '../shared/Button/Button';
import Drawer from '../shared/Drawer';
import { Dropdown } from '../shared/Dropdown';
import ModalDialog from '../shared/ModalDialog/Base/ModalDialog';
import SearchForm from '../shared/SearchForm';

import styles from './AppBar.scss';

// Background Images
const POL_TOPBAR_IMG = `${process.env.CUSTOMER_DATA_S3_URL}/global/ParishesOnline-topbar_bg.jpg`;
const MCO_TOPBAR_IMG = `${process.env.CUSTOMER_DATA_S3_URL}/global/MyCommunityOnline-topbar_bg.jpg`;

const AppBar = ({
    isSearchPage,
}) => {
    const ability = useAbility(AbilityContext);
    const {
        isOwnershipRequest,
        setOwnershipRequest,
    } = useContext(AppContext);
    const themeContext = useContext(ThemeContext);

    const {
        isAuthenticated,
        isLoading,
        loginWithPopup,
        loginWithRedirect,
        getAccessTokenSilently,
        logout,
        user,
        error,
    } = useAuth0();

    const windowWidth = useWindowSize();
    const isSmallScreen = windowWidth < 770;

    const location = useLocation();
    const path = location.pathname;
    const isHomePage = path === routes.HOME;

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const NAV_ITEMS = [
        {
            icon: themeContext !== POL_THEME ? faSchool : faChurch,
            label: themeContext !== POL_THEME ? 'Communities' : 'Parishes',
            route: routes.HOME,
        },
        {
            icon: faUsers,
            label: 'Supporters',
            route: routes.SUPPORTERS,
        },
    ];

    const history = useNavigate();

    const redirect = (pathname) => {
        history(pathname);
    };

    useEffect(() => {
        setIsDrawerOpen(false);
        setIsSearchOpen(false);
    }, [location.pathname, location.search]);

    useEffect(() => {
        if (!error) return;
        switch (error.error_description) {
            case 'Timeout':
                toast.error('Your session has timed out, please login again.', { autoClose: 3000 });
                break;
            case 'Popup closed':
                toast.warning('You are not logged in.', { autoClose: 3000 });
                break;
            case 'Please verify your email address to continue to Online Directory':
            case 'Please verify your email before logging in.':
                if (isOwnershipRequest) {
                    setIsDialogOpen(true);
                } else {
                    toast.success('You are signed up successfully', { autoClose: 3000 });
                    toast.warning('Please verify your email address to log in to Online Directory', {
                        autoClose: 3000,
                        onClose: () => logout({ logoutParams: { returnTo: routes.AUTH_REDIRECT } }),
                    });
                }
                break;
            default:
                toast.error(error.error_description, {
                    autoClose: 3000,
                    onClose: () => logout({ logoutParams: { returnTo: routes.AUTH_REDIRECT } }),
                });
        }
    }, [error, isOwnershipRequest, logout]);

    useEffect(() => {
        if (!isLoading) {
            setOwnershipRequest(false);
        }
    }, [isLoading, setOwnershipRequest]);

    if (isLoading) {
        return null;
    }

    const dropdownButtons = [];

    if (canViewManage(ability)) {
        dropdownButtons.push({
            label: 'Manage',
            icon: faGear,
            callback: () => redirect(routes.SETTINGS),
        });
    }

    dropdownButtons.push({
        label: 'Log Out',
        icon: faRightFromBracket,
        callback: () => logout({ logoutParams: { returnTo: routes.AUTH_REDIRECT } }),
    });

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogDone = () => {
        handleClose();
        logout({ logoutParams: { returnTo: routes.AUTH_REDIRECT } });
    };

    const backgroundImage = themeContext === POL_THEME ? POL_TOPBAR_IMG : MCO_TOPBAR_IMG;

    const getDialogContent = () => {
        if (themeContext !== POL_THEME) {
            return (
                <div>
                    <div style={{ marginBottom: 16 }}>
                        Your editing access is pending! Please allow up to 48 hours for our team to confirm
                        &nbsp;
                        your identity as a staff member within this organization.
                    </div>
                    <b>Please check your email inbox to verify your new My Community Online account.</b>
                </div>
            );
        }
        return (
            <div>
                <div style={{ marginBottom: 16 }}>
                    Your editing access is pending! Please allow up to 48 hours for our team
                    &nbsp;
                    to confirm your identity as a staff member within this parish.
                </div>
                <b>Please check your email inbox to verify your new Parishes Online account.</b>
            </div>
        );
    };

    return (
        <div
            className={styles.container}
            style={{
                backgroundImage: !isSearchPage && `url('${backgroundImage}')`,
            }}
        >
            <div className={styles.barContainer}>
                <div>
                    {isHomePage
                        ? (
                            <h1
                                className={styles.logoLink}
                                aria-label={
                                    themeContext === POL_THEME
                                        ? Titles.POL_HOME
                                        : Titles.MCO_HOME
                                }
                            >
                                {themeContext === POL_THEME
                                    ? <POLWhiteLogoSvg />
                                    : <MCOWhiteLogoSvg />}
                            </h1>
                        )
                        : (
                            <Link
                                to={routes.HOME}
                                className={styles.logoLink}
                                aria-label={
                                    themeContext === POL_THEME
                                        ? Titles.POL_HOME
                                        : Titles.MCO_HOME
                                }
                            >
                                {themeContext === POL_THEME
                                    ? <POLWhiteLogoSvg />
                                    : <MCOWhiteLogoSvg />}
                            </Link>
                        )}
                </div>
                {!isSmallScreen && (
                    <>
                        {!isSearchPage && (
                            <SearchForm
                                classes={{ root: [styles.searchForm] }}
                                isOnDarkBackground
                                uniqueId="AppBar-desktop"
                            />
                        )}
                        <div className={styles.linkContainer}>
                            {NAV_ITEMS.map((currentItem) => (
                                <Link
                                    key={currentItem.label}
                                    className={styles.linkItem}
                                    to={currentItem.route}
                                >
                                    {currentItem.label}
                                </Link>
                            ))}
                            {isAuthenticated && !error ? (
                                <Dropdown
                                    className={styles.linkItem}
                                    label="Account"
                                    buttons={dropdownButtons}
                                />
                            ) : (
                                <button
                                    className={styles.linkItem}
                                    onClick={() => handleLogIn(loginWithPopup, loginWithRedirect, getAccessTokenSilently, logout, user)}
                                    type="button"
                                >
                                    Log In
                                </button>
                            )}
                        </div>
                    </>
                )}
                {isSmallScreen && (
                    <>
                        <div className={styles.iconContainer}>
                            {!isSearchPage && (
                                <button
                                    className={styles.removeBtnStyles}
                                    onClick={() => setIsSearchOpen(!isSearchOpen)}
                                    type="button"
                                    aria-label="Search"
                                >
                                    <FontAwesomeIcon
                                        className={styles.mobileIcon}
                                        data-testid="search-icon"
                                        icon={faMagnifyingGlass}
                                    />
                                </button>
                            )}
                            <button
                                className={styles.removeBtnStyles}
                                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                                type="button"
                                aria-label="Menu"
                            >
                                <FontAwesomeIcon
                                    className={styles.mobileIcon}
                                    data-testid="hamburger-icon"
                                    icon={faBars}
                                />
                            </button>
                        </div>
                        <Drawer
                            open={isDrawerOpen}
                            onClose={() => setIsDrawerOpen(false)}
                            noPadding
                        >
                            <div
                                className={styles.drawerHeaderContainer}
                                style={{
                                    backgroundImage: `url('${backgroundImage}')`,
                                }}
                            >
                                <div className={styles.svgContainer}>
                                    {themeContext === POL_THEME
                                        ? <POLWhiteLogoSvg />
                                        : <MCOWhiteLogoSvg />}
                                </div>
                            </div>
                            <div className={styles.drawerContentContainer}>
                                {NAV_ITEMS.map((currentItem) => (
                                    <Link
                                        key={currentItem.label}
                                        className={styles.drawerItem}
                                        data-testid={`drawer-link-${currentItem.label.replace(/ /g, '-')}`}
                                        to={currentItem.route}
                                        onClick={() => setIsDrawerOpen(false)}
                                        tabIndex={isDrawerOpen ? 0 : -1}
                                    >
                                        <FontAwesomeIcon icon={currentItem.icon} />
                                        {currentItem.label}
                                    </Link>
                                ))}
                                {isAuthenticated && !error
                                    ? (
                                        <>
                                            {(canViewManage(ability)
                                                && (
                                                    <Link
                                                        key="Manage"
                                                        className={styles.drawerItem}
                                                        to={routes.SETTINGS}
                                                        onClick={() => setIsDrawerOpen(false)}
                                                        tabIndex={isDrawerOpen ? 0 : -1}
                                                    >
                                                        <FontAwesomeIcon icon={faGear} />
                                                        Manage
                                                    </Link>
                                                )
                                            )}
                                            <button
                                                className={styles.drawerItem}
                                                onClick={() => logout({ logoutParams: { returnTo: routes.AUTH_REDIRECT } })}
                                                type="button"
                                                disabled={!isDrawerOpen}
                                            >
                                                <FontAwesomeIcon icon={faRightFromBracket} />
                                                Log Out
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            className={styles.drawerItem}
                                            onClick={() => handleLogIn(loginWithPopup, loginWithRedirect, getAccessTokenSilently, logout, user)}
                                            type="button"
                                            disabled={!isDrawerOpen}
                                        >
                                            <FontAwesomeIcon icon={faRightToBracket} />
                                            Log In
                                        </button>
                                    )}
                            </div>
                        </Drawer>
                    </>
                )}
            </div>
            {isSmallScreen && !isSearchPage
                && (
                    <div
                        className={classNames(styles.expandSearchContainer, isSearchOpen && styles.showExpandSearch)}
                        data-testid="expand-search-container"
                        aria-hidden={!isSearchOpen}
                    >
                        <div className={styles.intermediateContainer}>
                            <SearchForm
                                uniqueId="AppBar-mobile"
                                isHidden={!isSearchOpen}
                            />
                        </div>
                    </div>
                )}
            <ModalDialog
                open={isDialogOpen}
                onClose={handleClose}
                title="Account Created"
                content={getDialogContent()}
                buttons={(
                    <>
                        <div />
                        <Button
                            onClick={handleDialogDone}
                            color={COLORS.flat}
                        >
                            Done
                        </Button>
                    </>
                )}
            />
        </div>
    );
};

AppBar.propTypes = {
    isSearchPage: PropTypes.bool,
};

AppBar.defaultProps = {
    isSearchPage: false,
};

export default AppBar;
