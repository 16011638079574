import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-toastify';
import customerDataRequest from '../../services/api/customer-data';
import Loader from '../shared/Loader';
import TextField from '../shared/FormElements/TextField';
import FormField from '../shared/FormElements/FormField';
import Button from '../shared/Button/Button';
import {
    POL_THEME,
    ThemeContext,
    COLORS,
    SIZES,
} from '../../constants/theme';
import styles from './PopupSubscribeForm.scss';

const FORM_FIELD_NAMES = {
    firstName: 'firstName',
    lastName: 'lastName',
    contactEmail: 'contactEmail',
};

const PopupSubscribeForm = ({
    organizationTitle, salesforceId, isPopupOpen, setIsPopupOpen,
}) => {
    const themeContext = useContext(ThemeContext);
    const [isSaving, setIsSaving] = useState(false);
    const bookImageUrl = `${process.env.CUSTOMER_DATA_S3_URL}/subscribe-popup/open-book-turning-page.png`;

    const initialValues = {
        [FORM_FIELD_NAMES.firstName]: '',
        [FORM_FIELD_NAMES.lastName]: '',
        [FORM_FIELD_NAMES.contactEmail]: '',
    };

    const contactSchema = Yup.object().shape({
        [FORM_FIELD_NAMES.firstName]: Yup.string()
            .max(128, 'No longer than 128 characters')
            .min(2, 'Must be at least two characters'),
        [FORM_FIELD_NAMES.lastName]: Yup.string()
            .max(128, 'No longer than 128 characters')
            .min(2, 'Must be at least two characters'),
        [FORM_FIELD_NAMES.contactEmail]: Yup.string()
            .email('Must be a valid email address')
            .max(128, 'Email cannot be longer than 128 characters'),
    });

    const onSubmit = async (values) => {
        setIsSaving(true);

        try {
            await customerDataRequest('post', `/organizations/${salesforceId}/subscribe/`, null, {
                salesforceId,
                fName: values.firstName,
                lName: values.lastName,
                email: values.contactEmail,
                subscriptionSource: 'web',
            });

            setIsSaving(false);
            setIsPopupOpen(false);
            toast.success('Thanks for subscribing!');
        } catch (error) {
            setIsSaving(false);
            toast.error('There was an error subscribing.');
        }
    };

    return (
        isPopupOpen
                && (
                    <Grid
                        className={styles.container}
                        container
                        id="popup-window"
                    >
                        {isSaving && <Loader color={COLORS.secondary} />}
                        <button
                            type="button"
                            className={styles.closeButton}
                            aria-label="Close Window"
                            onClick={() => setIsPopupOpen(false)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <Container maxWidth="lg">
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validationSchema={contactSchema}
                            >
                                {({ errors, touched }) => (
                                    <Form
                                        id="subscribe-popup"
                                    >
                                        <Grid
                                            container
                                            sx={{
                                                alignItems: 'center',
                                            }}
                                            rowSpacing={{
                                                sm: 2,
                                                md: 0,
                                            }}
                                            columnSpacing={{
                                                sm: 0,
                                                md: 3,
                                            }}
                                        >
                                            <Grid
                                                size={{
                                                    xs: 12,
                                                    md: 5,
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    sx={{
                                                        alignItems: 'center',
                                                    }}
                                                    rowSpacing={{
                                                        xs: 0,
                                                    }}
                                                    columnSpacing={{
                                                        xs: 0,
                                                        md: 4,
                                                    }}
                                                >
                                                    <Grid
                                                        size={{
                                                            xs: 0,
                                                            md: 4,
                                                        }}
                                                        display={{
                                                            xs: 'none',
                                                            md: 'block',
                                                        }}
                                                    >
                                                        <img
                                                            className={styles.bookImage}
                                                            src={bookImageUrl}
                                                            alt=""
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        size={{
                                                            xs: 12,
                                                            md: 8,
                                                        }}
                                                    >
                                                        <h2 className={styles.heading}>Sign Up Now</h2>
                                                        {themeContext === POL_THEME ? (
                                                            <h5 className={styles.subHeading}>
                                                                Subscribe here to receive your weekly bulletin from
                                                                {' '}
                                                                {organizationTitle}
                                                                .
                                                            </h5>
                                                        ) : (
                                                            <h5 className={styles.subHeading}>
                                                                Subscribe here to receive your newsletter from
                                                                {' '}
                                                                {organizationTitle}
                                                                .
                                                            </h5>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                size={{
                                                    xs: 12,
                                                    md: 7,
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    sx={{
                                                        alignItems: 'center',
                                                    }}
                                                    rowSpacing={{
                                                        xs: 2,
                                                        md: 0,
                                                    }}
                                                    columnSpacing={{
                                                        xs: 0,
                                                        md: 4,
                                                    }}
                                                >
                                                    <Grid
                                                        className={styles.formFields}
                                                        size={{
                                                            xs: 12,
                                                            md: 8,
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            rowSpacing={{
                                                                xs: 2,
                                                                sm: 0,
                                                            }}
                                                            columnSpacing={{
                                                                xs: 0,
                                                                sm: 2,
                                                            }}
                                                        >
                                                            <Grid
                                                                size={{
                                                                    xs: 12,
                                                                    sm: 6,
                                                                }}
                                                            >
                                                                <FormField
                                                                    label="First Name"
                                                                    component={TextField}
                                                                    name={FORM_FIELD_NAMES.firstName}
                                                                    errors={errors[FORM_FIELD_NAMES.firstName]}
                                                                    touched={touched[FORM_FIELD_NAMES.firstName]}
                                                                    id="popup-name"
                                                                    variant="standard"
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                className={styles.lastNameField}
                                                                size={{
                                                                    xs: 12,
                                                                    sm: 6,
                                                                }}
                                                            >
                                                                <FormField
                                                                    label="Last Name"
                                                                    component={TextField}
                                                                    name={FORM_FIELD_NAMES.lastName}
                                                                    errors={errors[FORM_FIELD_NAMES.lastName]}
                                                                    touched={touched[FORM_FIELD_NAMES.lastName]}
                                                                    id="popup-name"
                                                                    variant="standard"
                                                                    required
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <FormField
                                                            label="Email Address"
                                                            component={TextField}
                                                            name={FORM_FIELD_NAMES.contactEmail}
                                                            errors={errors[FORM_FIELD_NAMES.contactEmail]}
                                                            touched={touched[FORM_FIELD_NAMES.contactEmail]}
                                                            id="popup-email"
                                                            variant="standard"
                                                            marginBottom={false}
                                                            required
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        size={{
                                                            xs: 12,
                                                            md: 4,
                                                        }}
                                                        mb={{
                                                            xs: 1,
                                                            sm: 0,
                                                        }}
                                                    >
                                                        <Button
                                                            size={SIZES.large}
                                                            type="submit"
                                                            classes={{
                                                                root: styles.subscribeButton,
                                                            }}
                                                            upperCase
                                                        >
                                                            Subscribe
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Container>
                    </Grid>
                )
    );
};

PopupSubscribeForm.propTypes = {
    organizationTitle: PropTypes.string.isRequired,
    salesforceId: PropTypes.string.isRequired,
    isPopupOpen: PropTypes.bool.isRequired,
    setIsPopupOpen: PropTypes.func.isRequired,
};

export default PopupSubscribeForm;
