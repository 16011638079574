import React, {
    useState, useEffect,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './Dropdown.scss';

export const Dropdown = ({
    label,
    className = styles.linkItem,
    buttons,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget.lastChild); // Set anchor to chevron icon
        setDropdownIsOpen(true);
    };

    const dropdownResize = () => {
        if (window.innerWidth <= 770) {
            setDropdownIsOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', dropdownResize);
        return () => {
            window.removeEventListener('resize', dropdownResize);
        };
    }, []);

    return (
        <div>
            <button
                className={className}
                onClick={handleClick}
                type="button"
            >
                {label}
                &nbsp;
                <FontAwesomeIcon icon={faChevronDown} />
            </button>
            {
                dropdownIsOpen
                    ? (
                        <div
                            onSelect={() => false}
                            onSelectCapture={() => false}
                        >
                            <Menu
                                anchorEl={anchorEl}
                                open={dropdownIsOpen}
                                onClose={() => setDropdownIsOpen(false)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {buttons.map((button) => {
                                    let icon = '';
                                    if (button.icon) {
                                        icon = (
                                            <FontAwesomeIcon icon={button.icon} className={styles.menuItemIcon} />
                                        );
                                    }
                                    return (
                                        <MenuItem
                                            id={`MenuItem-${button.label}`}
                                            key={button.label}
                                            className={styles.linkItem}
                                            onClick={() => {
                                                setDropdownIsOpen(false);
                                                button.callback();
                                            }}
                                        >
                                            {icon}
                                            &nbsp;
                                            {button.label ? button.label : ''}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </div>
                    )
                    : null
            }
        </div>
    );
};

Dropdown.propTypes = {
    label: PropTypes.string.isRequired,
    buttons: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string.isRequired,
};
