import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { SIZES } from '../../../../constants/theme';

import styles from './MultiSelect.scss';

/**
 * The react-select plugin does not create an actual dropdown, so we need to tell formik about changed values.
 *
 * @see https://codesandbox.io/s/jrze53pqr?file=/index.js
 * @todo Have Select FormElement use the react-select plugin adn combine the two. Then use one story for both
 */
const MultiSelect = ({
    classes,
    fullWidth,
    id,
    label,
    name,
    size,
    isMulti,
    helperText,
    errors,
    touched,
    disabled,
    ...otherProps
}) => {
    const handleChange = (value) => {
        otherProps.setValue(value);
    };

    const handleBlur = () => {
        otherProps.onBlur(name, true);
    };

    return (
        // To add a shared class, put it in a string like below 'formItem'
        // 'react-select-container' has the plug in generating a select container class
        <div className={classNames(styles.container, classes.container, 'formItem', 'react-select-container')}>
            <label
                className={classes.label}
                htmlFor={id}
            >
                {label}
            </label>
            <Select
                className={classes.root}
                id={id}
                name={name}
                isMulti={isMulti}
                {...otherProps}
                onChange={handleChange}
                onBlur={handleBlur}
                // below is how we get the plug in to generate a class name for the controls
                classNamePrefix="react-select"
                aria-label={label}
                isDisabled={disabled}
            />
            {helperText && (
                <p className={styles.helperText}><i>{helperText}</i></p>
            )}
            {errors[name]
            && touched[name] && (
                <div className={styles.errors}>
                    {errors[name]}
                </div>
            )}
        </div>
    );
};

MultiSelect.propTypes = {
    classes: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        select: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        container: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    fullWidth: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.oneOf(Object.values(SIZES)),
    isMulti: PropTypes.bool,
    helperText: PropTypes.string,
    touched: PropTypes.shape({}).isRequired,
    errors: PropTypes.shape({}).isRequired,
    disabled: PropTypes.bool,
};

MultiSelect.defaultProps = {
    classes: {
        label: null,
        root: null,
        select: null,
    },
    fullWidth: false,
    size: SIZES.medium,
    isMulti: false,
    helperText: null,
    disabled: false,
};

export default MultiSelect;
