import { shape, string } from 'prop-types';

const ContactType = shape({
    contactName: string,
    contactRole: string,
    contactEmail: string,
    contactPhone: string,
    listingAddress1: string,
    listingAddress2: string,
    listingAddress3: string,
    listingCity: string,
    listingState: string,
    listingZip: string,
    latitude: string,
    longitude: string,
});

export default ContactType;
