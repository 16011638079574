import * as routes from '../../constants/routes';

export const setMinHeight = (path, query, windowWidth) => {
    const flipbookDesktop = 1166;
    const desktopSmall = 1022;
    const tabletMedium = 800;
    const tabletSmall = 766;
    const flipbookMobile = 695;

    const matchOrgDetailPattern = (urlPath) => {
        const pattern = /^\/organization\/.+$/;
        return pattern.test(urlPath);
    };

    const matchPdfViewPattern = (urlPath, urlQuery) => {
        const pathPattern = /^\/publication-page\/[^/]+$/;
        const queryPattern = /^\?selectedPublication=.+$/;
        return pathPattern.test(urlPath) && queryPattern.test(urlQuery);
    };

    const matchFlipbookViewPattern = (urlPath, urlQuery) => {
        const pathPattern = /^\/publication-page\/[^/]+$/;
        const queryPattern = /^\?pid=.+$/;
        return pathPattern.test(urlPath) && queryPattern.test(urlQuery);
    };

    switch (true) {
        case path.includes(routes.ABOUT):
            return windowWidth > desktopSmall ? '125.2rem' : '173.66rem';
        case path.includes(routes.ADVERTISE):
            return windowWidth > tabletSmall ? '135.2rem' : '146.67rem';
        case path.includes(routes.FLIPBOOK):
            return windowWidth > flipbookMobile ? '122.23rem' : '108.52rem';
        case path.includes(routes.HELP):
            return windowWidth > tabletMedium ? '576.08rem' : '632.05rem';
        case path.includes(routes.TERMSCONDITIONS):
            return windowWidth > tabletSmall ? '467.05rem' : '608.23rem';
        case matchOrgDetailPattern(path):
            return '147.18rem';
        case matchPdfViewPattern(path, query) || path.includes('/bulletin/file/'):
            return windowWidth > tabletMedium ? '148.88rem' : '167.44rem';
        case matchFlipbookViewPattern(path, query):
            if (windowWidth > flipbookDesktop) return '160.73rem';
            return windowWidth > tabletMedium ? '148.88rem' : '172.9rem';
        default:
            return '100vh';
    }
};
