/* eslint-disable no-console */
import { Ability, AbilityBuilder } from '@casl/ability';
import { toast } from 'react-toastify';
import customerDataRequest from '../services/api/customer-data';
import * as routes from '../constants/routes';
import {
    CHROME, FIREFOX, MICROSOFT_EDGE, getBrowserAgent,
} from '../utils/browserAgent';

export const loadAbilityForUser = (user, can) => {
    if (user) {
        const {
            permissionsList = [],
        } = user[`${process.env.AUTH0_NAMESPACE}`] || {};

        permissionsList.forEach((permission) => {
            const [action, item] = permission.split(':');
            can(action, item);
        });
    }
};

/**
 * Load listing abilities for the given user. This was added as a separate function because of the
 * promises. The app needed a response immediately about whether or not a user had permissions when
 * generating the menu, etc. I think we could make this better by including the listings a user owns
 * from Auth0 as part of the token. For now, this works.
 *
 * @param user
 * @param token
 * @param ability
 * @return {Promise<*>}
 */
export const loadListingAbilitiesForUser = async (user, token, ability) => {
    if (!user) {
        return ability;
    }

    const { can, rules } = new AbilityBuilder(Ability);
    loadAbilityForUser(user, can); // ability.update replaces existing rules, so we need to load it in again

    if (ability.can('admin', 'all')) {
        // if a user can administrate listings, they can update any listing
        can('admin', 'claimed');
    } else {
        const ownershipPromises = [
            customerDataRequest('get', '/organizations/owners', token),
            customerDataRequest('get', '/sponsors/owners', token),
        ];

        await Promise.all(ownershipPromises).then((response) => {
            response[0].data.data.forEach((organizationOwnership) => {
                can('admin', 'claimed', { id: { $eq: organizationOwnership.organization_salesforce_id } });
            });
            response[1].data.data.forEach((sponsorOwnership) => {
                can('admin', 'claimed', { id: { $eq: sponsorOwnership.sponsor_advertiser_id } });
            });
        });
    }

    ability.update(rules);

    return ability;
};

export const defineAbility = (user) => {
    const { rules, can } = new AbilityBuilder(Ability);
    loadAbilityForUser(user, can);

    return new Ability(rules);
};

export const canViewManage = (ability) => ability.can('admin', 'all');

export const getAuthToken = (getAccessTokenSilently) => getAccessTokenSilently({ detailedResponse: true })
    .then((token) => token.access_token)
    .catch(() => null);

const authLogin = (loginWithPopup, loginWithRedirect) => {
    const browserAgent = getBrowserAgent();
    console.log({ browserAgent });
    if (browserAgent === CHROME || browserAgent === FIREFOX || browserAgent === MICROSOFT_EDGE) {
        return loginWithPopup();
    }
    return loginWithRedirect();
};
// handle login/logout . no return
export const handleLogIn = async (loginWithPopup, loginWithRedirect, getAccessTokenSilently, logout) => {
    try {
        await getAccessTokenSilently();
    } catch (error) {
        switch (true) {
            case error.message === 'Login required':
            case error.message.includes('Missing Refresh Token'):
                authLogin(loginWithPopup, loginWithRedirect);
                break;
            case error.message.includes('Service not found'):
                toast.error(error.message);
                break;
            default: {
                toast.error(error.message);
                logout({ logoutParams: { returnTo: routes.AUTH_REDIRECT } });
            }
        }
    }
};
