import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    useContext,
    useEffect,
} from 'react';

import { Descriptions } from '../../constants/Descriptions';
import {
    POL_THEME,
    MCO_THEME,
    ThemeContext,
} from '../../constants/theme';
import { Titles } from '../../constants/titles';
import { AppContext } from '../../context/AppContext';

import SearchForm from '../shared/SearchForm';

import styles from './HomeContainer.scss';

const HomeContainer = ({ isSupporterHome }) => {
    const themeContext = useContext(ThemeContext);
    const { setPageTitle, setPageDescription } = useContext(AppContext);

    let typeContainer = '';
    if (themeContext === POL_THEME && isSupporterHome === false) {
        typeContainer = styles.polContainer;
    } else if (themeContext === MCO_THEME && isSupporterHome === false) {
        typeContainer = styles.mcoContainer;
    } else {
        typeContainer = styles.supportersContainer;
    }

    useEffect(() => {
        switch (themeContext) {
            case MCO_THEME:
                if (isSupporterHome) {
                    setPageTitle(Titles.MCO_SUPPORTERS);
                    setPageDescription(Descriptions.MCO_SUPPORTERS);
                    break;
                }
                setPageTitle(Titles.MCO_HOME);
                setPageDescription(Descriptions.MCO_HOME);
                break;
            case POL_THEME:
                if (isSupporterHome) {
                    setPageTitle(Titles.POL_SUPPORTERS);
                    setPageDescription(Descriptions.POL_SUPPORTERS);
                    break;
                }
                setPageTitle(Titles.POL_HOME);
                setPageDescription(Descriptions.POL_HOME);
                break;
            default:
                break;
        }
    }, [themeContext, isSupporterHome, setPageTitle, setPageDescription]);

    return (
        <main className={classNames(styles.container, typeContainer)}>
            <div className={styles.greetingContainer}>
                {isSupporterHome ? (
                    <>
                        <h1 className={styles.pageTitle}>
                            {themeContext === POL_THEME ? (
                                <>
                                    <span className={styles.headerTop}>Browse Parish Supporters </span>
                                    <span className={styles.headerBottom}>Across the Country.</span>
                                </>
                            ) : (
                                <>
                                    <span className={styles.headerTop}>Browse Community Supporters </span>
                                    <span className={styles.headerBottom}>Across the Country.</span>
                                </>
                            )}
                        </h1>
                        <div>
                            {themeContext === POL_THEME ? (
                                <div>Support your church advertisers.</div>
                            ) : (

                                <div>Support your community’s advertisers.</div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            {themeContext === POL_THEME ? (
                                <>
                                    <div className={styles.headerTop}>Live Your Faith,</div>
                                    <div className={styles.headerBottom}>Wherever Life Takes You.</div>
                                </>
                            ) : (
                                <>
                                    <div className={styles.headerTop}>A Sense of Belonging;</div>
                                    <div className={styles.headerBottom}>A Community of Friends.</div>
                                </>
                            )}
                        </div>
                        <div>
                            {themeContext === POL_THEME ? (
                                <div>
                                    Search&nbsp;
                                    <span className="italic">Catholic</span>
                                        &nbsp;parishes across the country.
                                </div>
                            ) : (
                                <div>
                                    <span className="normal">Find Your Community Online.</span>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.searchContainer}>
                <SearchForm
                    isSupporterSearch={isSupporterHome}
                    uniqueId="HomeContainer"
                    isHomeOrSupporters
                />
            </div>
        </main>
    );
};

HomeContainer.propTypes = {
    isSupporterHome: PropTypes.bool,
};

HomeContainer.defaultProps = {
    isSupporterHome: false,
};

export default HomeContainer;
