import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Buffer } from 'buffer';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { toast } from 'react-toastify';
import readXlsxFile from 'read-excel-file';

import { getAuthToken } from '../../auth/authHelpers';
import customerDataRequest from '../../services/api/customer-data';

import Button from '../shared/Button';
import PublicationsSubscribeForm from '../shared/Forms/PublicationsSubscribe';
import SubscriberRemoveForm from '../shared/Forms/SubscriberRemove';
import ModalDialog from '../shared/ModalDialog/Base';

import styles from './MassEmailsSubscription.scss';
import { COLORS } from '../../constants/theme';

// Image and XLS file for example spreadsheet
const screenshotImgUrl = `${process.env.CUSTOMER_DATA_S3_URL}/email/bulk-email-subscription-xls_example.jpg`;
const xlsSampleFileUrl = `${process.env.CUSTOMER_DATA_S3_URL}/email/bulk-email-subscription_sample-file.xlsx`;

const MassEmailsSubscription = ({
    salesforceId,
    openSubscriptionForm,
    organization,
    subscriberCount,
    updateSubscriberCount,
    isHidden,
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [data, setData] = useState([]);
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [isUploadSubmitted, setIsUploadSubmitted] = useState(false);
    const [subscribeFormTitle, setSubscribeFormTitle] = useState('Subscribe');
    const [openModal, setOpenModal] = useState(openSubscriptionForm);
    const [openRemoveModal, setOpenRemoveModal] = useState(openSubscriptionForm);
    const [contactInfo] = useState({
        contactEmail: '',
        firstName: '',
        lastName: '',
    });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [uploadFileName, setUploadFileName] = useState('');

    const fileInputRef = useRef();

    const handleFileChange = async (e) => {
        e.preventDefault();

        if (e.target.files) {
            const targetFile = e.target.files[0];

            try {
                const rows = await readXlsxFile(targetFile);
                const headers = rows[0];
                const dataRows = rows.slice(1);

                const trimmedData = dataRows.map((row) => ({
                    lastName: row[headers.indexOf('Last Name')]?.trim(),
                    firstName: row[headers.indexOf('First Name')]?.trim(),
                    emailAddress: row[headers.indexOf('Email')]?.trim(),
                    salesforceID: salesforceId,
                    subscriptionSource: 'bulk',
                }));

                const emailRegex = /^[a-zA-Z0-9._!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-_]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-_]{0,61}[a-zA-Z0-9])?)*$/;

                let hasValues = true;
                trimmedData.forEach((field) => {
                    if (
                        !field.lastName || field.lastName.length < 2
                        || !field.firstName || field.firstName.length < 2
                        || !field.emailAddress || !emailRegex.test(field.emailAddress)
                    ) {
                        hasValues = false;
                    }
                });

                if (!hasValues) {
                    setUploadError('File has missing or invalid values. The file should include the Last Name, First Name, and Email columns and each row should have complete information.');
                } else if (trimmedData.length < 2) {
                    setUploadError('The file must have a minimum of two email contacts.');
                    setData(trimmedData);
                } else if (trimmedData.length > 999) {
                    setUploadError('The file can have maximum of 999 email contacts.');
                    setData(trimmedData);
                } else {
                    setData(trimmedData);
                    setUploadError(null);
                }
            } catch (error) {
                setUploadError('Error reading the file. Ensure it is a valid Excel (.xlsx) file.');
            }
        }
    };

    const readUploadFile = (e) => {
        e.preventDefault();
        const targetFile = e.target.files[0];
        setFile(targetFile);
        setUploadFileName(targetFile.name);

        if (
            targetFile.type !== 'application/vnd.ms-excel'
            && targetFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            setUploadError('Only .xlsx or .xls files are acceptable for email subscription attachments.');
        } else {
            handleFileChange(e);
        }
    };

    const handleCancel = () => {
        fileInputRef.current.value = '';
        setFile(null);
        setUploadFileName('');
        setUploadError(null);
        setData([]);
    };

    const handleAddSubscriberCancel = () => {
        setIsFormSubmitted(false);
        setOpenModal(false);
        setSubscribeFormTitle('Subscribe');
    };

    const handleRemoveSubscriberCancel = () => {
        setIsFormSubmitted(false);
        setOpenRemoveModal(false);
        setSubscribeFormTitle('Subscribe');
    };

    const bulkContactManagementUrl = process.env.BULK_CONTACT_MANAGEMENT_API_URL;

    const fetchFiles = useCallback(async () => {
        const token = await getAuthToken(getAccessTokenSilently);

        try {
            const response = await axios.get(`${bulkContactManagementUrl}/email/s3/${salesforceId}`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });

            setFiles(response.data.data);
        } catch (error) {
            setFiles([]);
        }
    }, [
        bulkContactManagementUrl,
        getAccessTokenSilently,
        salesforceId,
    ]);

    const handleS3FileUpload = async () => {
        const token = await getAuthToken(getAccessTokenSilently);
        axios
            .put(`${bulkContactManagementUrl}/email/s3`, {
                salesforceId,
                fileKey: file.name,
                data: { data: Buffer.from(await file.arrayBuffer()) },
                contentType: file.type,
            }, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                fetchFiles();
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
            });
    };

    const handleSubmit = async () => {
        setIsUploadSubmitted(true);
        const url = '/organizations/mass-email-subscription';
        const token = await getAuthToken(getAccessTokenSilently);

        await customerDataRequest('post', url, token, data)
            .then(async () => {
                toast.success('The emails in the attached file are subscribed.');
                await handleS3FileUpload();
                updateSubscriberCount();
                handleCancel();
            })
            .catch(() => {
                toast.error('An error occurred during the bulk email subscription process.');
            });

        setIsUploadSubmitted(false);
    };

    const downloadBulkEmail = async () => {
        const token = await getAuthToken(getAccessTokenSilently);
        axios
            .get(`${bulkContactManagementUrl}/email/bulk-email/${salesforceId}`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            .then((results) => {
                const blob = new Blob([Buffer.from(results.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const today = new Date().toISOString().slice(0, 10);
                link.setAttribute('download', `email-subscribers-list_${today}.xlsx`);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
            });
    };

    const onSubmit = async (values) => {
        setIsSaving(true);

        await customerDataRequest('post', `/organizations/${salesforceId}/subscribe/`, null, {
            fName: values.firstName,
            lName: values.lastName,
            email: values.contactEmail,
            subscriptionSource: 'web',
        })
            .then(() => {
                updateSubscriberCount();
                setIsFormSubmitted(true);
            })
            .catch(() => {
                toast.error('There was an error subscribing');
            });

        setIsSaving(false);
    };

    const onRemoveSubmit = async (values) => {
        setIsSaving(true);
        const url = process.env.SENDGRID_INTEGRATION_SERVICE;

        await fetch(`${url}/unsubscribe`, {
            method: 'PATCH',
            body: JSON.stringify({
                emailAddress: values.contactEmail,
                salesforceID: salesforceId,
            }),
            headers: {
                'Content-type': 'application/json',
            },
        })
            .then(() => {
                updateSubscriberCount();
                setIsFormSubmitted(true);
            })
            .catch(() => {
                toast.error('There was an error unsubscribing');
            });

        setIsSaving(false);
    };

    useEffect(() => {
        fetchFiles();
    }, [fetchFiles]);

    useEffect(() => {
        if (isFormSubmitted) {
            setSubscribeFormTitle('Subscription Confirmation');
        }
    }, [isFormSubmitted]);

    const isValidFile = file && (
        file.type === 'application/vnd.ms-excel'
        || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    return (
        <Grid
            className={styles.root}
            container
        >
            <Grid
                className={styles.subscriberContainer}
                size={12}
            >
                <Typography
                    className={styles.title}
                    variant="h2"
                >
                    Total Email Subscribers:
                    {' '}
                    {subscriberCount}
                </Typography>
                {subscriberCount !== 0 && (
                    <button
                        className={styles.removeBtnStyles}
                        type="button"
                        onClick={downloadBulkEmail}
                        disabled={isHidden}
                    >
                        <Typography
                            className={classNames(styles.title, styles.link)}
                            variant="h2"
                        >
                            Click to download a list of all current email subscribers
                        </Typography>
                    </button>
                )}
                <Grid
                    container
                    size={12}
                    spacing={2}
                >
                    <Grid
                        size={{
                            xs: 12,
                            sm: 6,
                            md: 4,
                        }}
                    >
                        <Button
                            onClick={() => setOpenModal(true)}
                            otherClasses={styles.button}
                            fullWidth
                            disabled={isHidden}
                        >
                            Add A Subscriber
                        </Button>
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            sm: 6,
                            md: 4,
                        }}
                    >
                        <Button
                            color={COLORS.outlined}
                            onClick={() => setOpenRemoveModal(true)}
                            otherClasses={styles.button}
                            fullWidth
                            disabled={subscriberCount === 0 || isHidden}
                        >
                            Remove A Subscriber
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                className={styles.bulkUploadContainer}
                size={12}
            >
                <Typography
                    className={classNames(styles.title, styles.bulkUploadTitle)}
                    variant="h2"
                >
                    For Bulk Email Subscriptions:
                </Typography>
                <Grid
                    className={styles.bulkUploadFile}
                    container
                    size={12}
                    spacing={4}
                >
                    <Grid
                        className={styles.bulkUploadButtons}
                        container
                        size={{
                            xs: 12,
                            sm: 6,
                            md: 5,
                        }}
                        spacing={1}
                    >
                        <Grid
                            className={styles.fileInput}
                            size={12}
                        >
                            <Button
                                otherClasses={classNames(styles.button)}
                                onClick={() => fileInputRef.current.click()}
                                fullWidth
                                disabled={isHidden}
                            >
                                Upload Subscriber List
                            </Button>
                            <input
                                type="file"
                                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onClick={() => handleCancel()}
                                onInput={readUploadFile}
                                ref={fileInputRef}
                                disabled={isHidden}
                            />
                        </Grid>
                        <Grid size={6}>
                            <Button
                                onClick={handleSubmit}
                                otherClasses={classNames(styles.button)}
                                autoMinWidth
                                fullWidth
                                disabled={!isValidFile || !!uploadError || isUploadSubmitted || isHidden}
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid size={6}>
                            <Button
                                color={COLORS.outlined}
                                onClick={handleCancel}
                                otherClasses={classNames(styles.button)}
                                autoMinWidth
                                fullWidth
                                disabled={!isValidFile || isUploadSubmitted || isHidden}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        className={styles.fileSize}
                        size={{
                            xs: 12,
                            sm: 6,
                        }}
                    >
                        <TextField
                            variant="standard"
                            placeholder="No File Chosen"
                            value={uploadFileName}
                            onClick={() => fileInputRef.current.click()}
                            onKeyDown={() => fileInputRef.current.click()}
                            autoComplete="off"
                            fullWidth
                            error={uploadError !== null}
                            helperText={uploadError}
                            disabled={isHidden}
                        />
                        <Typography
                            className={classNames(styles.title, (!isValidFile || uploadError) && styles.disabledText)}
                            variant="h2"
                        >
                            Total subscribers in this file:
                            {' '}
                            {data.length !== 0 && data.length}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    className={styles.fileInfo}
                    size={12}
                >
                    <Typography
                        className={styles.title}
                        variant="h2"
                    >
                        Requirements for subscriber list uploads:
                    </Typography>
                    <ul>
                        <li>
                            File should be Microsoft Excel format only (
                            <span>.xls or .xlsx</span>
                            )
                        </li>
                        <li>
                            The file must contain at least 2 and no more than 999 email contacts
                        </li>
                        <li>
                            All email addresses should be valid
                        </li>
                        <li>
                            First and last name must contain at least two characters
                        </li>
                    </ul>
                    <div className={styles.screenshotImageContainer}>
                        <img
                            src={screenshotImgUrl}
                            alt="Screenshot of bulk email subscription example file"
                            className={styles.screenshotImage}
                        />
                    </div>
                    <a
                        className={styles.screenshotLink}
                        href={xlsSampleFileUrl}
                        download
                        tabIndex={isHidden ? -1 : 0}
                    >
                        Click to download a sample file
                    </a>
                </Grid>
            </Grid>
            <Grid
                container
                size={12}
                sx={{
                    justifySelf: 'flex-start',
                }}
            >
                <Grid size={12}>
                    <Typography
                        className={styles.title}
                        variant="h2"
                    >
                        Previously Uploaded Lists:
                    </Typography>
                </Grid>
                <Table className={styles.uploadsTable}>
                    <TableHead>
                        <TableRow key="headerRow">
                            <TableCell
                                align="left"
                                key="file-key"
                                size="medium"
                            >
                                File
                            </TableCell>
                            <TableCell
                                align="left"
                                key="status-key"
                                size="medium"
                            >
                                Status
                            </TableCell>
                            <TableCell
                                align="left"
                                key="day-time-key"
                                size="medium"
                            >
                                Date/Time
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            l: 100,
                        }}
                    >
                        {files?.map((currentFile) => {
                            const blob = new Blob([Buffer.from(currentFile.data)]);
                            const url = window.URL.createObjectURL(blob);
                            const timestampFileName = currentFile.file.split('/').pop();
                            const [timestamp] = timestampFileName.split('-');
                            // Remove timestamp from filename with regex
                            const fileName = timestampFileName.replace(/^\d+-/, '');

                            return (
                                <TableRow key={currentFile.file}>
                                    <TableCell>
                                        <a
                                            href={url}
                                            download={fileName}
                                            tabIndex={isHidden ? -1 : 0}
                                        >
                                            {fileName}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        <b>Processed</b>
                                    </TableCell>
                                    <TableCell>
                                        {DateTime
                                            .fromMillis(parseFloat(timestamp))
                                            .toFormat('MMMM d, yyyy')}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
            <ModalDialog
                title={subscribeFormTitle}
                content={(
                    <PublicationsSubscribeForm
                        uniqueId="subscriberAdd"
                        contactInfo={contactInfo}
                        organizationTitle={organization}
                        onClose={handleAddSubscriberCancel}
                        onSubmit={onSubmit}
                        isFormSubmitted={isFormSubmitted}
                        isSaving={isSaving}
                    />
                )}
                open={openModal}
                onClose={handleAddSubscriberCancel}
                buttons={([])}
            />
            <ModalDialog
                title="Unsubscribe"
                content={(
                    <SubscriberRemoveForm
                        uniqueId="subscriberRemove"
                        contactInfo={contactInfo}
                        organizationTitle={organization}
                        onClose={handleRemoveSubscriberCancel}
                        onSubmit={onRemoveSubmit}
                        isFormSubmitted={isFormSubmitted}
                        isSaving={isSaving}
                    />
                )}
                open={openRemoveModal}
                onClose={handleRemoveSubscriberCancel}
                buttons={([])}
            />
        </Grid>
    );
};

MassEmailsSubscription.propTypes = {
    organization: PropTypes.string,
    openSubscriptionForm: PropTypes.bool,
    salesforceId: PropTypes.string.isRequired,
    subscriberCount: PropTypes.number,
    updateSubscriberCount: PropTypes.func.isRequired,
    isHidden: PropTypes.bool,
};

MassEmailsSubscription.defaultProps = {
    organization: null,
    openSubscriptionForm: false,
    subscriberCount: 0,
    isHidden: false,
};

export default MassEmailsSubscription;
