// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-SearchTag-SearchTag__searchTag--ew5cW{background-color:#fff !important;margin-right:1rem !important;margin-bottom:1rem !important}`, "",{"version":3,"sources":["webpack://./src/components/shared/SearchTag/SearchTag.scss"],"names":[],"mappings":"AACA,6DACI,gCAAA,CACA,4BAAA,CACA,6BAAA","sourcesContent":["@import 'variables';\n.searchTag {\n    background-color: #fff !important;\n    margin-right: 1rem !important;\n    margin-bottom: 1rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#ec9300`,
	"secondary": `#00a5aa`,
	"secondaryDark": `#264550`,
	"backgroundBlue": `#eaf6f7`,
	"searchTag": `src-components-shared-SearchTag-SearchTag__searchTag--ew5cW`
};
export default ___CSS_LOADER_EXPORT___;
