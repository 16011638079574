import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SIZES, COLORS } from '../../../constants/theme';
import styles from './Button.scss';

const Button = ({
    classes,
    color,
    autoMinWidth,
    fullWidth,
    upperCase,
    leftMargin,
    size,
    outline,
    otherClasses,
    ...otherProps
}) => (
    <button
        className={classNames(styles.button, classes.root, styles[color], styles[size], {
            [styles.autoMinWidth]: autoMinWidth,
            [styles.fullWidth]: fullWidth,
            [styles.upperCase]: upperCase,
            [styles.leftMargin]: leftMargin,
            [styles.outline]: outline,
        }, otherClasses)}
        type="button"
        // Passing in classes other than the above will default to this and wipe out the above
        {...otherProps}
    />
);

Button.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    color: PropTypes.oneOf(Object.values(COLORS)),
    autoMinWidth: PropTypes.bool,
    fullWidth: PropTypes.bool,
    upperCase: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(SIZES)),
    leftMargin: PropTypes.bool,
    outline: PropTypes.bool,
    otherClasses: PropTypes.string,
};

Button.defaultProps = {
    classes: {
        root: null,
    },
    color: COLORS.default,
    autoMinWidth: false,
    fullWidth: false,
    upperCase: false,
    size: SIZES.medium,
    leftMargin: false,
    outline: false,
    otherClasses: '',
};

export default Button;
