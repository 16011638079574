import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { Link } from 'react-router-dom';
import styles from './Footer.scss';
import * as routes from '../../constants/routes';
import { BACKGROUND_COLORS, POL_THEME, ThemeContext } from '../../constants/theme';

const getContainerBackgroundColorClass = (backgroundColor) => {
    if (backgroundColor === BACKGROUND_COLORS.vistaWhite) {
        return styles.footerContainerNoImageVista;
    }

    return styles.footerContainerNoImage;
};

const Footer = ({ showBackgroundImage, containerBackgroundColor }) => {
    const tableHackStyle = {
        visibility: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 0,
        height: 0,
    };

    // MaterialTable is included as an invisible element as a hacky fix for an issue where the dropdown menu's links
    // were being placed next to eachother horizontally on the Weekly Readings page when the MaterialTable was visible.
    // See PR for a screenshot: https://github.com/liturgicalpublications/onlinedir/pull/57
    // I think this is caused by some style overriding being done by the MaterialTable.
    const themeContext = useContext(ThemeContext);
    return (
        <div className={`${styles.footerContainer}`
            + ` ${showBackgroundImage ? '' : getContainerBackgroundColorClass(containerBackgroundColor)}`}
        >
            <footer className={styles.footer}>
                <nav className={styles.menuContainer}>
                    {themeContext === POL_THEME ? (
                        <>
                            <ul className={styles.menu}>
                                <li><Link to={routes.ADVERTISE}>Advertise</Link></li>
                                <li><Link to={routes.ABOUT}>About</Link></li>
                                <li>
                                    <a
                                        href="https://www.4lpi.com/Blog/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Blog
                                    </a>
                                </li>
                                <li><Link to={routes.HELP}>Help</Link></li>
                                <li>
                                    <a
                                        href="https://www.4lpi.com/privacy-policy/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Privacy
                                    </a>
                                </li>
                                <li><Link to={routes.TERMSCONDITIONS}>Terms</Link></li>
                            </ul>
                            <p>
                                Powered by LPi &copy;
                                {(new Date().getFullYear())}
                            </p>
                        </>
                    ) : (

                        <>
                            <ul className={styles.menuMCO}>
                                <li><Link to={routes.ADVERTISE}>Advertise</Link></li>
                                <li><Link to={routes.ABOUT}>About</Link></li>
                                <li><Link to={routes.HELP}>Help</Link></li>
                                <li>
                                    <a
                                        href="https://www.4lpi.com/privacy-policy/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Privacy
                                    </a>
                                </li>
                                <li><Link to={routes.TERMSCONDITIONS}>Terms</Link></li>
                            </ul>
                            <p className={styles.powered}>
                                Powered by LPi &copy;
                                {(new Date().getFullYear())}
                            </p>
                        </>
                    )}
                    <MaterialTable
                        style={tableHackStyle}
                    />
                </nav>
            </footer>
        </div>
    );
};

Footer.propTypes = {
    showBackgroundImage: PropTypes.bool,
    containerBackgroundColor: PropTypes.string,
};

Footer.defaultProps = {
    showBackgroundImage: false,
    containerBackgroundColor: BACKGROUND_COLORS.white,
};

export default Footer;
