/* eslint-disable function-paren-newline */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import 'regenerator-runtime';

import AppContainer from './components/layout/AppContainer';

import { AppProvider } from './context/AppContext';

import { MCO_THEME, POL_THEME, ThemeContext } from './constants/theme';
import * as routes from './constants/routes';

import './_theme.scss';

// MUI Theme provider setup, see https://mui.com/material-ui/customization/theming/ for details.
// Theme specific MUI styling can go here:
const muiThemeMCO = createTheme({});
const muiThemePOL = createTheme({});
const POL_TAGMANAGER_CONFIG = {
    auth: process.env.POL_TAGMANAGER_AUTH,
    gtmId: process.env.POL_TAGMANAGER_ID,
    preview: process.env.POL_TAGMANAGER_ENV,
};
const MCO_TAGMANAGER_CONFIG = {
    auth: process.env.MCO_TAGMANAGER_AUTH,
    gtmId: process.env.MCO_TAGMANAGER_ID,
    preview: process.env.MCO_TAGMANAGER_ENV,
};

let { hostname } = window.location;
hostname = hostname.toLowerCase().replace('www.', '');

let themeName = POL_THEME;
let muiTheme = muiThemePOL;

if (hostname === process.env.MCO_HOSTNAME) {
    themeName = MCO_THEME;
    muiTheme = muiThemeMCO;
}

if (process.env.PUBLISH_ENV !== 'development') {
    const isPOL = themeName === POL_THEME;
    const isMCO = themeName === MCO_THEME;

    if (isPOL || isMCO) {
        let tagManagerConfig = null;
        let analyticsID = null;

        if (isPOL) {
            tagManagerConfig = POL_TAGMANAGER_CONFIG;
            analyticsID = process.env.POL_GA4_ID;
        } else if (isMCO) {
            tagManagerConfig = MCO_TAGMANAGER_CONFIG;
            analyticsID = process.env.MCO_GA4_ID;
        }

        if (tagManagerConfig) {
            ReactGA.initialize(analyticsID, { gaOptions: { send_page_view: false } });
            TagManager.initialize(tagManagerConfig);

            // Add noindex meta tag to prevent search indexing if env is stage
            if (process.env.PUBLISH_ENV === 'stage') {
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('name', 'robots');
                metaTag.setAttribute('content', 'noindex');
                document.getElementsByTagName('head')[0].appendChild(metaTag);
            }
        }
    }
}

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        navigate(appState && appState.returnTo ? appState.returnTo : routes.HOME);
    };
    return (
        <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
            {children}
        </Auth0Provider>
    );
};

const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: routes.AUTH_REDIRECT,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    },
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    cacheLocation: 'localstorage',
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <ThemeContext.Provider value={themeName}>
            <ThemeProvider theme={muiTheme}>
                <BrowserRouter>
                    <Auth0ProviderWithRedirectCallback {...providerConfig}>
                        <AppProvider>
                            <AppContainer />
                        </AppProvider>
                    </Auth0ProviderWithRedirectCallback>
                </BrowserRouter>
            </ThemeProvider>
        </ThemeContext.Provider>
    </React.StrictMode>
);
