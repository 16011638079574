import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Divider.scss';

const Divider = ({ classes, vertical, short }) => (
    <div
        className={classNames(styles.container, classes.root, { [styles.vertical]: vertical }, { [styles.short]: short })}
    />
);

Divider.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    vertical: PropTypes.bool,
    short: PropTypes.bool,
};

Divider.defaultProps = {
    classes: {
        root: null,
    },
    vertical: false,
    short: false,
};

export default Divider;
