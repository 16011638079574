// Add new icons for SIC codes or parent SIC code categories here only!
import {
    faBagsShopping,
    faBasketballHoop,
    faBooks,
    faBowlingPins,
    faBriefcase,
    faBug,
    faBuilding,
    faCalculator,
    faCamera,
    faCar,
    faCarBurst,
    faCartShopping,
    faCarWash,
    faChair,
    faClock,
    faCouch,
    faCrateApple,
    faDumbbell,
    faEnvelopeOpenDollar,
    faFaucet,
    faFileContract,
    faFireExtinguisher,
    faFlowerDaffodil,
    faForkKnife,
    faGasPump,
    faGavel,
    faGift,
    faGlasses,
    faHammer,
    faHandHeart,
    faHandshakeAngle,
    faHeadphones,
    faHomeHeart,
    faHospital,
    faHospitals,
    faHouse,
    faHouseChimneyMedical,
    faIndustry,
    faLaptop,
    faMailbox,
    faMasksTheater,
    faMonument,
    faOven,
    faPaintRoller,
    faPalette,
    faPaw,
    faPennant,
    faPenRuler,
    faPersonDigging,
    faPersonDolly,
    faPhoneOffice,
    faPie,
    faPiggyBank,
    faPlane,
    faPlug,
    faPrescriptionBottle,
    faPrint,
    faRibbon,
    faRingsWedding,
    faSandwich,
    faSchool,
    faScrewdriverWrench,
    faScrollOld,
    faShirt,
    faSignHanging,
    faSprayCan,
    faSprayCanSparkles,
    faStethoscope,
    faStretcher,
    faTeddyBear,
    faTire,
    faToolbox,
    faTooth,
    faTreasureChest,
    faTree,
    faTruckPlow,
    faUserHelmetSafety,
    faVacuum,
    faWindowFrame,
    faWineBottle,
} from '@fortawesome/pro-regular-svg-icons';

export const faSicCodeLibrary = [
    faBagsShopping,
    faBasketballHoop,
    faBooks,
    faBowlingPins,
    faBriefcase,
    faBug,
    faBuilding,
    faCalculator,
    faCamera,
    faCar,
    faCarBurst,
    faCartShopping,
    faCarWash,
    faChair,
    faClock,
    faCouch,
    faCrateApple,
    faDumbbell,
    faEnvelopeOpenDollar,
    faFaucet,
    faFileContract,
    faFireExtinguisher,
    faFlowerDaffodil,
    faForkKnife,
    faGasPump,
    faGavel,
    faGift,
    faGlasses,
    faHammer,
    faHandHeart,
    faHandshakeAngle,
    faHeadphones,
    faHomeHeart,
    faHospital,
    faHospitals,
    faHouse,
    faHouseChimneyMedical,
    faIndustry,
    faLaptop,
    faMailbox,
    faMasksTheater,
    faMonument,
    faOven,
    faPaintRoller,
    faPalette,
    faPaw,
    faPennant,
    faPenRuler,
    faPersonDigging,
    faPersonDolly,
    faPhoneOffice,
    faPie,
    faPiggyBank,
    faPlane,
    faPlug,
    faPrescriptionBottle,
    faPrint,
    faRibbon,
    faRingsWedding,
    faSandwich,
    faSchool,
    faScrewdriverWrench,
    faScrollOld,
    faShirt,
    faSignHanging,
    faSprayCan,
    faSprayCanSparkles,
    faStethoscope,
    faStretcher,
    faTeddyBear,
    faTire,
    faToolbox,
    faTooth,
    faTreasureChest,
    faTree,
    faTruckPlow,
    faUserHelmetSafety,
    faVacuum,
    faWindowFrame,
    faWineBottle,
];
