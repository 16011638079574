import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import classNames from 'classnames';
import { ANCHOR_POSITIONS, SIZES } from '../../../constants/theme';
import styles from './Drawer.scss';

const Drawer = ({
    anchor,
    children,
    classes,
    onClose,
    sideSheet,
    noPadding,
    open,
    onLoad,
    size = SIZES.small,
}) => {
    useEffect(() => {
        if (onLoad) onLoad();
    }, [onLoad]);

    return (
        <SwipeableDrawer
            variant="temporary"
            anchor={anchor}
            open={open}
            onClose={onClose}
            className={styles.minWidth}
            onOpen={() => { /* Do nothing */ }} // onOpen is required by SwipeableDrawer
            disableSwipeToOpen
            disableDiscovery
        >
            <div className={classNames(classes.root, size === SIZES.small ? styles.container : styles.containerLg, {
                [styles.sideSheet]: sideSheet,
                [styles.noPadding]: noPadding,
            })}
            >
                {children}
            </div>
        </SwipeableDrawer>
    );
};

Drawer.propTypes = {
    anchor: PropTypes.oneOf(Object.values(ANCHOR_POSITIONS)),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    open: PropTypes.bool,
    classes: PropTypes.shape({
        backdrop: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        drawer: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    sideSheet: PropTypes.bool,
    noPadding: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
    size: PropTypes.oneOf([SIZES.small, SIZES.large]),
};

Drawer.defaultProps = {
    classes: {
        backdrop: null,
        drawer: null,
        root: null,
    },
    children: null,
    anchor: ANCHOR_POSITIONS.right,
    open: false,
    sideSheet: true,
    noPadding: false,
    size: SIZES.small,
    onLoad: () => {},
};

export default Drawer;
