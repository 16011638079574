import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/pro-regular-svg-icons';
import { faFireFlame } from '@fortawesome/pro-light-svg-icons';
import {
    POL_THEME,
    MCO_THEME,
    ThemeContext,
} from '../../constants/theme';
import styles from './500-error.scss';
import { Titles } from '../../constants/titles';
import { AppContext } from '../../context/AppContext';
import { Descriptions } from '../../constants/Descriptions';

const Error500 = () => {
    const themeContext = useContext(ThemeContext);
    const { setPageTitle, setPageDescription } = useContext(AppContext);

    useEffect(() => {
        switch (themeContext) {
            case MCO_THEME:
                setPageTitle(Titles.MCO_500);
                setPageDescription(Descriptions.MCO_500);
                break;
            case POL_THEME:
                setPageTitle(Titles.POL_500);
                setPageDescription(Descriptions.POL_500);
                break;
            default:
                break;
        }
    }, [themeContext, setPageTitle, setPageDescription]);

    return (
        <div className={styles.outerContainer}>
            <main className={styles.container}>
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon
                        className={styles.flameIcon}
                        icon={faFireFlame}
                    />
                    <FontAwesomeIcon
                        icon={faDesktop}
                    />
                </div>
                <div className={styles.textBlock}>
                    <h1 className={styles.errorHeadline}>500</h1>
                    <h2>Oops, something went wrong.</h2>
                    <p>
                        Please try again in a few minutes. If the problem persists, please
                        &nbsp;
                        <a href="https://www.4lpi.com/contact-us"> contact support</a>
                        .
                    </p>
                </div>
            </main>
        </div>
    );
};

export default Error500;
