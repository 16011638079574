import { useAuth0 } from '@auth0/auth0-react';
import { subject } from '@casl/ability';
import {
    faArrowDownToLine,
    faGlobe,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/system/Box';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    AdSlot,
    DFPManager,
    DFPSlotsProvider,
} from 'react-dfp';
import {
    Link,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    getAuthToken,
    loadListingAbilitiesForUser,
} from '../../auth/authHelpers';
import { AbilityContext } from '../../auth/Can';
import { Descriptions } from '../../constants/Descriptions';
import { FULL_PUBLICATION_FORMAT } from '../../constants/publications';
import * as routes from '../../constants/routes';
import {
    COLORS,
    MCO_THEME,
    POL_THEME,
    ThemeContext,
} from '../../constants/theme';
import { Titles } from '../../constants/titles';
import { AppContext } from '../../context/AppContext';
import {
    getPdfUrlByPidCallback,
    loadContractsCallback,
    loadOrganizationCallback,
    loadPublicationsCallback,
} from '../../utils/organization';
import { fillRouteParameters } from '../../utils/routes';
import { withHttp } from '../../utils/url';

import Flipbook from '../flipbook/View/Flipbook';
import AdminInfo from '../organization-detail/AdminInfo/AdminInfo';
import AdsSponsorsSection from '../organization-detail/AdsSponsorsSection';
import RecentPublicationsSection from '../organization-detail/RecentPublicationsSection';
import PopupSubscribeForm from '../popup-subscribe-form/PopupSubscribeForm';
import Button from '../shared/Button';
import Loader from '../shared/Loader';

import buttonStyles from '../../styles/PDFButtons.scss';
import styles from './PublicationPage.scss';

const PublicationPage = () => {
    const themeContext = useContext(ThemeContext);
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    const { setPageTitle, setPageDescription } = useContext(AppContext);
    const contextAbility = useContext(AbilityContext);

    const history = useNavigate();
    const location = useLocation();
    const { slug, filename } = useParams();

    const {
        getAccessTokenSilently,
        user,
        isAuthenticated,
    } = useAuth0();

    const params = new URLSearchParams(location.search);
    const pid = params.get('pid');
    const pub = params.get('publication');
    const selectedPublicationUrl = params.get('selectedPublication');
    const publication = filename || pub;

    let { hostname } = window.location;
    hostname = hostname.toLowerCase();
    hostname = hostname.replace('www.', '');

    const isPOLDomain = hostname === process.env.POL_HOSTNAME;

    let urlPublication = null;
    if (publication) {
        urlPublication = {
            fileUrl: `${(isPOLDomain)
                ? process.env.POL_BULLETINS_ROOT_URL
                : process.env.MCO_BULLETINS_ROOT_URL}/${publication.replaceAll('-', '/')}`,
        };
    }

    let urlSelectedPublication = selectedPublicationUrl && !isPOLDomain
        ? selectedPublicationUrl.replace('parishesonline', 'mycommunityonline')
        : selectedPublicationUrl;

    if (urlPublication) {
        urlSelectedPublication = urlPublication.fileUrl;
    }

    const [ability, setAbility] = useState({});
    const [publications, setPublications] = useState([]);

    // const [publicationTitle] = useState('');
    const [orgPhysicalTitle, setOrgPhysicalTitle] = useState('');

    const [viewerPublication, setViewerPublication] = useState(null);
    const [organization, setOrganization] = useState({});
    const [isLoadingPublications, setIsLoadingPublications] = useState(false);
    const [isLoadingOrganization, setIsLoadingOrganization] = useState(false);
    // State for subscribe popup window
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [prettyPublishDate, setPrettyPublishDate] = useState(null);
    const [dataReady, setDataReady] = useState({ org: false, contracts: false });
    const adSetupComplete = useRef(false);

    useEffect(() => {
        // Redirect to org details page if no publication info is present in URL
        if (!pid && !pub && !filename && !selectedPublicationUrl) {
            toast.error('No publication was requested.');
            history(`/organization/${slug}`);
        }
    }, [filename, pid, pub, selectedPublicationUrl, history, slug]);

    useEffect(() => {
        if (orgPhysicalTitle && prettyPublishDate) {
            const splitDate = prettyPublishDate.split(', ');
            const date = prettyPublishDate !== 'Archive'
                ? `${splitDate[1]}, ${splitDate[2]}`
                : prettyPublishDate;

            switch (themeContext) {
                case MCO_THEME:
                    setPageTitle(Titles.MCO_PUBLICATION(orgPhysicalTitle, date));
                    setPageDescription(Descriptions.MCO_PUBLICATION(orgPhysicalTitle, date));
                    break;
                case POL_THEME:
                    setPageTitle(Titles.POL_PUBLICATION(orgPhysicalTitle, date));
                    setPageDescription(Descriptions.POL_PUBLICATION(orgPhysicalTitle, date));
                    break;
                default:
                    break;
            }
        }
    }, [orgPhysicalTitle, prettyPublishDate, themeContext, setPageTitle, setPageDescription]);

    const loadListingAbilities = useCallback(async () => {
        const token = isAuthenticated ? await getAuthToken(getAccessTokenSilently) : null;
        setAbility(await loadListingAbilitiesForUser(user, token, contextAbility));
    }, [isAuthenticated, getAccessTokenSilently, user, contextAbility]);

    useEffect(() => {
        loadListingAbilities();
    }, [loadListingAbilities]);

    const sortedPublications = useMemo(() => sortBy(publications, (currentPublication) => DateTime.fromISO(currentPublication.publishDate).valueOf()).reverse(), [publications]);

    const [organizationContractIds, setOrganizationContractIds] = useState(undefined);

    const [salesforceId, setSalesforceId] = useState('');

    const getDisplayDate = (date) => {
        const dateObj = new Date(date);
        const displayDate = dateObj.getDate() + 1;
        const publishDate = dateObj.setDate(displayDate);
        const isoPublishDate = new Date(publishDate).toISOString();
        const publishDateTime = DateTime.fromISO(isoPublishDate);

        return publishDateTime.toFormat(FULL_PUBLICATION_FORMAT);
    };

    useEffect(() => {
        if (viewerPublication) {
            if (viewerPublication?.publishDate) {
                setPrettyPublishDate(getDisplayDate(viewerPublication.publishDate));
            } else {
                // Fallback date if publication not found within 12 recent pubs
                setPrettyPublishDate('Archive');
            }
        }
    }, [viewerPublication]);

    const canUpdateListing = useCallback(() => ability.can && ability.can('admin', subject('claimed', { id: salesforceId })), [ability, salesforceId]);

    const breadCrumbsTitle = () => (
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" to={fillRouteParameters(routes.ORGANIZATION_DETAIL, { slug })}>
                {orgPhysicalTitle}
            </Link>
            <div>
                {themeContext === POL_THEME ? 'Bulletin' : 'Publication'}
            </div>
        </Breadcrumbs>
    );

    const setOrganizationData = (newOrganization) => {
        setOrganization(newOrganization);
        setSalesforceId(newOrganization.salesforce_id);
        setOrgPhysicalTitle(newOrganization.physical_title);
    };

    const loadOrganization = useCallback(async () => {
        await loadOrganizationCallback(slug, setIsLoadingOrganization, setOrganizationData, history, themeContext);
    }, [slug, history, themeContext]);

    const loadPublications = useCallback(async () => {
        setIsLoadingPublications(true);
        if (salesforceId) {
            const orgType = themeContext === POL_THEME ? 'Church' : 'Community';
            await loadPublicationsCallback(salesforceId, orgType, setPublications, setIsLoadingPublications);
        }
    }, [salesforceId, themeContext]);

    const loadContracts = useCallback(async () => {
        if (salesforceId) {
            await loadContractsCallback(salesforceId, getAccessTokenSilently, setIsLoadingPublications, setOrganizationContractIds, false, false);
        }
    }, [salesforceId, getAccessTokenSilently]);

    const loadPdfByPid = useCallback(async () => {
        if (salesforceId && pid) {
            const data = await getPdfUrlByPidCallback(salesforceId, pid, getAccessTokenSilently);

            if (data && data.pdfUrl) {
                history(data.pdfUrl);
            } else {
                history(`/organization/${slug}`);
            }
        }
    }, [salesforceId, pid, slug, history, getAccessTokenSilently]);

    const publicationAds = {
        SAF2_PUBLICATION_Sponsor_300x100_Mobile: {
            unit: 'SAF2_PUBLICATION_Sponsor_300x100_Mobile',
            sizes: [[300, 100]],
        },
        OSC2_PUBLICATION_Sponsor_300x100_Mobile: {
            unit: 'OSC2_PUBLICATION_Sponsor_300x100_Mobile',
            sizes: [[300, 100]],
        },
    };

    const rightColumnAdSlots = {
        POL: [
            {
                unit: 'SAF2_PublicationInternal_300x150',
                sizes: [[300, 150]],
            },
            {
                unit: 'SAF2_PUBLICATION_Sponsor_300x600',
                sizes: [[300, 600]],
            },
        ],
        MCO: [
            {
                unit: 'OSC2_PublicationInternal_300x150',
                sizes: [[300, 150]],
            },
            {
                unit: 'OSC2_PUBLICATION_Sponsor_300x600',
                sizes: [[300, 600]],
            },
        ],
    };

    useEffect(() => {
        loadPublications();
    }, [loadPublications]);

    useEffect(() => {
        loadContracts();
    }, [loadContracts]);

    useEffect(() => {
        if (organizationContractIds || organizationContractIds === null) {
            setDataReady((prev) => ({ ...prev, contracts: true }));
        }
    }, [organizationContractIds]);

    useEffect(() => {
        loadOrganization();
    }, [loadOrganization]);

    useEffect(() => {
        if (organization && organization.salesforce_id) {
            setDataReady((prev) => ({ ...prev, org: true }));
        }
    }, [organization]);

    useEffect(() => {
        loadPdfByPid();
    }, [loadPdfByPid]);

    useEffect(() => {
        if (!urlSelectedPublication) {
            setViewerPublication(null);
        }
    }, [urlSelectedPublication]);

    useEffect(() => {
        if (dataReady.org && dataReady.contracts && !adSetupComplete.current) {
            DFPManager.setTargetingArguments({
                Church_ID: organizationContractIds,
                Salesforce_Id: salesforceId,
                City: organization.physical_city,
                State: organization.physical_state,
                Zip: organization.physical_postal_code,
                Category: organization.tags,
            });
            DFPManager.refresh();
            adSetupComplete.current = true;
        }
        // Check if org is a publication customer to set subscribe popup state
        // Then check if this is first visit to Org page for subscribe popup state
        if (
            organization.is_publication_customer
            && localStorage.getItem(`${salesforceId}-popup-shown`) === null
            && salesforceId
        ) {
            setIsPopupOpen(true);
            // If local storage item didn't exist, create it
            localStorage.setItem(`${salesforceId}-popup-shown`, true);
        }
    }, [dataReady, organization, organizationContractIds, salesforceId]);

    useEffect(() => {
        if (urlSelectedPublication && sortedPublications.length) {
            const matchingPublication = sortedPublications
                .map(((_publication) => {
                    if (!_publication.fileUrl) {
                        return _publication;
                    }
                    // eslint-disable-next-line no-param-reassign
                    _publication.fileUrl = (isPOLDomain)
                        ? _publication.fileUrl
                        : _publication.fileUrl.replace('parishesonline', 'mycommunityonline');
                    return _publication;
                }))
                .find((publicationItem) => (publicationItem.fileUrl === urlSelectedPublication.replace(/ /g, '%20')));

            // Set viewerPublication to available URL if no match is present
            if (!matchingPublication) {
                // Check file request is returning 200, else redirect to org details
                const checkAndSetPublication = async () => {
                    try {
                        const fileResponse = await fetch(urlSelectedPublication);

                        if (fileResponse.status === 200) {
                            setViewerPublication({
                                fileUrl: urlSelectedPublication,
                            });
                        } else {
                            toast.error('The requested publication is not available.');
                            history(`/organization/${slug}`);
                        }
                    } catch (error) {
                        toast.error('Encountered an unknown error.');
                    }
                };

                checkAndSetPublication();
            } else {
                setViewerPublication(matchingPublication);
            }
        }
    }, [urlSelectedPublication, sortedPublications, isPOLDomain, history, slug]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const renderAdSlot = (ad, id, slotRenderHandler = () => {}) => (
        <AdSlot
            dfpNetworkId={process.env.DFP_NETWORK_ID}
            key={ad.unit}
            adUnit={ad.unit}
            sizes={ad.sizes}
            slotId={id}
            onSlotRender={(e) => slotRenderHandler(e)}
        />
    );

    const publicationViewerContent = (
        <>
            <Grid
                container
                size={12}
            >
                <Grid
                    size={6}
                >
                    {prettyPublishDate}
                </Grid>
                <Grid
                    className={styles.downloadButtonContainer}
                    size={6}
                >
                    <Button
                        classes={{
                            root: buttonStyles.button,
                        }}
                        onClick={() => window.open(viewerPublication?.fileUrl, '_blank')}
                        role="link"
                    >
                        <FontAwesomeIcon icon={faArrowDownToLine} />
                        {' '}
                        Download PDF
                    </Button>
                </Grid>
            </Grid>
            <Grid
                className={styles.flipbookWrapper}
                size={12}
            >
                <Flipbook
                    pdfUrl={viewerPublication?.fileUrl}
                    salesforceId={salesforceId}
                    width={600}
                />
            </Grid>
        </>
    );

    const adminMenuContent = (
        <AdminInfo
            org={organization}
            publicationsData={publications}
            arePublicationsLoading={isLoadingPublications}
            setArePublicationsLoading={setIsLoadingPublications}
            loadPublications={loadPublications}
            isPublicationPage
        />
    );

    const adsSponsorsSectionContent = (
        <AdsSponsorsSection
            organizationSalesforceId={salesforceId}
            slug={slug}
        />
    );

    const primaryContent = (
        <Grid
            id="primary-content"
            className={classNames(styles.primaryContentContainer, styles.alignContentStart)}
            container
            spacing={3}
        >
            {!isMediumScreen && organization && canUpdateListing()
                && (
                    <Grid size={12}>
                        {adminMenuContent}
                    </Grid>
                )}
            <Grid
                className={styles.topBar}
                container
                size={12}
                spacing={{
                    xs: 1,
                    md: 0,
                }}
            >
                <Grid
                    size={{
                        xs: 12,
                        md: 8,
                        lg: 9,
                    }}
                >
                    <h1
                        className={styles.pageTitle}
                        aria-label={`${orgPhysicalTitle} ${themeContext === POL_THEME ? 'Bulletin' : 'Publication'} for ${prettyPublishDate}`}
                    >
                        {breadCrumbsTitle(orgPhysicalTitle)}
                    </h1>
                </Grid>
                <Grid
                    className={styles.visitWebsiteLink}
                    size={{
                        xs: 12,
                        md: 4,
                        lg: 3,
                    }}
                >
                    {organization.website && (
                        <a
                            href={withHttp(organization.website)}
                            rel="noreferrer"
                            target="_blank"
                            className={styles.fullWidth}
                            aria-label="Organization Website"
                        >
                            <div>
                                <FontAwesomeIcon
                                    className={styles.visitWebsiteLinkIcon}
                                    icon={faGlobe}
                                />
                                <span>
                                    Visit Website
                                </span>
                            </div>
                        </a>
                    )}
                </Grid>
            </Grid>
            {isExtraSmallScreen && (
                <Grid
                    className={styles.mobileInlineAd}
                    size={12}
                >
                    {themeContext === POL_THEME
                        ? renderAdSlot(publicationAds.SAF2_PUBLICATION_Sponsor_300x100_Mobile, 'mobile-leaderboard-ad')
                        : renderAdSlot(publicationAds.OSC2_PUBLICATION_Sponsor_300x100_Mobile, 'mobile-leaderboard-ad')}
                </Grid>
            )}
            {/* Publication Viewer content */}
            {publicationViewerContent}
            {!!sortedPublications && (
                <Grid size={12}>
                    <RecentPublicationsSection
                        organizationTitle={organization.physical_title}
                        publications={sortedPublications}
                        loadPublications={loadPublications}
                        salesforceId={salesforceId}
                        setViewerPublication={() => {}}
                        isPublicationCustomer={organization.is_publication_customer}
                        organization={organization}
                        openSubscriptionForm={false}
                        isLoading={isLoadingPublications}
                        setIsLoading={setIsLoadingPublications}
                    />
                </Grid>
            )}
            {!isMediumScreen && organization.is_publication_customer
                && (
                    <Grid size={12}>
                        {adsSponsorsSectionContent}
                    </Grid>
                )}
        </Grid>
    );

    const rightSidebarContent = (
        <Grid
            className={classNames(styles.rightSideBarContainer, styles.alignContentStart)}
            container
            spacing={2}
        >
            {organization && canUpdateListing()
                && (
                    <Grid size={12}>
                        {adminMenuContent}
                    </Grid>
                )}
            <Grid
                container
                size={12}
                spacing={2}
            >
                {themeContext === POL_THEME
                    ? rightColumnAdSlots.POL.map((ad, index) => (
                        <Grid
                            key={ad.unit}
                            size={12}
                        >
                            {renderAdSlot(ad, `right-column-ad-${index}`)}
                        </Grid>
                    ))
                    : rightColumnAdSlots.MCO.map((ad, index) => (
                        <Grid
                            key={ad.unit}
                            size={12}
                        >
                            {renderAdSlot(ad, `right-column-ad-${index}`)}
                        </Grid>
                    ))}
            </Grid>
            {organization.is_publication_customer && (
                <Grid size={12} sx={{ marginTop: '16px' }}>
                    {adsSponsorsSectionContent}
                </Grid>
            )}
        </Grid>
    );

    return (
        <DFPSlotsProvider
            dfpNetworkId={process.env.DFP_NETWORK_ID}
            singleRequest
            disableInitialLoad
        >
            <main>
                {isLoadingOrganization && (
                    <Loader color={COLORS.secondary} />
                )}
                {salesforceId && (
                    <>
                        <PopupSubscribeForm
                            organizationTitle={organization.physical_title}
                            salesforceId={salesforceId}
                            isPopupOpen={isPopupOpen}
                            setIsPopupOpen={setIsPopupOpen}
                        />
                        <Box className={styles.pageContainer}>
                            {/* Empty space on left side */}
                            <Box
                                className={styles.leftSideBarContainer}
                            />
                            {/* Primary details container */}
                            {primaryContent}
                            {/* Right-side ads container */}
                            {isMediumScreen && rightSidebarContent}
                        </Box>
                    </>
                )}
            </main>
        </DFPSlotsProvider>
    );
};

export default PublicationPage;
