import { POL_THEME } from '../constants/theme';

const PROD_SUBDOMAIN = 'prod';

const isUrl = (string) => {
    try {
        return !!(new URL(string));
    } catch (_) {
        return false;
    }
};

const formUrl = (string) => (isUrl(string) ? string : `http://${string}`);

const getHttpProtocol = () => {
    if (process.env.PUBLISH_ENV === 'development') {
        return 'http://';
    }
    return 'https://';
};

const getHostname = (themeContext) => {
    if (process.env.PUBLISH_ENV === 'development') {
        return process.env.BASE_URL;
    }

    if (themeContext === POL_THEME) {
        return process.env.POL_HOSTNAME;
    }

    return process.env.MCO_HOSTNAME;
};

const getProtocolAndHostname = (themeContext) => `${getHttpProtocol()}${getHostname(themeContext)}`;

const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);

const truncateUrl = (url, maxLength) => {
    const truncatedUrl = url
        .replace(/^https?:\/\//, '')
        .replace(/\/$/, '');

    return truncatedUrl.length > maxLength
        ? `${truncatedUrl.substring(0, maxLength)}...`
        : truncatedUrl;
};

export {
    isUrl,
    formUrl,
    getHttpProtocol,
    getHostname,
    getProtocolAndHostname,
    withHttp,
    truncateUrl,
    PROD_SUBDOMAIN,
};
