import React, {
    useContext, useRef, useState, useMemo,
} from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { subject } from '@casl/ability';
import classNames from 'classnames';

import { useAuth0 } from '@auth0/auth0-react';
import TextField from '../../FormElements/TextField';
import FormField from '../../FormElements/FormField';
import styles from './SettingsForm.scss';
import {
    COLORS,
    SIZES,
    POL_THEME,
    ThemeContext,
} from '../../../../constants/theme';
import { Can } from '../../../../auth/Can';
import Button from '../../Button';
import * as routes from '../../../../constants/routes';
import { fillRouteParameters } from '../../../../utils/routes';
import { LISTING_TYPE_ORGANIZATION, LISTING_TYPE_SPONSOR } from '../../../../constants/site';
import customerDataRequest, { customerDataMicroserviceRequest } from '../../../../services/api/customer-data';
import { getHttpProtocol } from '../../../../utils/url';
import ChurchIconSvg from '../../../../../public/svg/profile-image-icon-church.svg';
import ListingIconSvg from '../../../../../public/svg/profile-image-icon-listing.svg';
import { getAuthToken } from '../../../../auth/authHelpers';
import { cacheInvalidation } from '../../../../utils/cacheInvalidation';

const ERROR_EXISTING_SLUG = 'The slug is already in use';
const ERROR_CHECKING_SLUG = 'There was an error checking the slug. Please try again in a few minutes.';
const FORM_FIELD_NAMES = {
    pageTitle: 'pageTitle',
    pageSlug: 'pageSlug',
    description: 'description',
};
const IMAGE_ACCEPTED_FILE_TYPES = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/webp',
];
const MIN_WIDTH = 1200;
const MIN_HEIGHT = 645;

const SettingsForm = ({
    uniqueId,
    pageTitle,
    pageSlug,
    description,
    profileImageUrl,
    setProfileImageUrl,
    updateSettings,
    listingType,
    listingId,
    onCancel,
    setIsLoading,
    isHidden,
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const themeContext = useContext(ThemeContext);
    const fileInputRef = useRef();
    const [charCount, setCharCount] = useState(description.length);

    const existingSlugCheck = [
        'existingSlug',
        ERROR_EXISTING_SLUG,
        async function test(value) {
            let valid = true;
            const listingUrlType = (listingType === LISTING_TYPE_ORGANIZATION) ? 'organizations' : 'sponsors';

            const thenCallback = (res) => {
                const foundListing = res.data.data;

                // if found, and is not the same listing ID, then not valid
                if ((listingType === LISTING_TYPE_ORGANIZATION && foundListing.salesforce_id !== listingId)
                    || (listingType === LISTING_TYPE_SPONSOR && foundListing.advertiser_id !== listingId)) {
                    valid = false;
                }
            };

            await customerDataRequest('get', `/${listingUrlType}/slug/${value}/?includeDeleted=true`)
                .then(thenCallback)
                .catch((err) => {
                    if (err.response && err.response.status !== 404) {
                        // any other errors
                        valid = false;
                        toast.error(ERROR_CHECKING_SLUG);
                    }
                });

            if (!valid) {
                return false;
            }

            // Check again while ignoring claimed data, since we do not want to duplicate a slug that exists
            // in the main tables
            await customerDataRequest('get', `/${listingUrlType}/slug/${value}/?includeDeleted=true&ignoreClaimedData=true`)
                .then(thenCallback)
                .catch((err) => {
                    if (!err.response || err.response.status !== 404) {
                        // already exists
                        valid = false;
                        toast.error(ERROR_CHECKING_SLUG);
                    }
                });

            return valid;
        },
    ];

    const settingsSchema = Yup.object().shape({
        [FORM_FIELD_NAMES.pageSlug]: Yup.string().matches(/^[a-z0-9-]+$/, 'URL slug can only contain lower case letters, numbers, or dashes').max(96, 'Slug cannot be longer than 96 characters')
            .test(...existingSlugCheck)
            .default(pageSlug),
        [FORM_FIELD_NAMES.pageTitle]: Yup.string()
            .max(96, 'Page title cannot be longer than 96 characters')
            .default(pageTitle),
        [FORM_FIELD_NAMES.description]: Yup.string().nullable().max(1500, 'Description cannot be longer than 1,500 characters.'),
    });

    const initialValues = useMemo(() => ({
        ...settingsSchema.cast(),
        [FORM_FIELD_NAMES.description]: description,
    }), [description, settingsSchema]);

    const uploadProfileImage = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const {
                target: {
                    files: [uploadedFile],
                },
            } = event;

            if (!uploadedFile) {
                return;
            }

            const checkImageDimensions = () => new Promise((resolve, reject) => {
                const image = new Image();
                image.src = URL.createObjectURL(uploadedFile);

                image.onload = () => {
                    const {
                        width,
                        height,
                    } = image;

                    if (width < MIN_WIDTH || height < MIN_HEIGHT) {
                        toast.error(`Image doesn't meet minimum dimensions of ${MIN_WIDTH} x ${MIN_HEIGHT} pixels.`);

                        reject(new Error());
                    }

                    resolve();
                };

                image.onerror = () => {
                    reject(new Error());
                };
            });

            await checkImageDimensions();

            const token = await getAuthToken(getAccessTokenSilently);

            const readFileAsBase64 = (file) => new Promise((resolve) => {
                const fileReader = new FileReader();
                fileReader.onload = () => resolve(fileReader.result);
                fileReader.readAsDataURL(file);
            });
            const fileBuffer = await readFileAsBase64(uploadedFile);

            await customerDataMicroserviceRequest('PUT', process.env.PHOTO_MANAGEMENT_API_URL, `organizations/${listingId}/profile-photo`, token, {
                fileName: uploadedFile.name,
                imageDataBase64: fileBuffer,
            });

            setProfileImageUrl(URL.createObjectURL(uploadedFile));
            cacheInvalidation(`/organizations/slug/${pageSlug}`, token);

            toast.success('Profile image updated successfully.');
        } catch (error) {
            toast.error('An error occurred while uploading the profile image.');
        } finally {
            setIsLoading(false);
        }
    };

    const deleteProfileImage = async () => {
        setIsLoading(true);

        try {
            const token = await getAuthToken(getAccessTokenSilently);

            await customerDataMicroserviceRequest('DELETE', process.env.PHOTO_MANAGEMENT_API_URL, `organizations/${listingId}/profile-photo`, token);

            setProfileImageUrl(null);
            cacheInvalidation(`/organizations/slug/${pageSlug}`, token);

            toast.success('Profile image deleted successfully.');
        } catch (error) {
            toast.error('An error occurred while deleting the profile image.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleProfileImageCancel = () => {
        fileInputRef.current.value = '';
    };

    const onSubmit = async (values) => {
        await updateSettings(values[FORM_FIELD_NAMES.pageTitle], values[FORM_FIELD_NAMES.pageSlug], values[FORM_FIELD_NAMES.description]);
    };

    const getUrlLinkText = () => {
        const route = (listingType === LISTING_TYPE_ORGANIZATION) ? routes.ORGANIZATION_DETAIL : routes.SPONSOR_DETAIL;
        const URL = themeContext === POL_THEME ? process.env.BASE_URL : process.env.MCO_HOSTNAME;

        return `${getHttpProtocol()}${URL}${fillRouteParameters(route, { slug: '' })}`;
    };

    return (
        <div
            className={styles.container}
        >
            <div
                className={styles.sheetForm}
            >
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={settingsSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >

                    {({
                        errors, touched, values, handleChange,
                    }) => (
                        <Form>
                            {listingType === LISTING_TYPE_ORGANIZATION && (
                                <Can I="admin" this={subject('listing', { id: listingId })}>
                                    <div className={styles.profileImageContainer}>
                                        <div
                                            className={styles.profileImageLeft}
                                            style={{
                                                backgroundImage: `url("${profileImageUrl}")`,
                                            }}
                                            aria-label="Profile Image"
                                        >
                                            {!profileImageUrl && (
                                                themeContext === POL_THEME ? (
                                                    <ChurchIconSvg />
                                                ) : (
                                                    <ListingIconSvg />
                                                )
                                            )}
                                        </div>
                                        <div className={styles.profileImageRight}>
                                            <input
                                                className={styles.fileInput}
                                                type="file"
                                                accept={IMAGE_ACCEPTED_FILE_TYPES.join(',')}
                                                onClick={() => handleProfileImageCancel()}
                                                onInput={uploadProfileImage}
                                                ref={fileInputRef}
                                                disabled={isHidden}
                                            />
                                            <Button
                                                size={SIZES.small}
                                                onClick={() => fileInputRef.current.click()}
                                                disabled={isHidden}
                                            >
                                                {profileImageUrl ? 'Replace' : 'Upload'}
                                                &nbsp;
                                                {themeContext === POL_THEME ? 'Church' : 'Listing'}
                                                &nbsp;
                                                Image
                                            </Button>
                                            {!!profileImageUrl && (
                                                <Button
                                                    color={COLORS.flat}
                                                    size={SIZES.small}
                                                    onClick={deleteProfileImage}
                                                    disabled={isHidden}
                                                >
                                                    Delete
                                                </Button>
                                            )}
                                            <p className={styles.profileImageInfoText}>
                                                Please provide an image that&apos;s at least 1200 x 645 pixels.
                                                <br />
                                                We recommend uploading landscape-style only to best frame your image.
                                            </p>
                                        </div>
                                    </div>
                                </Can>
                            )}
                            <div>
                                <FormField
                                    label={`Your ${themeContext === POL_THEME ? 'Parishes' : 'My Community'} Online Page Title`}
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.pageTitle}
                                    errors={errors[FORM_FIELD_NAMES.pageTitle]}
                                    touched={touched[FORM_FIELD_NAMES.pageTitle]}
                                    id={`${uniqueId}-title`}
                                    required
                                    mediumWidth
                                    disabled={isHidden}
                                />
                            </div>
                            <Can I="admin" this={subject('listing', { id: listingId })}>
                                <FormField
                                    label="URL"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.pageSlug}
                                    errors={errors[FORM_FIELD_NAMES.pageSlug]}
                                    touched={touched[FORM_FIELD_NAMES.pageSlug]}
                                    id={`${uniqueId}-url`}
                                    required
                                    linkText={getUrlLinkText()}
                                    additionalInfo="Please validate the &quot;Online Directory Link&quot;
                                        in Salesforce matches the &quot;URL&quot;"
                                    copyClipboard
                                    urlErrorTextWrap
                                    disabled={isHidden}
                                />
                            </Can>
                            <h2 className={styles.heading}>About Us</h2>
                            <FormField
                                label="About Us Description"
                                component={TextField}
                                name={FORM_FIELD_NAMES.description}
                                errors={errors[FORM_FIELD_NAMES.description]}
                                touched={touched[FORM_FIELD_NAMES.description]}
                                id={`${uniqueId}-description-text-area`}
                                value={values[FORM_FIELD_NAMES.description]}
                                required={false}
                                onChange={(e) => {
                                    handleChange(e);
                                    setCharCount(e.target.value.length);
                                }}
                                className={styles.textField}
                                showOptional={false}
                                size="medium"
                                multiline
                                disabled={isHidden}
                            />
                            <div className={classNames(styles.descriptionText)}>
                                <span
                                    className={classNames({
                                        [styles.counterText]: charCount > 0,
                                    })}
                                >
                                    {charCount}
                                    {' '}
                                </span>
                                <span>/1500</span>
                            </div>
                            <Button
                                otherClasses={styles.saveButton}
                                size={SIZES.small}
                                type="submit"
                                disabled={isHidden}
                            >
                                Save
                            </Button>
                            <Button
                                color={COLORS.flat}
                                size={SIZES.small}
                                onClick={onCancel}
                                disabled={isHidden}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

SettingsForm.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
    pageSlug: PropTypes.string,
    description: PropTypes.string.isRequired,
    profileImageUrl: PropTypes.string,
    setProfileImageUrl: PropTypes.func,
    updateSettings: PropTypes.func.isRequired,
    listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    listingType: PropTypes.oneOf([LISTING_TYPE_ORGANIZATION, LISTING_TYPE_SPONSOR]).isRequired,
    onCancel: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func,
    isHidden: PropTypes.bool,
};

SettingsForm.defaultProps = {
    listingId: null,
    pageSlug: null,
    profileImageUrl: null,
    setProfileImageUrl: () => null,
    setIsLoading: () => null,
    isHidden: false,
};

export default SettingsForm;
