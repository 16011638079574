import { useAuth0 } from '@auth0/auth0-react';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import { faSquareInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { toast } from 'react-toastify';

import { getAuthToken } from '../../../auth/authHelpers';
import { AbilityContext } from '../../../auth/Can';
import customerDataRequest from '../../../services/api/customer-data';
import { LISTING_TYPE_ORGANIZATION } from '../../../constants/site';
import {
    COLORS,
    POL_THEME,
    SIZES,
    ThemeContext,
} from '../../../constants/theme';
import {
    addEventCallback,
    removeEventCallback,
} from '../../../utils/organization';

import Button from '../../shared/Button';

import AccountSettingsDrawer from './Drawers/AccountSettingsDrawer';
import DigitalCommunicationsDrawer from './Drawers/DigitalCommunicationsDrawer';
import ListingInformationDrawer from './Drawers/ListingInformationDrawer';
import PublicationsManageDrawer from './Drawers/PublicationsManageDrawer';
import PublicationsWidgetDrawer from './Drawers/PublicationsWidgetDrawer';

import styles from './AdminInfo.scss';

const AdminInfo = ({
    org,
    publicationsData,
    orgContractIds,
    contactInfo,
    events,
    setEvents,
    billingInfo,
    socialData,
    pageSlug,
    loadOrganization,
    isPublicationPage,
    loadPublications,
    arePublicationsLoading,
    setArePublicationsLoading,
}) => {
    const {
        is_publication_customer: isPublicationCustomer,
        profile_image_thumbnail_url: profileImageUrl,
        salesforce_id: salesforceId,
    } = org;
    const ability = useAbility(AbilityContext);
    const { getAccessTokenSilently } = useAuth0();
    const canAdminAll = ability.can('admin', 'all');
    const canAdminClaimedPage = ability.can('admin', subject('claimed', { id: salesforceId }));
    const themeContext = useContext(ThemeContext);
    const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false);
    const [isDigitalCommDrawerOpen, setIsDigitalCommDrawerOpen] = useState(false);
    const [isAccountSettingsDrawerOpen, setIsAccountSettingsDrawerOpen] = useState(false);
    const [isWidgetDrawerOpen, setIsWidgetDrawerOpen] = useState(false);
    const [isPublicationDrawerOpen, setIsPublicationDrawerOpen] = useState(false);
    const [isCountLoading, setIsCountLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [currentProfileImage, setCurrentProfileImage] = useState(null);

    useEffect(() => {
        setCurrentProfileImage(profileImageUrl);
    }, [profileImageUrl]);

    const updateSubscriberCount = useCallback(async () => {
        const url = `/organizations/subscriber-count/${salesforceId}`;

        try {
            const token = await getAuthToken(getAccessTokenSilently);

            const customerData = await customerDataRequest('get', url, token);
            setCount(customerData.data?.data?.subscriberCount);
        } catch (_) {
            toast.error(`There was an error loading the organization's subscriber count.
            Please try again in a few minutes.`);
        }
    }, [getAccessTokenSilently, salesforceId]);

    const loadData = useCallback(async () => { // Customer data API to return subscribers counts based on salesforceId
        if (salesforceId && isPublicationCustomer) {
            setIsCountLoading(true);
            await updateSubscriberCount();
            setIsCountLoading(false);
        }
    }, [salesforceId, isPublicationCustomer, updateSubscriberCount]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const addEvent = async (newEvent) => {
        const result = await addEventCallback(salesforceId, newEvent, getAccessTokenSilently);
        if (result.success) {
            const addedEvent = result.data;
            const eventExists = events.some((event) => event.id === addedEvent.id);
            if (eventExists) {
                setEvents((prevEvents) => prevEvents.map((event) => (event.id === addedEvent.id ? addedEvent : event)));
            } else {
                setEvents((prevEvents) => [...prevEvents, addedEvent]);
            }
            return true;
        }
        return false;
    };

    const removeEvent = async (eventId) => {
        const result = await removeEventCallback(salesforceId, eventId, getAccessTokenSilently);
        if (result.success) {
            setEvents((prevEvents) => prevEvents.map((event) => {
                if (event.id === eventId) {
                    return { ...event, deleted_at: new Date() };
                }
                return event;
            }));
            toast.success('Event deleted');
            return true;
        }
        toast.error('There was an error deleting the event.');
        return false;
    };

    return (
        <>
            <div
                className={
                    classNames(styles.container, 'border-section', isCountLoading ? styles.containerLoading : '')
                }
            >
                <div className={styles.title}>Admin</div>

                {canAdminAll && (
                    <Button
                        size={SIZES.small}
                        color={COLORS.flat}
                        onClick={() => setIsInfoDrawerOpen(true)}
                    >
                        <FontAwesomeIcon icon={faSquareInfo} />
                        {' '}
                        Listing Information
                    </Button>
                )}
                {/* Button won't be accessible on publication page */}
                {!isPublicationPage && (
                    <Button
                        onClick={() => setIsAccountSettingsDrawerOpen(true)}
                        fullWidth
                    >
                        Account Settings
                    </Button>
                )}
                {isPublicationCustomer && !!publicationsData.length
                    && (
                        <Button
                            onClick={() => setIsWidgetDrawerOpen(true)}
                            fullWidth
                        >
                            {`Add ${themeContext === POL_THEME ? 'Bulletin to Your Website' : 'Newsletter to Website'}`}
                        </Button>
                    )}
                <Button
                    onClick={() => setIsPublicationDrawerOpen(true)}
                    fullWidth
                >
                    Manage Publications
                </Button>

                {/* Button won't be accessible on publication page */}
                {isPublicationCustomer && !isPublicationPage && (
                    <Button
                        onClick={() => setIsDigitalCommDrawerOpen(true)}
                        fullWidth
                    >
                        Manage Communications
                    </Button>
                )}
            </div>

            {/* Drawer Panels */}
            {canAdminClaimedPage && (
                <ListingInformationDrawer
                    isOpen={isInfoDrawerOpen}
                    setIsOpen={setIsInfoDrawerOpen}
                    isAdmin={canAdminClaimedPage}
                    organization={org}
                    publicationsData={publicationsData}
                    isCountLoading={isCountLoading}
                    count={count}
                />
            )}
            {isPublicationCustomer && !!publicationsData.length
                && (
                    <PublicationsWidgetDrawer
                        salesforceId={salesforceId}
                        isDrawerOpen={isWidgetDrawerOpen}
                        setIsDrawerOpen={setIsWidgetDrawerOpen}
                    />
                )}

            <PublicationsManageDrawer
                isDrawerOpen={isPublicationDrawerOpen}
                setIsDrawerOpen={setIsPublicationDrawerOpen}
                publicationsData={publicationsData}
                salesforceId={salesforceId}
                isPublicationCustomer={isPublicationCustomer}
                arePublicationsLoading={arePublicationsLoading}
                setArePublicationsLoading={setArePublicationsLoading}
                loadPublications={loadPublications}
            />

            {/* Drawers won't be accessible on publication page */}
            {!isPublicationPage && (
                <>
                    <AccountSettingsDrawer
                        socialData={socialData}
                        contactInfo={contactInfo}
                        billingInfo={billingInfo}
                        listingType={LISTING_TYPE_ORGANIZATION}
                        classes={{ root: styles.topDetailContainer }}
                        isDrawerOpen={isAccountSettingsDrawerOpen}
                        setIsDrawerOpen={setIsAccountSettingsDrawerOpen}
                        loadOrganization={loadOrganization}
                        pageTitle={org.physical_title || ''}
                        pageSlug={pageSlug}
                        salesforceId={salesforceId}
                        description={org.description}
                        isPublicationCustomer={isPublicationCustomer}
                        profileImageUrl={currentProfileImage}
                        setProfileImageUrl={setCurrentProfileImage}
                    />
                    {isPublicationCustomer && (
                        <DigitalCommunicationsDrawer
                            isOpen={isDigitalCommDrawerOpen}
                            setIsOpen={setIsDigitalCommDrawerOpen}
                            organization={org}
                            orgContractIds={orgContractIds}
                            subscriberCount={count}
                            updateSubscriberCount={updateSubscriberCount}
                            events={events}
                            addEventCallback={addEvent}
                            removeEventCallback={removeEvent}
                        />
                    )}
                </>
            )}
        </>
    );
};

AdminInfo.propTypes = {
    org: PropTypes.objectOf(PropTypes.any),
    publicationsData: PropTypes.arrayOf(PropTypes.object),
    orgContractIds: PropTypes.arrayOf(PropTypes.string),
    contactInfo: PropTypes.object,
    events: PropTypes.arrayOf(PropTypes.object),
    setEvents: PropTypes.func,
    billingInfo: PropTypes.object,
    socialData: PropTypes.object,
    pageSlug: PropTypes.string,
    loadOrganization: PropTypes.func,
    isPublicationPage: PropTypes.bool,
    arePublicationsLoading: PropTypes.bool,
    setArePublicationsLoading: PropTypes.func,
    loadPublications: PropTypes.func.isRequired,
};

AdminInfo.defaultProps = {
    org: {},
    publicationsData: [],
    orgContractIds: [],
    contactInfo: {},
    events: [],
    setEvents: () => [],
    socialData: {},
    pageSlug: '',
    loadOrganization: () => {},
    billingInfo: {},
    isPublicationPage: false,
    arePublicationsLoading: false,
    setArePublicationsLoading: () => {},
};

export default AdminInfo;
