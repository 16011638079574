import MUIAutoComplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {
    useField,
    useFormikContext,
} from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { SIZES } from '../../../../constants/theme';
import {
    getFontCSS,
    getFontSize,
} from '../../../../styles/fonts';

import styles from './Autocomplete.scss';

const Autocomplete = ({
    onChange = null,
    onInputChange = null,
    options = [],
    size = SIZES.small,
    error,
    useFormik = true,
    callGivenOnChangeWithFormik = false,
    fontSize,
    isLoading,
    ...otherProps
}) => {
    const [field, meta] = useField(otherProps);
    const {
        setFieldValue, setFieldTouched, setFieldError,
    } = useFormikContext();

    const fontSizeCSSValue = getFontSize(fontSize);

    let onChangeFunc = onChange;

    // Filtering out Formik-specific props for TextField
    const {
        initialValue,
        initialError,
        initialTouched,
        setValue,
        setError,
        setTouched,
        ...filteredProps
    } = otherProps;

    if (useFormik) {
        onChangeFunc = (_, newValue) => {
            setFieldValue(field.name, newValue || '');
            if (callGivenOnChangeWithFormik && onChange) onChange(newValue); // Call given onChange after Formik's
        };
    }

    const onInputChangeFunc = (e, newValue) => {
        if ((e && e.type === 'change') || !newValue) {
            setFieldValue(field.name, newValue || '');
            onInputChange(newValue);
        }
    };

    const handleBlur = () => {
        if (meta.touched && meta.error && field.value) {
            setFieldError(field.name, undefined);
        }
        setFieldTouched(field.name, true);
    };

    // Determine if there is an error
    const hasError = useFormik ? !!(meta.touched && meta.error) : !!error;

    return (
        <MUIAutoComplete
            className={styles.autoComplete}
            value={field.value}
            onChange={onChangeFunc}
            onInputChange={onInputChangeFunc}
            options={options.map((option) => option)}
            loading={isLoading}
            filterOptions={(x) => x}
            disableClearable={!field.value}
            freeSolo
            isOptionEqualToValue={(option, value) => option.includes(value)}
            renderOption={(prop, option) => (
                <Box
                    component="li"
                    {...prop}
                    key={prop.id}
                >
                    {option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...filteredProps}
                    size={size}
                    fontSize={fontSize}
                    onBlur={handleBlur}
                    error={hasError}
                    label={
                        hasError ? (
                            <span style={{ color: 'red' }}>
                                {meta.error}
                            </span>
                        ) : otherProps.label
                    }
                    slotProps={{
                        InputProps: {
                            style: error
                                ? {
                                    color: 'red',
                                }
                                : {},
                        },
                    }}
                />
            )}
            slotProps={{
                input: getFontCSS(fontSizeCSSValue),
            }}
        />
    );
};

Autocomplete.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onInputChange: PropTypes.func,
    options: PropTypes.instanceOf(Array),
    size: PropTypes.oneOf(Object.values(SIZES)),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    useFormik: PropTypes.bool,
    callGivenOnChangeWithFormik: PropTypes.bool,
    fontSize: PropTypes.oneOf(Object.values(SIZES)),
    isLoading: PropTypes.bool,
};

Autocomplete.defaultProps = {
    size: SIZES.small,
    error: null,
    onChange: null,
    onBlur: null,
    onInputChange: null,
    options: [],
    value: null,
    useFormik: true,
    callGivenOnChangeWithFormik: false,
    fontSize: SIZES.medium,
    isLoading: false,
};

export default Autocomplete;
