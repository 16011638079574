import React from 'react';
import PropTypes from 'prop-types';
import MaterialTabs from '@mui/material/Tabs';
import MaterialTab from '@mui/material/Tab';
import classNames from 'classnames';
import styles from './Tabs.scss';

const TabPanel = ({
    children,
    value,
    index,
    ...otherProps
}) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...otherProps}
    >
        {value === index && <div>{ children }</div>}
    </div>
);

TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export const Tabs = ({
    classes,
    children,
    isHidden,
}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabChildren = React.Children.toArray(children).filter((child) => child !== null);

    return (
        <div className={classNames(styles.container, classes.root)}>
            <MaterialTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
            >
                {tabChildren.map((child) => (
                    <MaterialTab
                        key={child.props['data-key']}
                        label={child.props['data-title']}
                        disabled={isHidden}
                    />
                ))}
            </MaterialTabs>
            {tabChildren.map((child, index) => (
                <TabPanel
                    key={child.props['data-key']}
                    index={index}
                    value={value}
                >
                    {child}
                </TabPanel>
            ))}
        </div>
    );
};

Tabs.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    isHidden: PropTypes.bool,
};

Tabs.defaultProps = {
    classes: {
        root: null,
    },
    isHidden: false,
};
