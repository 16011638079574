import axios from 'axios';

/**
 * Default handler for customer data api requests
 * @param method
 * @param url
 * @param authToken
 * @param data
 * @param timeout timeout in milliseconds, default 0 = no timeout
 * @returns {Promise<AxiosResponse<any>>}
 */
// eslint-disable-next-line default-param-last
export default async function customerDataRequest(method = 'get',
    url,
    authToken = null,
    data = null,
    timeout = 0,
    contentType = null) {
    const headers = {
        'X-API-KEY': process.env.API_KEY,
    };

    if (contentType) {
        headers.contentType = contentType;
    }

    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }

    const config = {
        baseURL: `${process.env.API_URL}/`,
        method,
        headers,
        url,
        timeout,
    };

    if (data !== null) {
        config.data = data;
    }

    return axios.request(config);
}
// eslint-disable-next-line default-param-last
export async function customerDataReqCustom(method = 'get',
    url,
    authToken = null,
    data = null,
    timeout = 0,
    contentType = null,
    headers = {}) {
    const sendHeaders = {
        ...headers,
        'X-API-KEY': process.env.API_KEY,
    };

    if (contentType) {
        sendHeaders.contentType = contentType;
    }

    if (authToken) {
        sendHeaders.Authorization = `Bearer ${authToken}`;
    }

    const config = {
        baseURL: `${process.env.API_CUSTOM_URL}/`,
        method,
        headers: sendHeaders,
        url,
        timeout,
    };

    if (data !== null) {
        config.data = data;
    }

    return axios.request(config);
}
// eslint-disable-next-line default-param-last
export async function customerDataMicroserviceRequest(method = 'get',
    baseURL,
    url,
    authToken = null,
    data = null,
    timeout = 0,
    contentType = null) {
    const headers = {};
    if (contentType) {
        headers.contentType = contentType;
    }

    if (authToken) {
        headers.Authorization = `Bearer ${authToken}`;
    }

    const config = {
        baseURL,
        method,
        headers,
        url,
        timeout,
    };

    if (data !== null) {
        config.data = data;
    }

    return axios.request(config);
}
