import React, {
    useContext,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark, faBan, faCheck, faSpinner,
} from '@fortawesome/pro-light-svg-icons';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { addOwnerCallback, removeOwnerCallback } from '../../../utils/organization';
import {
    COLORS, POL_THEME, SIZES, ThemeContext,
} from '../../../constants/theme';
import Button from '../../shared/Button';
import styles from './OwnerView.scss';
import { addSponsorOwnerCallback, removeSponsorOwnerCallback } from '../../../utils/sponsor';

const OwnerView = ({
    owners,
    salesforceId,
    advertiserId,
    isAddingOwner,
    onOwnerAdded,
    onOwnerAddedCancelled,
    onOwnerRemoved,
    isHidden,
}) => {
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const themeContext = useContext(ThemeContext);
    const isPOL = themeContext === POL_THEME;

    // Define the validation schema using yup
    const validationSchema = yup.object({
        email: yup.string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: () => {
        },
    });

    const startRemoveOwner = (owner) => {
        setSelectedOwner(owner);
    };

    const handleRemoveOwnerCallbackSuccess = (removedOwner) => {
        onOwnerRemoved(removedOwner);
        toast.success('Owner removed successfully', { autoClose: 1500 });
        setSelectedOwner(false);
    };

    const handleRemoveOwnerCallbackError = () => {
        toast.error('There was an error removing the owner. Please try again in a few minutes.');
    };

    const handleRemoveOwnerConfirm = () => {
        const patchData = {
            isPOL,
            declineReason: '',
        };
        if (salesforceId) {
            removeOwnerCallback(salesforceId, selectedOwner.id, getAccessTokenSilently, setIsLoading, handleRemoveOwnerCallbackSuccess, handleRemoveOwnerCallbackError, patchData);
        }
        if (advertiserId) {
            removeSponsorOwnerCallback(advertiserId, selectedOwner.id, getAccessTokenSilently, setIsLoading, handleRemoveOwnerCallbackSuccess, handleRemoveOwnerCallbackError, patchData);
        }
    };

    const handleRemoveOwnerCancel = () => {
        setSelectedOwner(null);
    };

    const handleAddOwnerCallbackSuccess = (addedOwner) => {
        onOwnerAdded(addedOwner);
        formik.resetForm();
        toast.success('Owner added successfully', { autoClose: 1500 });
    };

    const handleAddOwnerCallbackError = () => {
        formik.setErrors({ email: 'No account found' });
        // eslint-disable-next-line max-len
        toast.error('There was an error adding this owner. Please verify an account exists with this email address and try again.');
    };

    const handleAddOwner = () => {
        formik.setSubmitting(true);

        // Ensure the email doesn't match an existing owner
        if (owners
            .filter((owner) => owner.approved === 1 && owner.deleted_at === null)
            .some((owner) => owner.email === formik.values.email)
        ) {
            formik.setErrors({ email: 'Owner already exists' });
            return;
        }

        formik.validateForm();

        if (formik.errors.email) {
            return;
        }
        if (salesforceId) {
            addOwnerCallback(salesforceId, formik.values.email, getAccessTokenSilently, setIsLoading, handleAddOwnerCallbackSuccess, handleAddOwnerCallbackError, isPOL);
        }

        if (advertiserId) {
            addSponsorOwnerCallback(advertiserId, formik.values.email, getAccessTokenSilently, setIsLoading, handleAddOwnerCallbackSuccess, handleAddOwnerCallbackError, isPOL);
        }
    };

    const handleAddOwnerCancel = () => {
        formik.setErrors({});
        formik.resetForm();
        onOwnerAddedCancelled();
    };

    const handleChange = (e) => {
        formik.handleChange(e);
        formik.setSubmitting(false);
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {owners
                        .filter((owner) => owner.approved === 1 && owner.deleted_at === null)
                        .map((owner) => (
                            <TableRow key={owner.id}>
                                <TableCell>
                                    {`${owner.first_name || ''} ${owner.last_name || ''}`}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        width: 375,
                                    }}
                                >
                                    {owner.email_address || ''}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        width: 250,
                                    }}
                                >
                                    {selectedOwner !== owner && (
                                        <Button
                                            size={SIZES.medium}
                                            color={COLORS.flat}
                                            leftMargin={false}
                                            fullWidth={false}
                                            onClick={() => startRemoveOwner(owner)}
                                            disabled={isHidden}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCircleXmark}
                                                style={{ marginRight: '0.75rem' }}
                                            />
                                            Remove
                                        </Button>
                                    )}
                                    {selectedOwner === owner && (
                                        <>
                                            {isLoading && (
                                                <div className={styles.spinner}>
                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                                </div>
                                            )}
                                            {!isLoading && (
                                                <>
                                                    <Button
                                                        size={SIZES.medium}
                                                        color={COLORS.flat}
                                                        otherClasses={styles.confirm}
                                                        leftMargin={false}
                                                        fullWidth={false}
                                                        onClick={() => handleRemoveOwnerConfirm(owner)}
                                                        disabled={isHidden}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                            style={{ marginRight: '0.5rem' }}
                                                        />
                                                        Confirm
                                                    </Button>
                                                    <Button
                                                        size={SIZES.medium}
                                                        color={COLORS.flat}
                                                        otherClasses={styles.cancel}
                                                        leftMargin={false}
                                                        fullWidth={false}
                                                        onClick={() => handleRemoveOwnerCancel(owner)}
                                                        disabled={isHidden}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faBan}
                                                            style={{ marginRight: '0.5rem' }}
                                                        />
                                                        Cancel
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    {isAddingOwner && (
                        <TableRow>
                            <TableCell />
                            <TableCell>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="filled"
                                    type="email"
                                    fullWidth
                                    value={formik.values.email}
                                    onChange={handleChange}
                                    onBlur={formik.handleBlur}
                                    helperText={
                                        formik.errors.email
                                        && formik.touched.email && formik.isSubmitting && formik.errors.email
                                    }
                                    error={
                                        formik.errors.email
                                        && formik.touched.email && formik.isSubmitting
                                    }
                                    disabled={isHidden}
                                />
                            </TableCell>
                            <TableCell align="right">
                                {isLoading && (
                                    <div className={styles.spinner}>
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    </div>
                                )}
                                {!isLoading && (
                                    <Stack
                                        spacing={0}
                                        direction="row"
                                        sx={{
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <Button
                                            size={SIZES.medium}
                                            color={COLORS.flat}
                                            leftMargin={false}
                                            fullWidth={false}
                                            onClick={() => {
                                                handleAddOwnerCancel();
                                            }}
                                            disabled={isHidden}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size={SIZES.medium}
                                            leftMargin={false}
                                            fullWidth={false}
                                            onClick={() => {
                                                handleAddOwner();
                                            }}
                                            disabled={isHidden}
                                        >
                                            Add
                                        </Button>
                                    </Stack>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

OwnerView.propTypes = {
    owners: PropTypes.arrayOf(PropTypes.any),
    salesforceId: PropTypes.string,
    advertiserId: PropTypes.number,
    isAddingOwner: PropTypes.bool.isRequired,
    onOwnerAdded: PropTypes.func.isRequired,
    onOwnerAddedCancelled: PropTypes.func.isRequired,
    onOwnerRemoved: PropTypes.func.isRequired,
    isHidden: PropTypes.bool,
};

OwnerView.defaultProps = {
    owners: [],
    salesforceId: undefined,
    advertiserId: undefined,
    isHidden: false,
};

export default OwnerView;
