import { useAuth0 } from '@auth0/auth0-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import Hotjar from '@hotjar/browser';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    lazy,
    Suspense,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import ReactGA from 'react-ga4';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';

import { defineAbility } from '../../auth/authHelpers';
import { AbilityContext } from '../../auth/Can';
import { faSicCodeLibrary } from '../../constants/faSicCodeLibrary';
import * as routes from '../../constants/routes';
import {
    ALIGN,
    BACKGROUND_COLORS,
    COLORS,
    POL_THEME,
    ThemeContext,
} from '../../constants/theme';
import { AppContext } from '../../context/AppContext';
import { genCanonicalLink } from '../../utils/canonical';
import {
    usePrevious,
    useWindowSize,
} from '../../utils/hooks';

// Most important components loaded up front
import HomeContainer from '../home/HomeContainer';
import Error404 from '../error-404/404-error';
import Error500 from '../error-500/500-error';
import Footer from '../footer/Footer';
import PublicationPage from '../publication-page/PublicationPage';
import Loader from '../shared/Loader';

import AppBar from './AppBar';
import { setMinHeight } from './webpageHeight';

import styles from './AppContainer.scss';

const About = lazy(() => import('../about/About'));
const Advertise = lazy(() => import('../advertise/Advertise'));
const AdvertisementForm = lazy(() => import('../AdvertisementForm/AdvertisementForm'));
const ExpandedEmailContent = lazy(() => import('../expanded-email-content/ExpandedEmailContent'));
const Help = lazy(() => import('../help/Help'));
const ListingRedirect = lazy(() => import('../listing-redirect/ListingRedirect'));
const OrganizationDetailContainer = lazy(() => import('../organization-detail/OrganizationDetailContainer'));
const PublicationWidget = lazy(() => import('../publication-widget/PublicationWidget'));
const SearchContainer = lazy(() => import('../search/SearchContainer'));
const SettingsContainer = lazy(() => import('../settings/SettingsContainer'));
const SponsorDetailContainer = lazy(() => import('../sponsor-detail/SponsorDetailContainer'));
const SupportersPage = lazy(() => import('../supporters-page/SupportersContainer'));
const TermsConditions = lazy(() => import('../terms-conditions/TermsConditions'));

// Add SIC code icons to FA library import
library.add(...faSicCodeLibrary);

// Toastify styles
injectStyle();

// Background Images
const POL_BG_IMG = `${process.env.CUSTOMER_DATA_S3_URL}/home/ParishesOnline-home_bg.jpg`;
const MCO_BG_IMG = `${process.env.CUSTOMER_DATA_S3_URL}/home/MyCommunityOnline-home_bg.jpg`;

const AppContainer = () => {
    const location = useLocation();
    const path = location.pathname;
    const { pageTitle, pageDescription } = useContext(AppContext);
    const prevPageTitle = usePrevious(pageTitle);

    const isSearchPage = path === routes.HOME || path === routes.SUPPORTERS;
    const widgetPage = path === routes.PUBLICATION_WIDGET;

    const themeContext = useContext(ThemeContext);

    // Hotjar tracking code
    useEffect(() => {
        if (process.env.PUBLISH_ENV === 'production' && themeContext === POL_THEME) {
            const siteId = 4951827;
            const hotjarVersion = 6;

            Hotjar.init(siteId, hotjarVersion);
        }
    }, [themeContext]);

    useEffect(() => {
        // Trigger pageview if title is not default value and has changed
        if (
            pageTitle !== 'Online Directory'
            && pageTitle !== prevPageTitle
        ) {
            document.title = pageTitle;

            const currentDomain = `${themeContext === POL_THEME ? 'parishes' : 'mycommunity'}online`;
            const canonicalLink = genCanonicalLink(currentDomain, location.pathname, location.search);
            const canonicalTag = document.querySelector('link[rel="canonical"]');
            canonicalTag.setAttribute('href', canonicalLink);

            const metaDescriptionTag = document.querySelector('meta[name="description"]');
            metaDescriptionTag.setAttribute('content', pageDescription);

            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname,
                title: pageTitle,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageTitle]);

    // Set page height using window width to handle CLS issues on different pages
    const [pageHeight, setPageHeight] = useState('100vh');
    const windowWidth = useWindowSize();

    useEffect(() => {
        setPageHeight(setMinHeight(location.pathname, location.search, windowWidth));
    }, [location, windowWidth]);

    const appContainerRef = useRef();
    const { user } = useAuth0();

    let backgroundStyle = '';
    let backgroundImage = '';
    if (isSearchPage) {
        backgroundStyle = themeContext === POL_THEME ? styles.polBackgroundImg : styles.mcoBackgroundImg;
        backgroundImage = themeContext === POL_THEME ? POL_BG_IMG : MCO_BG_IMG;
    }

    // Render Widget
    if (widgetPage) {
        return (
            <div className={styles.widgetDiv}>
                <ToastContainer position="bottom-center" theme="dark" />
                <Suspense fallback={<Loader color={COLORS.secondary} align={ALIGN.center} />}>
                    <PublicationWidget />
                </Suspense>
            </div>
        );
    }

    // Render main website

    // The ToastContainer is in the same div as the AppBar so that the ToastContainer
    // does not take up space in the main div
    return (
        <AbilityContext.Provider value={defineAbility(user)}>
            <div className={classNames(styles.appDiv, isSearchPage && styles.appDivHasSearch)}>
                <div
                    className={classNames(styles.container, backgroundStyle)}
                    ref={appContainerRef}
                    style={{
                        backgroundImage: `url('${backgroundImage}')`,
                        minHeight: pageHeight,
                    }}
                >
                    <div className={styles.appBarContainer}>
                        <AppBar isSearchPage={isSearchPage} />
                        <ToastContainer position="bottom-center" theme="dark" />
                    </div>
                    <Suspense fallback={<Loader color={COLORS.secondary} align={ALIGN.center} />}>
                        <Routes>
                            <Route path={routes.HOME} element={<HomeContainer />} />
                            <Route path={routes.SUPPORTERS} element={<HomeContainer isSupporterHome />} />
                            <Route path={routes.ORGANIZATION_SUPPORTERS_PAGE} element={<SupportersPage />} />
                            <Route path={routes.ORGANIZATION_EMAIL_CONTENT_PAGE} element={<ExpandedEmailContent />} />
                            <Route path={routes.SEARCH} element={<SearchContainer />} />
                            <Route path={routes.TERMSCONDITIONS} element={<TermsConditions />} />
                            <Route path={routes.ABOUT} element={<About />} />
                            <Route path={routes.ADVERTISE} element={<Advertise />} />
                            <Route path={routes.HELP} element={<Help />} />
                            <Route path={routes.ERROR_404} element={<Error404 />} />
                            <Route path={routes.FIND_SUPPORTERS_PAGE} element={<ListingRedirect supportersPage />} />
                            <Route path={routes.ERROR_500} element={<Error500 />} />
                            <Route path={routes.FIND_BULLETIN_PAGE} element={<PublicationPage />} />
                            <Route path={routes.FIND} element={<ListingRedirect />} />
                            <Route path={routes.ORGANIZATION_BULLETIN_PAGE} element={<PublicationPage />} />
                            <Route path={routes.PUBLICATION_PAGE} element={<PublicationPage />} />
                            <Route path={routes.ORGANIZATION_DETAIL_VARIANT} element={<OrganizationDetailContainer />} />
                            <Route path={routes.ORGANIZATION_DETAIL} element={<OrganizationDetailContainer />} />
                            <Route path={routes.SPONSOR_DETAIL} element={<SponsorDetailContainer />} />
                            <Route path={routes.SETTINGS} element={<PrivateRoute component={<SettingsContainer />} />} />
                            <Route path={routes.ADVERTISEMENT_FORM} element={<AdvertisementForm appContainerRef={appContainerRef} />} />
                            <Route path="/" element={<Navigate to={routes.HOME} />} />
                            <Route path="*" element={<Navigate to={routes.ERROR_404} />} />
                        </Routes>
                    </Suspense>
                    <Footer
                        showBackgroundImage={isSearchPage}
                        containerBackgroundColor={
                            (path === routes.SETTINGS || path === routes.FLIPBOOK)
                                ? BACKGROUND_COLORS.vistaWhite
                                : BACKGROUND_COLORS.white
                        }
                    />
                </div>
            </div>
        </AbilityContext.Provider>
    );
};

const PrivateRoute = ({ component }) => {
    const {
        isAuthenticated,
        loginWithRedirect,
        isLoading,
    } = useAuth0();

    if (isLoading) {
        return null;
    }

    return isAuthenticated ? component : loginWithRedirect();
};

PrivateRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
};

export default AppContainer;
