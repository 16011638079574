import Slider from '@mui/material/Slider';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './SliderRadio.scss';

const SliderRadio = ({
    classes,
    id,
    label,
    isLabelShown,
    labelDisplay,
    name,
    errors,
    touched,
    valueLabelFormat,
    step,
    marks,
    track,
    min,
    max,
    value,
    setValue,
}) => {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classNames(styles.container, classes.root, 'formItem')}>
            {label && isLabelShown && (
                <label
                    className={classNames(styles.label, classes.label)}
                    htmlFor={id}
                >
                    {label}
                </label>
            )}
            <Slider
                key={`slider-${id}`}
                id={id}
                valueLabelDisplay={labelDisplay}
                name={name}
                value={value}
                onChangeCommitted={handleChange}
                min={min}
                max={max}
                step={step}
                marks={marks}
                track={track}
                // This shows the tooltip text
                valueLabelFormat={valueLabelFormat}
                {...(!isLabelShown && { 'aria-label': label })}
            />
            {errors[name]
            && touched[name] && (
                <div className={styles.errors}>
                    {errors[name]}
                </div>
            )}
        </div>
    );
};

SliderRadio.propTypes = {
    classes: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        select: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isLabelShown: PropTypes.bool,
    name: PropTypes.string.isRequired,
    labelDisplay: PropTypes.string,
    valueLabelFormat: PropTypes.func,
    // eslint-disable-next-line react/require-default-props
    errors: PropTypes.shape({}),
    // eslint-disable-next-line react/require-default-props
    touched: PropTypes.shape({}),
    step: PropTypes.number,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    track: PropTypes.bool,
    marks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    })),
    value: PropTypes.number,
    setValue: PropTypes.func,
};

SliderRadio.defaultProps = {
    classes: {
        label: null,
        root: null,
        select: null,
    },
    labelDisplay: 'auto', // Can be 'on', 'auto', or 'off'
    step: 1,
    valueLabelFormat: (value) => value,
    isLabelShown: true,
    track: false,
    marks: [],
    value: 0,
    setValue: () => {},
};

export default SliderRadio;
