import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import styles from './ToggleGroup.scss';

const ToggleGroup = ({
    classes,
    id,
    label,
    name,
    errors,
    touched,
    options,
    color,
    ...otherProps
}) => {
    const handleChange = (event, value) => {
        if (value && value.length) {
            otherProps.setValue(value);
        }
    };

    return (
        <div className={classNames(classes.container, styles.togglegroup, 'formItem')}>
            <label
                className={classes.label}
                htmlFor={id}
            >
                {label}
            </label>
            <ToggleButtonGroup
                size="small"
                exclusive
                onChange={handleChange}
                value={otherProps.value}
            >
                {options.map((currentOption) => (
                    <ToggleButton
                        className={styles.togglePadding}
                        id={`button-${currentOption.value}`}
                        key={`button-${currentOption.value}`}
                        value={currentOption.value}
                    >
                        {currentOption.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
};

ToggleGroup.propTypes = {
    classes: PropTypes.shape({
        input: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        container: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/require-default-props
    errors: PropTypes.shape({}),
    // eslint-disable-next-line react/require-default-props
    touched: PropTypes.shape({}),
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    color: PropTypes.string,
};

ToggleGroup.defaultProps = {
    classes: {
        input: null,
        label: null,
        root: null,
        container: null,
    },
    color: '',
    onChange: () => {},
};

export default ToggleGroup;
