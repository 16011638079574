import { faLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@mui/material/Grid2';
import Popover from '@mui/material/Popover';
import Stack from '@mui/system/Stack';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
    Form,
    Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';

import {
    WIDGET_BACKGROUND_TRANSPARENT,
    WIDGET_BACKGROUND_WHITE,
    WIDGET_DEFAULT_COLOR,
    WIDGET_MAX_WIDTH_PX_MULTIPLE,
    WIDGET_MAX_WIDTH_PX_SINGLE,
    WIDGET_NUM_PUBS_MULTIPLE,
    WIDGET_NUM_PUBS_SINGLE,
    WIDGET_TEXT_LARGE,
    WIDGET_TEXT_NORMAL,
    WIDGET_TYPE_BULLETIN,
    WIDGET_TYPE_NEWSLETTER,
    WIDGET_TYPE_PUBLICATION,
} from '../../../../constants/publications';
import { PUBLICATION_WIDGET } from '../../../../constants/routes';
import {
    COLORS,
    POL_THEME,
    ThemeContext,
} from '../../../../constants/theme';
import { getProtocolAndHostname } from '../../../../utils/url';

import PublicationWidget from '../../../publication-widget/PublicationWidget';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import FormField from '../../../shared/FormElements/FormField';
import ToggleGroup from '../../../shared/FormElements/ToggleGroup';

import styles from './PublicationsWidgetDrawer.scss';

const BACKGROUND_TOGGLE_STATES = [
    {
        label: 'White',
        value: WIDGET_BACKGROUND_WHITE,
    },
    {
        label: 'Transparent',
        value: WIDGET_BACKGROUND_TRANSPARENT,
    },
];

const NUM_PUBS_TOGGLE_STATES = [
    {
        label: '4',
        value: WIDGET_NUM_PUBS_MULTIPLE,
    },
    {
        label: '1',
        value: WIDGET_NUM_PUBS_SINGLE,
    },
];

const TEXT_SIZE_TOGGLE_STATES = [
    {
        label: 'Normal',
        value: WIDGET_TEXT_NORMAL,
    },
    {
        label: 'Large',
        value: WIDGET_TEXT_LARGE,
    },
];

const TYPE_TOGGLE_STATES_POL = [
    {
        label: 'Bulletin',
        value: WIDGET_TYPE_BULLETIN,
    },
    {
        label: 'Publication',
        value: WIDGET_TYPE_PUBLICATION,
    },
];

const TYPE_TOGGLE_STATES_MCO = [
    {
        label: 'Newsletter',
        value: WIDGET_TYPE_NEWSLETTER,
    },
    {
        label: 'Publication',
        value: WIDGET_TYPE_PUBLICATION,
    },
];

const FORM_FIELD_NAMES = {
    backgroundToggle: 'background_toggle',
    colorPicker: 'colors_toggle',
    numPubsToggle: 'num_pubs_toggle',
    textSizeToggle: 'text_size_toggle',
    typeToggle: 'type_toggle',
};

const UNIQUE_ID = 'PublicationsWidgetDrawer';

const PublicationsWidgetDrawer = ({
    salesforceId,
    isDrawerOpen,
    setIsDrawerOpen,
}) => {
    const themeContext = useContext(ThemeContext);
    const sitePrefix = getProtocolAndHostname(themeContext);

    const formInitialValues = {
        [FORM_FIELD_NAMES.backgroundToggle]: WIDGET_BACKGROUND_WHITE,
        [FORM_FIELD_NAMES.colorPicker]: WIDGET_DEFAULT_COLOR,
        [FORM_FIELD_NAMES.numPubsToggle]: WIDGET_NUM_PUBS_MULTIPLE,
        [FORM_FIELD_NAMES.textSizeToggle]: WIDGET_TEXT_NORMAL,
        [FORM_FIELD_NAMES.typeToggle]: themeContext === POL_THEME
            ? WIDGET_TYPE_BULLETIN
            : WIDGET_TYPE_NEWSLETTER,
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [, forceUpdate] = useState(Date.now()); // Used to trigger page re-renders

    const open = !!anchorEl;
    const popoverId = open ? 'simple-popover' : undefined;

    const TYPE_TOGGLE_STATES = themeContext === POL_THEME
        ? TYPE_TOGGLE_STATES_POL
        : TYPE_TOGGLE_STATES_MCO;

    const handleResize = useCallback(() => forceUpdate(Date.now()), []);
    const debouncedHandleResize = useMemo(() => debounce(handleResize, 200), [handleResize]);

    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize);

        // Cleanup function
        return () => {
            debouncedHandleResize.cancel();
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [debouncedHandleResize]);

    const getWidgetHtml = (type, color, textSize, numPubs, background) => `<iframe
        id="LPI.Pub.Widget.v1"
        scrolling="no"
        frameBorder="0"
        style="width: 100%; height: 100%; max-width: ${numPubs === '4' ? WIDGET_MAX_WIDTH_PX_MULTIPLE : WIDGET_MAX_WIDTH_PX_SINGLE}; border: none;"
        src="${sitePrefix}${PUBLICATION_WIDGET}?type=${type}&id=${salesforceId}&textSize=${textSize}&numPubsShown=${numPubs}&color=${encodeURIComponent(color)}&background=${background}"
    ></iframe>
    <script src="https://wurfl.io/wurfl.js" crossorigin></script>
    <script src="${sitePrefix}/publicationWidget.js"></script>`;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleChange = (values) => {
        // eslint-disable-next-line no-param-reassign,max-len
        values[FORM_FIELD_NAMES.widgetCode] = getWidgetHtml(values[FORM_FIELD_NAMES.typeToggle], values[FORM_FIELD_NAMES.colorPicker], values[FORM_FIELD_NAMES.textSizeToggle], values[FORM_FIELD_NAMES.numPubsToggle], values[FORM_FIELD_NAMES.backgroundToggle]);
    };

    return (
        <Drawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
        >
            <h2 className={styles.mainHeading}>Customize Your Widget</h2>
            <div
                data-title="Publication Widget"
                data-key="PublicationWidget"
                className={classNames(styles.formContainer)}
            >
                <Formik
                    initialValues={formInitialValues}
                >
                    {({
                        // eslint-disable-next-line no-unused-vars
                        errors, touched, isValid, dirty, values,
                    }) => (
                        <Form>
                            <Grid
                                className={styles.allTogglesContainer}
                                container
                                spacing={2}
                            >
                                <Grid
                                    className={classNames('toggleContainer')}
                                    size={{
                                        xs: 12,
                                        sm: 6,
                                    }}
                                >
                                    <FormField
                                        component={ToggleGroup}
                                        name={FORM_FIELD_NAMES.typeToggle}
                                        options={TYPE_TOGGLE_STATES}
                                        id={`${UNIQUE_ID}-${FORM_FIELD_NAMES.typeToggle}`}
                                        onChange={handleToggleChange(values)}
                                        errors={errors[FORM_FIELD_NAMES.typeToggle]}
                                        touched={touched[FORM_FIELD_NAMES.typeToggle]}
                                        label="Button Language"
                                        disabled={!isDrawerOpen}
                                    />
                                </Grid>
                                <Grid
                                    className={classNames('toggleContainer')}
                                    size={{
                                        xs: 12,
                                        sm: 6,
                                    }}
                                >
                                    <FormField
                                        component={ToggleGroup}
                                        name={FORM_FIELD_NAMES.textSizeToggle}
                                        options={TEXT_SIZE_TOGGLE_STATES}
                                        id={`${UNIQUE_ID}-${FORM_FIELD_NAMES.textSizeToggle}`}
                                        onChange={handleToggleChange(values)}
                                        errors={errors[FORM_FIELD_NAMES.textSizeToggle]}
                                        touched={touched[FORM_FIELD_NAMES.textSizeToggle]}
                                        label="Text Size"
                                        disabled={!isDrawerOpen}
                                    />
                                </Grid>
                                <Grid
                                    className={classNames('toggleContainer')}
                                    size={{
                                        xs: 12,
                                        sm: 6,
                                    }}
                                >
                                    <FormField
                                        component={ToggleGroup}
                                        name={FORM_FIELD_NAMES.numPubsToggle}
                                        options={NUM_PUBS_TOGGLE_STATES}
                                        id={`${UNIQUE_ID}-${FORM_FIELD_NAMES.numPubsToggle}`}
                                        onChange={handleToggleChange(values)}
                                        errors={errors[FORM_FIELD_NAMES.numPubsToggle]}
                                        touched={touched[FORM_FIELD_NAMES.numPubsToggle]}
                                        label="Number of Publications Shown"
                                        disabled={!isDrawerOpen}
                                    />
                                </Grid>
                                <Grid
                                    className={classNames('toggleContainer')}
                                    size={{
                                        xs: 12,
                                        sm: 6,
                                    }}
                                >
                                    <FormField
                                        component={ToggleGroup}
                                        name={FORM_FIELD_NAMES.backgroundToggle}
                                        options={BACKGROUND_TOGGLE_STATES}
                                        id={`${UNIQUE_ID}-${FORM_FIELD_NAMES.backgroundToggle}`}
                                        onChange={handleToggleChange(values)}
                                        errors={errors[FORM_FIELD_NAMES.backgroundToggle]}
                                        touched={touched[FORM_FIELD_NAMES.backgroundToggle]}
                                        label="Background"
                                        disabled={!isDrawerOpen}
                                    />
                                </Grid>
                            </Grid>
                            <hr className={styles.pageBreak} />
                            <h2 className={styles.mainHeading}>Widget Preview</h2>
                            <div>
                                <PublicationWidget
                                    defaultBackground={values[FORM_FIELD_NAMES.backgroundToggle]}
                                    defaultNumPubsShown={values[FORM_FIELD_NAMES.numPubsToggle]}
                                    defaultSalesforceId={salesforceId}
                                    defaultTextSize={values[FORM_FIELD_NAMES.textSizeToggle]}
                                    defaultType={values[FORM_FIELD_NAMES.typeToggle]}
                                    isHidden={!isDrawerOpen}
                                />

                                <h3 className={styles.widgetCodeCopyText}>
                                    Please copy and paste the code into your website once your widget design is complete.
                                </h3>

                                <Stack
                                    direction="row"
                                    sx={{
                                        justifyContent: 'space-between',
                                    }}
                                    mt="35px"
                                >
                                    <div className={styles.copyButton}>
                                        <CopyToClipboard text={values[FORM_FIELD_NAMES.widgetCode]}>
                                            <Button
                                                onClick={handleClick}
                                                otherClasses={styles.buttonMargin}
                                                disabled={!isDrawerOpen}
                                            >
                                                <FontAwesomeIcon icon={faLink} />
                                                &nbsp;
                                                Copy Widget Code
                                            </Button>
                                        </CopyToClipboard>
                                        <Button
                                            color={COLORS.flat}
                                            onClick={() => setIsDrawerOpen(false)}
                                            disabled={!isDrawerOpen}
                                        >
                                            Close
                                        </Button>
                                        <Popover
                                            id={`${UNIQUE_ID}-${popoverId}`}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <p className={styles.popoverText}>Code copied!</p>
                                        </Popover>
                                    </div>
                                </Stack>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Drawer>
    );
};

PublicationsWidgetDrawer.propTypes = {
    salesforceId: PropTypes.string.isRequired,
    isDrawerOpen: PropTypes.bool.isRequired,
    setIsDrawerOpen: PropTypes.func.isRequired,
};

export default PublicationsWidgetDrawer;
