import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { SIZES, COLORS, ALIGN } from '../../../constants/theme';
import styles from './Loader.scss';

// TODO: The 'size' property does not seem to be working anymore, may need to be fixed if we need a non-standard size.
const Loader = ({
    classes, color, size, align, isDrawerLoader,
}) => (
    <div
        className={
            isDrawerLoader
                ? classNames(styles.container, styles.drawerLoaderContainer, classes.root, styles[align])
                : null
        }
    >
        <div
            className={
                classNames(!isDrawerLoader ? styles.container : '', styles.loaderContainer, classes.root, styles[color], styles[align], styles[size])
            }
        >
            <FontAwesomeIcon
                className={styles.loadingIcon}
                icon={faSpinnerThird}
                spin
            />
        </div>
    </div>
);

Loader.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    color: PropTypes.oneOf(Object.values(COLORS)),
    size: PropTypes.oneOf(Object.values(SIZES)),
    align: PropTypes.oneOf(Object.values(ALIGN)),
    isDrawerLoader: PropTypes.bool,
};

Loader.defaultProps = {
    classes: {
        root: null,
    },
    color: COLORS.default,
    size: SIZES.medium,
    align: ALIGN.center,
    isDrawerLoader: false,
};

export default Loader;
