import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COLORS, SIZES } from '../../../../constants/theme';
import styles from './Select.scss';
import Loader from '../../Loader';

const Select = ({
    children,
    classes,
    fullWidth,
    id,
    label,
    name,
    size,
    value,
    marginBottom,
    initialValue,
    initialError,
    initialTouched,
    setValue,
    setTouched,
    setError,
    touched,
    optional,
    showLabel,
    isLoading,
    ...otherProps
}) => (
    <div className={classNames(styles.container, classes.root, styles[size])}>
        <div
            className={classNames({
                [styles.marginBottom]: marginBottom,
            })}
        >
            {isLoading && <Loader color={COLORS.secondary} classes={{ root: classes.loader }} />}
            <select
                className={classNames(styles.select, classes.root, {
                    [styles.fullWidth]: fullWidth,
                    [styles.hasValue]: value,
                })}
                id={id}
                name={name}
                value={value}
                disabled={isLoading}
                {...otherProps}
            >
                {children}
            </select>
            {showLabel && (
                <label
                    className={classNames(styles.label, classes.label)}
                    htmlFor={id}
                >
                    {label}
                    <span className="italic">{`${optional ? ' (Optional)' : ''}`}</span>
                </label>
            )}
        </div>
    </div>
);

Select.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
    classes: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        select: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        loader: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    fullWidth: PropTypes.bool,
    marginBottom: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.oneOf(Object.values(SIZES)),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValue: PropTypes.string,
    initialTouched: PropTypes.bool,
    initialError: PropTypes.string,
    setValue: PropTypes.func,
    setTouched: PropTypes.func,
    setError: PropTypes.func,
    touched: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    optional: PropTypes.bool,
    showLabel: PropTypes.bool,
    isLoading: PropTypes.bool,
};

Select.defaultProps = {
    classes: {
        label: null,
        root: null,
        select: null,
        loader: null,
    },
    fullWidth: false,
    marginBottom: true,
    size: SIZES.medium,
    value: undefined,
    initialValue: '',
    initialError: '',
    initialTouched: false,
    setValue: {},
    setTouched: {},
    setError: {},
    touched: {},
    optional: false,
    showLabel: true,
    isLoading: false,
};

export default Select;
