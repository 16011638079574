import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { FileUploader } from 'react-drag-drop-files';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileXmark } from '@fortawesome/pro-duotone-svg-icons';

export const FileUploadContainer = ({
    fileName, fileUrl, onClear, ...rest
}) => (fileUrl ? (
    <ImageList variant="masonry">
        <ImageListItem
            key={`uploaded-item-${fileName}`}
            sx={{
                display: 'inline-block',
            }}
        >
            <img
                src={fileUrl}
                alt="Uploaded File Preview"
                loading="lazy"
                border="1rem dashed #c7c6c6"
            />
            <ImageListItemBar
                title={fileName}
                key={`uploaded-item-bar-${fileName}`}
                position="below"
                actionIcon={(
                    <IconButton
                        key={`uploaded-item-button-${fileName}`}
                        aria-label={`uploade file ${fileName}`}
                        onClick={onClear}
                    >
                        <FontAwesomeIcon icon={faFileXmark} color="#00a5aa" size="lg" />
                    </IconButton>
                )}
            />
        </ImageListItem>
    </ImageList>
) : (<FileUploader {...rest} />));

FileUploadContainer.propTypes = {
    fileName: PropTypes.string.isRequired,
    fileUrl: PropTypes.string,
    onClear: PropTypes.func.isRequired,
};

FileUploadContainer.defaultProps = {
    fileUrl: '',
};
