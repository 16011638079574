import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useState,
} from 'react';
import { toast } from 'react-toastify';

import { getAuthToken } from '../../../../auth/authHelpers';
import { ALL_PUB_EXCLUSIONS } from '../../../../constants/publications';
import customerDataRequest from '../../../../services/api/customer-data';

import PublicationsView from '../../../publications-manager/publications-view';
import Drawer from '../../../shared/Drawer';
import {
    OFFSET_IMMEDIATELY,
    OFFSET_ON_DISTRIBUTION_DATE_VALUE,
    PublicationSettingsForm,
    TOGGLE_IMMEDIATELY_VALUE,
    TOGGLE_SCHEDULE_VALUE,
} from '../../../shared/Forms/PublicationsSettings';
import { Tabs } from '../../../shared/Tabs';

const PublicationsManageDrawer = ({
    isDrawerOpen,
    setIsDrawerOpen,
    publicationsData,
    salesforceId,
    isPublicationCustomer,
    loadPublications,
    arePublicationsLoading,
    setArePublicationsLoading,
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [publicationSettings, setPublicationSettings] = useState({});
    const [managePublicationsTitle, setManagePublicationsTitle] = useState('Manage Publications');
    const [isAddEditView, setIsAddEditView] = useState(false);
    const [allExclusions, setAllExclusions] = useState([]);

    useEffect(() => {
        // grabbing existing publication settings
        if (isDrawerOpen) {
            (async () => {
                const token = await getAuthToken(getAccessTokenSilently);

                try {
                    await customerDataRequest('get', `/organizations/${salesforceId}/publication-settings`, token).then((response) => {
                        const settings = {};

                        if (response.data.data.display_offset === OFFSET_IMMEDIATELY) {
                            settings.show_publication_toggle = TOGGLE_IMMEDIATELY_VALUE;
                            settings.publication_offset = OFFSET_ON_DISTRIBUTION_DATE_VALUE;
                        } else {
                            settings.show_publication_toggle = TOGGLE_SCHEDULE_VALUE;
                            settings.publication_offset = response.data.data.display_offset;
                        }
                        settings.exclusions = response.data.data.exclusions.map((exclusion) => ({
                            label: exclusion.publication_type,
                            value: exclusion.publication_type_abbreviation,
                        }));

                        setPublicationSettings(settings);
                    });
                } catch (error) {
                    setPublicationSettings({});
                }
            })();
        }
    }, [getAccessTokenSilently, isDrawerOpen, salesforceId]);

    useEffect(() => {
        setAllExclusions(ALL_PUB_EXCLUSIONS.map((exclusion) => ({
            label: exclusion.publication_type,
            value: exclusion.publication_type_abbreviation,
        })));
    }, []);

    const updatePublicationSettings = (exclusions, offset) => {
        (async () => {
            setArePublicationsLoading(true);
            const token = await getAuthToken(getAccessTokenSilently);

            await customerDataRequest('put', `/organizations/${salesforceId}/publication-settings`, token, {
                publicationExclusions: exclusions,
                publicationOffset: offset,
            })
                .then(() => {
                    setIsDrawerOpen(false);
                    setArePublicationsLoading(false);
                    toast.success('Publication settings updated');
                })
                .catch(() => {
                    setArePublicationsLoading(false);
                    toast.error('There was an error updating publication settings. Please try again in a few minutes.');
                });
        })();
    };

    return (
        <Drawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
        >
            <h2>{managePublicationsTitle}</h2>
            <div>
                <Tabs isHidden={!isDrawerOpen}>
                    {!arePublicationsLoading && (
                        <div data-title="Publications" data-key="publications">
                            <PublicationsView
                                displayPublications={publicationsData}
                                setManagePublicationsTitle={setManagePublicationsTitle}
                                setIsDrawerOpen={setIsDrawerOpen}
                                salesforceId={salesforceId}
                                loadPublications={loadPublications}
                                isAddEditView={isAddEditView}
                                setIsAddEditView={setIsAddEditView}
                                onCancel={() => setIsDrawerOpen(false)}
                                isHidden={!isDrawerOpen}
                            />
                        </div>
                    )}
                    {isPublicationCustomer && !isAddEditView && (
                        <div data-title="Settings" data-key="settings">
                            <PublicationSettingsForm
                                uniqueId="publicationsSettings"
                                allExclusions={allExclusions}
                                values={publicationSettings}
                                updatePublicationSettings={updatePublicationSettings}
                                onCancel={() => setIsDrawerOpen(false)}
                                isHidden={!isDrawerOpen}
                            />
                        </div>
                    )}
                </Tabs>
            </div>
        </Drawer>
    );
};

PublicationsManageDrawer.propTypes = {
    isDrawerOpen: PropTypes.bool.isRequired,
    setIsDrawerOpen: PropTypes.func.isRequired,
    publicationsData: PropTypes.array.isRequired,
    salesforceId: PropTypes.string,
    isPublicationCustomer: PropTypes.bool,
    arePublicationsLoading: PropTypes.bool.isRequired,
    setArePublicationsLoading: PropTypes.func.isRequired,
    loadPublications: PropTypes.func.isRequired,
};

PublicationsManageDrawer.defaultProps = {
    isPublicationCustomer: false,
    salesforceId: null,
};

export default PublicationsManageDrawer;
