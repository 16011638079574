export const loadCss = (src) => {
    // Check if stylesheet already exists before loading it
    if (document.querySelector(`link[href="${src}"]`)) {
        return;
    }

    const cssLink = document.createElement('link');
    cssLink.href = src;
    cssLink.rel = 'stylesheet';
    cssLink.type = 'text/css';
    document.head.appendChild(cssLink);
};

export const loadScript = (src) => {
    // Check if script already exists before loading it
    if (document.querySelector(`script[src="${src}"]`)) {
        return null;
    }

    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    document.body.appendChild(script);

    return script;
};

const loadScriptAsPromise = (src) => new Promise((resolve, reject) => {
    const script = loadScript(src);

    if (script) {
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Error loading script ${src}`));
    } else {
        resolve();
    }
});

export const loadScriptsSequentially = (srcArray, callbackFn = () => null) => srcArray.reduce((promise, src) => promise.then(() => loadScriptAsPromise(src)), Promise.resolve(callbackFn()));
