import PropTypes from 'prop-types';
import React, {
    useEffect,
    useRef,
    useState,
} from 'react';

import {
    DFLIP_CSS_SRC,
    DFLIP_SCRIPT_SRC_LIST,
} from '../../../constants/site';
import {
    loadCss,
    loadScriptsSequentially,
} from '../../../utils/loadResources';

import './Flipbook.scss';

const Flipbook = ({ pdfUrl }) => {
    const flipbookRef = useRef();

    const [
        isLoadingScripts,
        setIsLoadingScripts,
    ] = useState(true);

    useEffect(() => {
        // Load dflip CSS file
        loadCss(DFLIP_CSS_SRC);

        // Load dflip JS files sequentially
        loadScriptsSequentially(DFLIP_SCRIPT_SRC_LIST, () => setIsLoadingScripts(false));
    }, []);

    useEffect(() => {
        if (
            pdfUrl
            && flipbookRef.current
            && !!window?.DFLIP?.FLIPBOOK_PAGE_MODE
        ) {
            let lastPageMode = window.DFLIP.FLIPBOOK_PAGE_MODE.SINGLE;
            const width = Number(flipbookRef.current.offsetWidth ?? flipbookRef.current.clientWidth);
            const height = width > 0 ? width / 0.75 + 50 : 700;

            // eslint-disable-next-line no-undef
            $('#flipbook-container').html('');

            window.DFLIP.defaults.onReady = ((flipbook) => {
                const { SINGLE } = window.DFLIP.FLIPBOOK_PAGE_MODE;

                document.addEventListener('fullscreenchange', () => {
                    if (document.fullscreenElement) {
                        lastPageMode = flipbook.viewer.pageMode;
                        flipbook.viewer.setPageMode({ isSingle: false });
                    } else {
                        const pageMode = flipbook.viewer.pageMode === SINGLE ? SINGLE : lastPageMode;
                        flipbook.viewer.setPageMode({ isSingle: pageMode === SINGLE });
                    }
                });
            });

            // eslint-disable-next-line no-undef
            $('#flipbook-container').flipBook(pdfUrl, {
                height,
                webgl: true,
                pageMode: 1,
                showPrintControl: false,
                leftControls: 'pageNumber',
                hideControls: 'sound,search,thumbnail',
                moreControls: 'pageMode,startPage,endPage',
                disablePageLabels: true,
                soundEnable: false,
            });
        }
    }, [pdfUrl]);

    return (
        <div className="flipbook-wrapper">
            {!isLoadingScripts && <div ref={flipbookRef} id="flipbook-container" />}
        </div>
    );
};

Flipbook.propTypes = {
    pdfUrl: PropTypes.string,
};

Flipbook.defaultProps = {
    pdfUrl: null,
};

export default Flipbook;
