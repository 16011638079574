import { faLink } from '@fortawesome/pro-light-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { inputLabelClasses } from '@mui/material/InputLabel';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import MUITextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';

import {
    COLORS,
    SIZES,
} from '../../../../constants/theme';
import {
    FONT_SIZES,
    getFontCSS,
    getFontSize,
} from '../../../../styles/fonts';

import Button from '../../Button';

import styles from './TextField.scss';

const getTextFieldStyling = (fontSizeCSSValue, inlineErrorText = null) => `
          .${inputLabelClasses.root} {
            font-size: ${fontSizeCSSValue};
            font-family: "Open Sans", tahoma, verdana, sans-serif;
          }

          .${inputLabelClasses.shrink} {
            font-size: 1.6rem;
            font-family: "Open Sans", tahoma, verdana, sans-serif;
          }

          .${formHelperTextClasses.root} {
            margin-top: 0;
            height: 0;
            font-size: 1.3rem;
            font-family: "Open Sans", tahoma, verdana, sans-serif;
            white-space: nowrap;
            ${inlineErrorText
        // eslint-disable-next-line indent
                ? `
                    position: relative;
                    top: -3.41rem;
                    left: 12rem;
                    pointer-events: none;
                ` : ''
        // eslint-disable-next-line indent
            }
          }
        `;

const STYLED_TEXT_FIELDS = {
    small: styled(MUITextField)(getTextFieldStyling(FONT_SIZES.small)),
    medium: styled(MUITextField)(getTextFieldStyling(FONT_SIZES.medium)),
    smallInlineError: styled(MUITextField)(getTextFieldStyling(FONT_SIZES.medium, true)),
    mediumInlineError: styled(MUITextField)(getTextFieldStyling(FONT_SIZES.medium, true)),
};

const getTextFieldForProps = (fontSize, inlineErrorText = false) => {
    let fontSizeToUse = fontSize;
    if (inlineErrorText) {
        fontSizeToUse += 'InlineError';
    }
    if (STYLED_TEXT_FIELDS[fontSizeToUse]) {
        return STYLED_TEXT_FIELDS[fontSizeToUse];
    }

    return STYLED_TEXT_FIELDS.medium;
};

/**
 * @param classes
 * @param shortWidth
 * @param mediumWidth
 * @param fullWidth
 * @param id
 * @param label
 * @param linkText
 * @param name
 * @param onChange
 * @param onBlur
 * @param required
 * @param showOptional True to show the (Optional) label if the field is not required.
 * @param marginBottom
 * @param size
 * @param fontSize
 * @param value
 * @param errors
 * @param error
 * @param touched
 * @param setTouched
 * @param setError
 * @param setValue
 * @param initialError
 * @param initialTouched
 * @param initialValue
 * @param className
 * @param multiline
 * @param copyClipboard
 * @param tooltip Tooltip text
 * @param inlineErrorText True to display the error text inside of the TextField
 * @param urlErrorTextWrap True to allow the URL link error text to wrap.
 * @param otherProps
 * @param additionalInfo Shows additional info about the text field
 * @param afterOnChangeFunc Custom function ran after calling onChange. Arguments: 'value'
 * @returns {JSX.Element}
 */
const TextField = ({
    classes,
    shortWidth,
    mediumWidth,
    fullWidth,
    id,
    label,
    linkText,
    name,
    onChange,
    onBlur,
    required,
    showOptional,
    marginBottom,
    size,
    fontSize,
    value,
    errors,
    error,
    touched,
    setTouched,
    setError,
    setValue,
    initialError,
    initialTouched,
    initialValue,
    className,
    multiline,
    copyClipboard,
    tooltip,
    tooltipPlacement,
    inlineErrorText,
    urlErrorTextWrap,
    additionalInfo,
    afterOnChangeFunc,
    ...otherProps
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [inputCurrentValue, setInputCurrentValue] = useState(value);
    const [showTooltip, setShowTooltip] = useState(false);

    const fontSizeCSSValue = getFontSize(fontSize);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let handleInputChange = onChange;

    if (linkText || afterOnChangeFunc) {
        handleInputChange = (event) => {
            onChange({ target: { id, name, value: event.currentTarget.value } });
            if (linkText) {
                setInputCurrentValue(event.currentTarget.value);
            }
            if (afterOnChangeFunc) {
                afterOnChangeFunc(event.currentTarget.value);
            }
        };
    }

    const open = !!anchorEl;

    const popoverId = open ? 'simple-popover' : undefined;

    let newLabel = label;
    if (!required && showOptional) {
        newLabel = (
            <>
                {label}
                {' '}
                <span className="italic">
                    (Optional)
                </span>
            </>
        );
    }

    const StyledMUITextField = getTextFieldForProps(fontSize, inlineErrorText);

    const textField = (
        <StyledMUITextField
            {...otherProps}
            size={size}
            className={`${classNames(classes.input, {
                [styles.shortWidth]: shortWidth,
                [styles.mediumWidth]: mediumWidth,
                [styles.fullWidth]: fullWidth,
                // Adding the linkTextInput class
                // This applies the class if the property is linkText
                [styles.linkTextInput]: linkText,
            })} ${className}`}
            label={!linkText ? newLabel : ''}
            value={value}
            onChange={handleInputChange}
            onBlur={onBlur}
            error={!!error} // String to bool (MUI expects a bool error flag, but Formik passes a string by default)
            helperText={
                !(linkText || additionalInfo) ? errors : ''
            } // 'linkText' and `additionalInfo` type of TextField has it's own error text section.
            multiline={multiline}
            id={id}
            name={name}
            required={required}
            // Don't show '*' in label if field is required, https://github.com/mui-org/material-ui/issues/10274
            InputLabelProps={{ required: false }}
            // Font of the input text, otherProps.inputProps must be used if coming from a DatePicker
            slotProps={{
                input: otherProps.inputProps
                    ? otherProps.inputProps
                    : getFontCSS(fontSizeCSSValue),
            }}
        />
    );

    return (
        <div
            className={classNames({
                [styles.container]: linkText,
            })}
        >
            {linkText && (
                <label
                    className={classNames(styles.urlLabel, classes.label)}
                    htmlFor={id}
                >
                    {newLabel}
                </label>
            )}
            <div
                className={classNames({
                    [styles.flex]: linkText,
                    [styles.marginBottom]: marginBottom && !linkText,
                    [styles.marginBottomLinkText]: marginBottom && linkText,
                })}
            >
                {linkText && (
                    <div className={styles.linkContainer}>
                        {linkText}
                    </div>
                )}
                {textField}
                {linkText && copyClipboard && (
                    <div className={styles.iconButtonContainer}>
                        <CopyToClipboard text={linkText + inputCurrentValue}>
                            <Button
                                variant="contained"
                                onClick={handleClick}
                                color={COLORS.flat}
                                aria-label="Copy Text"
                            >
                                <FontAwesomeIcon
                                    className={styles.linkIcon}
                                    data-testid={`${id}-linkIcon`}
                                    icon={faLink}
                                />
                            </Button>
                        </CopyToClipboard>
                        <Popover
                            id={popoverId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <p className={styles.popoverText}>Link copied!</p>
                        </Popover>
                    </div>
                )}
                {tooltip && (
                    <Tooltip
                        placement={tooltipPlacement}
                        title={tooltip}
                        className={classNames(styles.tooltip, {
                            [styles.tooltipLinkText]: linkText,
                        })}
                        open={showTooltip}
                        onOpen={() => setShowTooltip(true)}
                        onClose={() => setShowTooltip(false)}
                    >
                        {/* eslint-disable-next-line max-len */}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <span
                            className={styles.infoIcon}
                            onClick={() => setShowTooltip(!showTooltip)}
                        >
                            <FontAwesomeIcon icon={faCircleInfo} />
                        </span>
                    </Tooltip>
                )}
            </div>
            {additionalInfo && (
                <p className={classNames('adminHelpText')}>
                    {additionalInfo}
                </p>
            )}
            {(linkText || additionalInfo) && (
                <div className={classNames(styles.urlErrorTextContainer)}>
                    <span className={classNames(styles.urlErrorText, urlErrorTextWrap ? styles.urlErrorTextWrap : '')}>
                        {errors}
                    </span>
                </div>
            )}
        </div>
    );
};

TextField.propTypes = {
    classes: PropTypes.shape({
        input: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    shortWidth: PropTypes.bool,
    mediumWidth: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    marginBottom: PropTypes.bool,
    linkText: PropTypes.string,
    required: PropTypes.bool,
    showOptional: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(SIZES)),
    fontSize: PropTypes.oneOf(Object.values(SIZES)),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setTouched: PropTypes.func,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    errors: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    touched: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    initialError: PropTypes.string,
    initialTouched: PropTypes.bool,
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiline: PropTypes.bool,
    className: PropTypes.string,
    copyClipboard: PropTypes.bool,
    tooltip: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    additionalInfo: PropTypes.string,
    inlineErrorText: PropTypes.bool,
    urlErrorTextWrap: PropTypes.bool,
    afterOnChangeFunc: PropTypes.func,
};

TextField.defaultProps = {
    classes: {
        input: null,
        label: null,
        root: null,
    },
    marginBottom: true,
    shortWidth: false,
    mediumWidth: false,
    fullWidth: false,
    required: false,
    showOptional: true,
    size: SIZES.small,
    fontSize: SIZES.medium,
    errors: null,
    error: null,
    touched: {},
    initialError: '',
    initialValue: '',
    onChange: null,
    onBlur: null,
    setTouched: null,
    setError: null,
    setValue: null,
    initialTouched: false,
    id: null,
    name: null,
    value: undefined,
    className: '',
    multiline: false,
    linkText: '',
    tooltip: '',
    tooltipPlacement: 'left-start',
    inlineErrorText: false,
    urlErrorTextWrap: false,
    copyClipboard: false,
    additionalInfo: '',
    afterOnChangeFunc: null,
    label: '',
};

export default TextField;
