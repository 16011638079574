import { toast } from 'react-toastify';
import get from 'lodash/get';

import customerDataRequest, { customerDataMicroserviceRequest } from '../services/api/customer-data';
import { getAuthToken } from '../auth/authHelpers';
import { isThemeOrganization } from './theme';
import * as routes from '../constants/routes';

const loadPublicationsCallback = async (salesforceId,
    type,
    setData,
    setIsLoading = null,
    limit = null,
    setErrorText = null,
    getSlug = null) => {
    let url = `/organizations/${salesforceId}/publications?type=${type}`;
    if (limit) {
        url += `&limit=${limit}`;
    }

    if (getSlug) {
        url += '&getSlug=true';
    }

    if (salesforceId) {
        if (setIsLoading) {
            setIsLoading(true);
        }
        await customerDataRequest('get', url)
            .then((res) => {
                setData(res.data.data);
                if (setIsLoading) {
                    setIsLoading(false);
                }
            })
            .catch(() => {
                if (setIsLoading) {
                    setIsLoading(false);
                }
                const errorText = 'There was an error loading publications. Please try again in a few minutes.';
                if (setErrorText) {
                    setErrorText(errorText);
                }
                toast.error(errorText);
            });
    }
};

const loadContractsCallback = async (salesforceId,
    getAccessTokenSilently,
    setIsLoading,
    setContracts,
    fullData = false,
    singleContract = false) => {
    if (salesforceId) {
        setIsLoading(true);
        const token = await getAuthToken(getAccessTokenSilently);
        customerDataRequest('get', `organizations/${salesforceId}/contracts`, token)
            .then((res) => {
                setIsLoading(false);
                if (singleContract) {
                    if (get(res, 'data.data[0]')) { // Only first contract ID
                        setContracts(res.data.data[0].contract_id);
                    }
                } else if (fullData) { // Full contract objects
                    setContracts(res.data.data);
                } else if (get(res, 'data.data[0]')) { // List of IDs
                    setContracts(res.data.data.map((contract) => contract.contract_id));
                } else {
                    setContracts(null);
                }
            })
            .catch(() => {
                setIsLoading(false);
                if (fullData) {
                    setContracts([]);
                } else {
                    setContracts(null);
                }
                toast.error(`There was an error loading the organization's
                 contracts. Please try again in a few minutes.`);
            });
    }
};

const loadOwnersCallback = async (salesforceId,
    getAccessTokenSilently,
    setIsLoading,
    setOwners) => {
    if (salesforceId) {
        setIsLoading(true);
        const token = await getAuthToken(getAccessTokenSilently);
        customerDataMicroserviceRequest('get', process.env.ORGANIZATION_OWNERSHIP_API_URL, `organizations/${salesforceId}/owners`, token)
            .then((res) => {
                setIsLoading(false);
                setOwners(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                toast.error(`There was an error loading the organization's
                owners. Please try again in a few minutes.`);
            });
    }
};

const removeOwnerCallback = async (salesforceId, ownerId, getAccessTokenSilently, setIsLoading, handleSuccess, handleError, payload) => {
    if (salesforceId && ownerId) {
        setIsLoading(true);
        const token = await getAuthToken(getAccessTokenSilently);
        customerDataMicroserviceRequest('patch', process.env.ORGANIZATION_OWNERSHIP_API_URL, `organizations/${salesforceId}/owners/${ownerId}`, token, payload)
            .then((res) => {
                setIsLoading(false);
                handleSuccess(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                handleError();
            });
    }
};

const loadOrganizationCallback = async (slug, setIsLoading, setOrganizationData, history, themeContext) => {
    setIsLoading(true);
    await customerDataRequest('get', `/organizations/slug/${slug}/`)
        .then((res) => {
            const newOrganization = res.data.data;
            if (isThemeOrganization(newOrganization, themeContext)) {
                setOrganizationData(newOrganization);
                setIsLoading(false);
            } else {
                history(`${routes.ERROR_404}`);
            }
        })
        .catch((err) => {
            setIsLoading(false);
            if (err.response && err.response.status === 404) {
                // Need to have it go to a 404 page
                history(`${routes.ERROR_404}`);
            } else {
                // Pointing all other errors to a 500 page
                history(`${routes.ERROR_500}`);
            }
        });
};

const addOwnerCallback = async (salesforceId, email, getAccessTokenSilently, setIsLoading, handleSuccess, handleError, isPOL = true) => {
    if (salesforceId && email) {
        setIsLoading(true);
        const token = await getAuthToken(getAccessTokenSilently);
        customerDataMicroserviceRequest('post', process.env.ORGANIZATION_OWNERSHIP_API_URL, `organizations/${salesforceId}/owners`, token, { email, isPOL })
            .then((res) => {
                setIsLoading(false);
                handleSuccess(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                handleError();
            });
    }
};

const addEventCallback = async (salesforceId, event, getAccessTokenSilently) => {
    if (!salesforceId || !event) {
        return {
            success: false,
        };
    }

    const token = await getAuthToken(getAccessTokenSilently);

    if (event.id) {
        const result = await customerDataMicroserviceRequest('put', process.env.EVENT_MANAGEMENT_API_URL, `organizations/${salesforceId}/events/${event.id}`, token, event);
        if (result.status === 204) {
            toast.success('Event updated');
            return {
                success: true,
                data: { ...event, event_date: `${event.event_date.split(' ').join('T')}.000Z`, deleted_at: null },
            };
        }
    } else {
        const result = await customerDataMicroserviceRequest('post', process.env.EVENT_MANAGEMENT_API_URL, `organizations/${salesforceId}/events`, token, event);
        if (result.status === 200) {
            toast.success('Event added');
            return {
                success: true,
                data: result.data,
            };
        }
    }

    toast.error('Error while saving event');
    return {
        success: false,
    };
};

const removeEventCallback = async (salesforceId, eventId, getAccessTokenSilently) => {
    if (!salesforceId || !eventId) {
        return {
            success: false,
        };
    }

    const token = await getAuthToken(getAccessTokenSilently);
    const result = await customerDataMicroserviceRequest('delete', process.env.EVENT_MANAGEMENT_API_URL, `organizations/${salesforceId}/events/${eventId}`, token);
    if (result.status === 204) {
        return {
            success: true,
        };
    }

    return {
        success: false,
    };
};

const getPdfUrlByPidCallback = async (salesforceId, pid, getAccessTokenSilently) => {
    if (!salesforceId || !pid) {
        return null;
    }

    try {
        const token = await getAuthToken(getAccessTokenSilently);
        const result = await customerDataMicroserviceRequest('get', process.env.FLIPBOOK_PDF_API_URL, `flipbook/pdf/${salesforceId}/${pid}/pdfurl`, token);

        if (result.status === 200) {
            return result.data;
        }

        return null;
    } catch (error) {
        return null;
    }
};

export {
    loadPublicationsCallback,
    loadContractsCallback,
    loadOwnersCallback,
    addOwnerCallback,
    removeOwnerCallback,
    loadOrganizationCallback,
    addEventCallback,
    removeEventCallback,
    getPdfUrlByPidCallback,
};
