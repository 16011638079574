// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-organization-detail-AdminInfo-Drawers-DigitalCommunicationsDrawer__container--tX4BK{display:flex;flex-direction:column;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/components/organization-detail/AdminInfo/Drawers/DigitalCommunicationsDrawer.scss"],"names":[],"mappings":"AACA,oGACI,YAAA,CACA,qBAAA,CACA,6BAAA","sourcesContent":["@import 'variables';\n.container {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#ec9300`,
	"secondary": `#00a5aa`,
	"secondaryDark": `#264550`,
	"backgroundBlue": `#eaf6f7`,
	"container": `src-components-organization-detail-AdminInfo-Drawers-DigitalCommunicationsDrawer__container--tX4BK`
};
export default ___CSS_LOADER_EXPORT___;
