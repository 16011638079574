import { useAuth0 } from '@auth0/auth0-react';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { toast } from 'react-toastify';

import { getAuthToken } from '../../../../auth/authHelpers';
import {
    COLORS,
    SIZES,
} from '../../../../constants/theme';
import customerDataRequest from '../../../../services/api/customer-data';
import {
    loadContractsCallback,
    loadOwnersCallback,
} from '../../../../utils/organization';

import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import Loader from '../../../shared/Loader';

import OwnerHistoryView from '../OwnerHistoryView';
import OwnerView from '../OwnerView';

import styles from './ListingInformationDrawer.scss';

const ListingInformationDrawer = ({
    isOpen,
    setIsOpen,
    isAdmin,
    organization,
    publicationsData,
    isCountLoading,
    count,
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [isViewingOwners, setIsViewingOwners] = useState(false);
    const [isViewingOwnerHistory, setIsViewingOwnerHistory] = useState(false);
    const [isAddingOwner, setIsAddingOwner] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [owners, setOwners] = useState([]);
    const [lpiS3, setLpiS3] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPathLoading, setIsPathS3Loading] = useState(false);

    const {
        salesforce_id: salesforceId,
        is_publication_customer: isPublicationCustomer,
        show_another_accounts_organization: otherAccounts,
    } = organization;

    const orgUploadsName = process.env.ORG_UPLOADS_DIRECTORY_NAME;
    const bucketName = process.env.CUSTOMER_DATA_S3_BUCKET_NAME;
    const userLpiS3 = `${bucketName}/${orgUploadsName}/${salesforceId}/`;

    const hasUserPublications = (publications) => publications?.some((publication) => publication.type?.toUpperCase() === 'USER') || false;

    useEffect(() => {
        if (!isOpen) {
            setIsViewingOwners(false);
        }
    }, [isOpen]);

    const loadData = useCallback(async () => {
        if (isAdmin && salesforceId) {
            await loadContractsCallback(salesforceId, getAccessTokenSilently, setIsLoading, setContracts, true);

            await loadOwnersCallback(salesforceId, getAccessTokenSilently, setIsLoading, setOwners);

            const token = await getAuthToken(getAccessTokenSilently);
            if (isPublicationCustomer) {
                await customerDataRequest('get', `/organizations/${salesforceId}/publicationsFolderPath`, token).then((r) => {
                    setIsPathS3Loading(false);
                    setLpiS3(r.data.data.LPiUploadedS3Path);
                }).catch(() => {
                    setIsPathS3Loading(false);
                    toast.error(`There was an error loading the organization's
            LPi-uploaded publications folder path. Please try again in a few minutes.`);
                });
            }
        }
    }, [getAccessTokenSilently, salesforceId, isAdmin, isPublicationCustomer]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleOwnerAdded = async () => {
        await loadOwnersCallback(salesforceId, getAccessTokenSilently, setIsLoading, setOwners);

        setIsAddingOwner(false);
    };

    const handleOwnerAddedCancelled = () => {
        setIsAddingOwner(false);
    };

    const handleOwnerRemoved = (removedOwner) => {
        setOwners((prevOwners) => prevOwners.map((owner) => {
            if (owner.id === removedOwner.id) {
                return removedOwner;
            }
            return owner;
        }));
    };

    return (
        <Drawer
            open={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <div className={classNames(styles.adminContainer)}>
                {!isViewingOwners
                    && (
                        <h2>
                            Listing Information
                        </h2>
                    )}
                {isViewingOwners && !isViewingOwnerHistory
                    && (
                        <>
                            <div>
                                <Button
                                    size={SIZES.small}
                                    color={COLORS.flat}
                                    fullWidth={false}
                                    leftMargin={false}
                                    onClick={() => setIsViewingOwners(false)}
                                    disabled={!isOpen}
                                >
                                    <FontAwesomeIcon
                                        icon={faChevronLeft}
                                        className={styles.icon}
                                        style={{ marginRight: '0.75rem' }}
                                    />
                                    Back to Listing Information
                                </Button>
                            </div>
                            <h2 className={styles.title}>
                                Owners
                            </h2>
                            <h3 className={styles.subtitle}>
                                {organization.billing_title}
                            </h3>
                        </>
                    )}
                {isViewingOwners && isViewingOwnerHistory
                    && (
                        <>
                            <div>
                                <Button
                                    size={SIZES.small}
                                    color={COLORS.flat}
                                    leftMargin={false}
                                    fullWidth={false}
                                    onClick={() => setIsViewingOwnerHistory(false)}
                                    disabled={!isOpen}
                                >
                                    <FontAwesomeIcon
                                        icon={faChevronLeft}
                                        className={styles.icon}
                                        style={{ marginRight: '0.75rem' }}
                                    />
                                    Back to View Owners
                                </Button>
                            </div>
                            <h2 className={styles.title}>
                                Owner History
                            </h2>
                            <h3 className={styles.subtitle}>
                                {organization.billing_title}
                            </h3>
                        </>
                    )}
                {(isLoading || isCountLoading || isPathLoading)
                    && <Loader color={COLORS.secondary} isDrawerLoader />}
                {(!isLoading && !isCountLoading && !isPathLoading)
                    && (
                        <Stack
                            spacing={2}
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            {!isViewingOwners && (
                                <>
                                    <div>
                                        <div className={styles.infoTitle}>
                                            Contract Number(s)
                                        </div>
                                        {contracts.map((c) => (
                                            <div
                                                className={styles.infoText}
                                                key={c.contract_id}
                                            >
                                                {c.contract_id}
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        <div className={styles.infoTitle}>
                                            Map Coordinates
                                        </div>
                                        <div className={styles.infoText}>
                                            {organization.latitude}
                                            {', '}
                                            {organization.longitude}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.infoTitle}>
                                            Billing Title
                                        </div>
                                        <div className={styles.infoText}>
                                            {organization.billing_title}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.infoTitle}>
                                            Salesforce ID
                                        </div>
                                        <div className={styles.infoText}>
                                            {salesforceId}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.infoTitle}>
                                            LPI Uploaded Publications on S3
                                        </div>
                                        <div className={styles.infoText}>
                                            {lpiS3}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.infoTitle}>
                                            LPI User Uploaded Publications on S3
                                        </div>
                                        <div className={styles.infoText}>
                                            {hasUserPublications(publicationsData) && userLpiS3}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.infoTitle}>
                                            Subscriber Count
                                        </div>
                                        <div className={styles.infoText}>
                                            {count}
                                        </div>
                                    </div>
                                    {otherAccounts && (
                                        <div>
                                            <div className={styles.infoTitle}>
                                                Publications displayed from other accounts
                                            </div>
                                            <div className={styles.infoText}>
                                                {
                                                    otherAccounts
                                                }
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {isViewingOwners && !isViewingOwnerHistory
                                && (
                                    <OwnerView
                                        owners={owners}
                                        salesforceId={salesforceId}
                                        isAddingOwner={isAddingOwner}
                                        onOwnerAdded={handleOwnerAdded}
                                        onOwnerAddedCancelled={handleOwnerAddedCancelled}
                                        onOwnerRemoved={handleOwnerRemoved}
                                        isHidden={!isOpen}
                                    />
                                )}
                            {isViewingOwners && isViewingOwnerHistory
                                && (
                                    <OwnerHistoryView
                                        owners={owners}
                                        isHidden={!isOpen}
                                    />
                                )}
                            <Stack
                                name="command-list"
                                spacing={2}
                                direction="row"
                                sx={{
                                    marginTop: 'auto !important',
                                }}
                            >
                                {!isViewingOwners && (
                                    <div>
                                        <Button
                                            size={SIZES.large}
                                            onClick={() => setIsViewingOwners(true)}
                                            disabled={!isOpen}
                                        >
                                            View Owners
                                        </Button>
                                        <Button
                                            color={COLORS.flat}
                                            onClick={() => setIsOpen(false)}
                                            disabled={!isOpen}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                )}
                                {isViewingOwners && !isViewingOwnerHistory
                                    && (
                                        <>
                                            <Button
                                                size={SIZES.large}
                                                color={COLORS.flat}
                                                onClick={() => setIsViewingOwnerHistory(true)}
                                                disabled={!isOpen}
                                            >
                                                View Owner History
                                            </Button>
                                            <Button
                                                size={SIZES.large}
                                                onClick={() => setIsAddingOwner(true)}
                                                disabled={!isOpen}
                                            >
                                                Add Owner
                                            </Button>
                                        </>
                                    )}
                            </Stack>
                        </Stack>
                    )}
            </div>
        </Drawer>
    );
};

ListingInformationDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    organization: PropTypes.object,
    publicationsData: PropTypes.arrayOf(PropTypes.object),
    isCountLoading: PropTypes.bool,
    count: PropTypes.number,
};

ListingInformationDrawer.defaultProps = {
    isAdmin: false,
    organization: null,
    publicationsData: {},
    isCountLoading: false,
    count: 0,
};

export default ListingInformationDrawer;
