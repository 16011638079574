// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-FormElements-Autocomplete-Autocomplete__autoComplete--AvOko svg{width:1em !important;height:1em !important}`, "",{"version":3,"sources":["webpack://./src/components/shared/FormElements/Autocomplete/Autocomplete.scss"],"names":[],"mappings":"AAEI,uFACI,oBAAA,CACA,qBAAA","sourcesContent":["@import 'variables';\n.autoComplete {\n    svg {\n        width: 1em !important;\n        height: 1em !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#ec9300`,
	"secondary": `#00a5aa`,
	"secondaryDark": `#264550`,
	"backgroundBlue": `#eaf6f7`,
	"autoComplete": `src-components-shared-FormElements-Autocomplete-Autocomplete__autoComplete--AvOko`
};
export default ___CSS_LOADER_EXPORT___;
