import React, { useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import 'yup-phone-lite';
import TextField from '../../FormElements/TextField';
import FormField from '../../FormElements/FormField';
import Select from '../../FormElements/Select';
import styles from './ContactForm.scss';
import {
    COLORS,
    SIZES,
    POL_THEME,
    ThemeContext,
} from '../../../../constants/theme';
import Button from '../../Button';
import ContactType from '../../../../types/ContactType';
import Option from '../../Option';
import { STATE_LIST } from '../../../../constants/stateList';
import { LISTING_TYPE_ORGANIZATION, LISTING_TYPE_SPONSOR } from '../../../../constants/site';
import Loader from '../../Loader';

const FORM_FIELD_NAMES = {
    contactName: 'contactName',
    contactRole: 'contactRole',
    contactEmail: 'contactEmail',
    contactPhone: 'contactPhone',
    listingAddress1: 'listingAddress1',
    listingAddress2: 'listingAddress2',
    listingAddress3: 'listingAddress3',
    listingCity: 'listingCity',
    listingState: 'listingState',
    listingZip: 'listingZip',
};

const getHeadingType = (listingType, themeContext) => {
    if (themeContext === POL_THEME && listingType === LISTING_TYPE_ORGANIZATION) {
        return 'Parish';
    }

    if (themeContext !== POL_THEME && listingType === LISTING_TYPE_ORGANIZATION) {
        return 'Community';
    }

    if (listingType === LISTING_TYPE_SPONSOR) {
        return 'Supporter';
    }

    return 'Listing';
};

const ContactForm = ({
    uniqueId,
    contactInfo,
    updateContactInfo,
    listingType,
    onCancel,
    isHidden,
}) => {
    const themeContext = useContext(ThemeContext);
    const headingType = getHeadingType(listingType, themeContext);
    const [isSaving, setIsSaving] = useState(false);

    const initialValues = {
        [FORM_FIELD_NAMES.contactName]: contactInfo.contactName || '',
        [FORM_FIELD_NAMES.contactRole]: contactInfo.contactRole || '',
        [FORM_FIELD_NAMES.contactEmail]: contactInfo.contactEmail || '',
        [FORM_FIELD_NAMES.contactPhone]: contactInfo.contactPhone || '',
        [FORM_FIELD_NAMES.listingAddress1]: contactInfo.listingAddress1 || '',
        [FORM_FIELD_NAMES.listingAddress2]: contactInfo.listingAddress2 || '',
        [FORM_FIELD_NAMES.listingAddress3]: contactInfo.listingAddress3 || '',
        [FORM_FIELD_NAMES.listingCity]: contactInfo.listingCity || '',
        [FORM_FIELD_NAMES.listingState]: contactInfo.listingState || '',
        [FORM_FIELD_NAMES.listingZip]: contactInfo.listingZip || '',
    };

    const contactSchema = Yup.object().shape({
        [FORM_FIELD_NAMES.contactName]: Yup.string()
            .max(128, 'Contact name cannot be longer than 128 characters'),
        [FORM_FIELD_NAMES.contactRole]: Yup.string()
            .max(128, 'Contact role cannot be longer than 128 characters'),
        [FORM_FIELD_NAMES.contactEmail]: Yup.string()
            .email('Must be a valid email address')
            .max(128, 'Email cannot be longer than 128 characters'),
        [FORM_FIELD_NAMES.contactPhone]: Yup.string()
            .phone(['US', 'CA'], 'Must be a valid phone number'),
        [FORM_FIELD_NAMES.listingAddress1]: Yup.string()
            .max(128, 'Address 1 cannot be longer than 128 characters'),
        [FORM_FIELD_NAMES.listingAddress2]: Yup.string()
            .max(128, 'Address 2 cannot be longer than 128 characters'),
        [FORM_FIELD_NAMES.listingAddress3]: Yup.string()
            .max(128, 'Address 3 cannot be longer than 128 characters'),
        [FORM_FIELD_NAMES.listingCity]: Yup.string()
            .max(128, 'City cannot be longer than 128 characters'),
        [FORM_FIELD_NAMES.listingState]: Yup.string(),
        [FORM_FIELD_NAMES.listingZip]: Yup.string()
            .matches(/^\d{5}([-]?\d{4})?$/, 'Must be a valid postal code'),
    });

    const onSubmit = async (values) => {
        setIsSaving(true);
        await updateContactInfo({
            contactName: values[FORM_FIELD_NAMES.contactName],
            contactRole: values[FORM_FIELD_NAMES.contactRole],
            contactEmail: values[FORM_FIELD_NAMES.contactEmail],
            contactPhone: values[FORM_FIELD_NAMES.contactPhone],
            listingAddress1: values[FORM_FIELD_NAMES.listingAddress1],
            listingAddress2: values[FORM_FIELD_NAMES.listingAddress2],
            listingAddress3: values[FORM_FIELD_NAMES.listingAddress3],
            listingCity: values[FORM_FIELD_NAMES.listingCity],
            listingState: values[FORM_FIELD_NAMES.listingState],
            listingZip: values[FORM_FIELD_NAMES.listingZip],
        });
        setIsSaving(false);
    };

    return (
        <div
            className={classNames(styles.container)}
        >
            {isSaving && <Loader color={COLORS.secondary} isDrawerLoader />}
            <div
                className={classNames('sheetForm')}
            >
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={contactSchema}
                >

                    {({ errors, touched }) => (
                        <Form>
                            <section
                                className={classNames(styles.section)}
                            >
                                <h4>
                                    {`${headingType} Contact`}
                                </h4>
                                <FormField
                                    label="Contact Name"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.contactName}
                                    errors={errors[FORM_FIELD_NAMES.contactName]}
                                    touched={touched[FORM_FIELD_NAMES.contactName]}
                                    id={`${uniqueId}-name`}
                                    required
                                    mediumWidth
                                    disabled={isHidden}
                                />
                                <FormField
                                    label="Contact Role"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.contactRole}
                                    errors={errors[FORM_FIELD_NAMES.contactRole]}
                                    touched={touched[FORM_FIELD_NAMES.contactRole]}
                                    id={`${uniqueId}-role`}
                                    required
                                    shortWidth
                                    disabled={isHidden}
                                />
                                <div>
                                    {themeContext === POL_THEME ? (
                                        <FormField
                                            label="Contact email"
                                            tooltip="This e-mail is not public. It allows the public
                                                to contact you through Parishes Online."
                                            component={TextField}
                                            name={FORM_FIELD_NAMES.contactEmail}
                                            errors={errors[FORM_FIELD_NAMES.contactEmail]}
                                            touched={touched[FORM_FIELD_NAMES.contactEmail]}
                                            id={`${uniqueId}-email`}
                                            required
                                            mediumWidth
                                            disabled={isHidden}
                                        />
                                    ) : (
                                        <FormField
                                            label="Contact email"
                                            tooltip="This e-mail is not public. It allows the public
                                                to contact you through My Community Online."
                                            component={TextField}
                                            name={FORM_FIELD_NAMES.contactEmail}
                                            errors={errors[FORM_FIELD_NAMES.contactEmail]}
                                            touched={touched[FORM_FIELD_NAMES.contactEmail]}
                                            id={`${uniqueId}-email`}
                                            required
                                            mediumWidth
                                            disabled={isHidden}
                                        />
                                    )}
                                </div>
                                <FormField
                                    label="Phone Number"
                                    tooltip="Please use digits, (, ), +, -, and/or x only"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.contactPhone}
                                    errors={errors[FORM_FIELD_NAMES.contactPhone]}
                                    touched={touched[FORM_FIELD_NAMES.contactPhone]}
                                    id={`${uniqueId}-phone`}
                                    required
                                    shortWidth
                                    disabled={isHidden}
                                />
                            </section>
                            <section
                                className={classNames(styles.section)}
                            >
                                <h4>
                                    {`${headingType} Info`}
                                </h4>
                                <FormField
                                    label="Address 1"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.listingAddress1}
                                    errors={errors[FORM_FIELD_NAMES.listingAddress1]}
                                    touched={touched[FORM_FIELD_NAMES.listingAddress1]}
                                    id={`${uniqueId}-address1`}
                                    required
                                    mediumWidth
                                    disabled={isHidden}
                                />
                                <FormField
                                    label="Address 2"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.listingAddress2}
                                    errors={errors[FORM_FIELD_NAMES.listingAddress2]}
                                    touched={touched[FORM_FIELD_NAMES.listingAddress2]}
                                    id={`${uniqueId}-address2`}
                                    required={false}
                                    mediumWidth
                                    disabled={isHidden}
                                />
                                <FormField
                                    label="Address 3"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.listingAddress3}
                                    errors={errors[FORM_FIELD_NAMES.listingAddress3]}
                                    touched={touched[FORM_FIELD_NAMES.listingAddress3]}
                                    id={`${uniqueId}-address3`}
                                    required={false}
                                    mediumWidth
                                    disabled={isHidden}
                                />
                                <FormField
                                    label="City"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.listingCity}
                                    errors={errors[FORM_FIELD_NAMES.listingCity]}
                                    touched={touched[FORM_FIELD_NAMES.listingCity]}
                                    id={`${uniqueId}-city`}
                                    required
                                    mediumWidth
                                    disabled={isHidden}
                                />
                                <FormField
                                    label="State"
                                    component={Select}
                                    name={FORM_FIELD_NAMES.listingState}
                                    errors={errors[FORM_FIELD_NAMES.listingState]}
                                    touched={touched[FORM_FIELD_NAMES.listingState]}
                                    id={`${uniqueId}-state`}
                                    required
                                    disabled={isHidden}
                                >
                                    {Object.values(STATE_LIST).map((currentState) => (
                                        <Option key={currentState.value} value={currentState.value}>
                                            {currentState.label}
                                        </Option>
                                    ))}
                                </FormField>
                                <FormField
                                    label="Postal Code"
                                    component={TextField}
                                    name={FORM_FIELD_NAMES.listingZip}
                                    errors={errors[FORM_FIELD_NAMES.listingZip]}
                                    touched={touched[FORM_FIELD_NAMES.listingZip]}
                                    id={`${uniqueId}-zip`}
                                    required
                                    shortWidth
                                    disabled={isHidden}
                                />
                            </section>
                            <Button
                                size={SIZES.small}
                                type="submit"
                                disabled={isHidden}
                            >
                                Save
                            </Button>
                            <Button
                                color={COLORS.flat}
                                size={SIZES.small}
                                onClick={onCancel}
                                disabled={isHidden}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

ContactForm.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    contactInfo: ContactType.isRequired,
    updateContactInfo: PropTypes.func.isRequired,
    listingType: PropTypes.oneOf([LISTING_TYPE_ORGANIZATION, LISTING_TYPE_SPONSOR]).isRequired,
    onCancel: PropTypes.func.isRequired,
    isHidden: PropTypes.bool,
};

ContactForm.defaultProps = {
    isHidden: false,
};

export default ContactForm;
