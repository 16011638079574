import { CATEGORY_CATHOLIC } from '../components/shared/SearchForm/SearchForm';
import { POL_THEME } from '../constants/theme';

const MIN_DESKTOP_PAGE_WIDTH = 615;

const isThemeOrganization = (organization, themeContext) => {
    let POLOrganization = false;

    // Organizations that only have the 'Catholic' category are shown in POL
    if (organization.tags.length === 1 && organization.tags[0] === CATEGORY_CATHOLIC) {
        POLOrganization = true;
    }

    // If site/theme is POL and the organization is a POL organization, show it.
    if ((themeContext === POL_THEME && POLOrganization)
        // Or if the site/theme is MCO and the organization is not a POL organization, show it.
        || (themeContext !== POL_THEME && !POLOrganization)) {
        return true;
    }

    return false;
};

export {
    isThemeOrganization,
    MIN_DESKTOP_PAGE_WIDTH,
};
