import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import TextField from '../../FormElements/TextField';
import FormField from '../../FormElements/FormField';
import styles from './PublicationsSubscribe.scss';
import {
    COLORS,
    SIZES,
    POL_THEME,
    ThemeContext,
} from '../../../../constants/theme';
import Button from '../../Button';
import ContactType from '../../../../types/ContactType';
import Loader from '../../Loader';

const FORM_FIELD_NAMES = {
    firstName: 'firstName',
    lastName: 'lastName',
    contactEmail: 'contactEmail',
};

const PublicationsSubscribeForm = ({
    uniqueId,
    contactInfo,
    organizationTitle,
    onClose,
    isSaving,
    isFormSubmitted,
    onSubmit,
}) => {
    const themeContext = useContext(ThemeContext);
    const initialValues = {
        [FORM_FIELD_NAMES.firstName]: contactInfo.firstName,
        [FORM_FIELD_NAMES.lastName]: contactInfo.lastName,
        [FORM_FIELD_NAMES.contactEmail]: contactInfo.contactEmail,
    };

    const contactSchema = Yup.object().shape({
        [FORM_FIELD_NAMES.firstName]: Yup.string()
            .max(128, 'First name cannot be longer than 128 characters')
            .min(2, 'First name must be at least two characters'),
        [FORM_FIELD_NAMES.lastName]: Yup.string()
            .max(128, 'Last name cannot be longer than 128 characters')
            .min(2, 'Last name must be at least two characters'),
        [FORM_FIELD_NAMES.contactEmail]: Yup.string()
            .email('Must be a valid email address')
            .max(128, 'Email cannot be longer than 128 characters'),
    });
    const text = themeContext === POL_THEME ? 'Receive email notifications when new bulletins become available'
        : 'Receive email notifications when new publications become available';
    return (
        <div
            className={classNames(styles.container)}
        >
            {isSaving && <Loader color={COLORS.secondary} />}
            <div
                className={classNames(styles.sheetForm)}
            >
                {(isFormSubmitted)
                    ? (
                        <p>
                            Thank you for your subscription.  An email confirmation
                            for your subscription should be sent to your inbox in the
                            next 24 hours.
                        </p>
                    )
                    : (
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={contactSchema}
                        >

                            {({ errors, touched }) => (
                                <Form>
                                    <section
                                        className={classNames(styles.section)}
                                    >
                                        <p className={classNames(styles.adminInstruction)}>
                                            {text}
                                            {organizationTitle ? ' from ' : ''}
                                            {organizationTitle}
                                            .
                                        </p>
                                        <FormField
                                            label="Contact email"
                                            component={TextField}
                                            name={FORM_FIELD_NAMES.contactEmail}
                                            errors={errors[FORM_FIELD_NAMES.contactEmail]}
                                            touched={touched[FORM_FIELD_NAMES.contactEmail]}
                                            id={`${uniqueId}-email`}
                                            required
                                            fullWidth
                                        />
                                        <FormField
                                            label="First Name"
                                            component={TextField}
                                            name={FORM_FIELD_NAMES.firstName}
                                            errors={errors[FORM_FIELD_NAMES.firstName]}
                                            touched={touched[FORM_FIELD_NAMES.firstName]}
                                            id={`${uniqueId}-name`}
                                            required
                                            fullWidth
                                        />
                                        <FormField
                                            label="Last Name"
                                            component={TextField}
                                            name={FORM_FIELD_NAMES.lastName}
                                            errors={errors[FORM_FIELD_NAMES.lastName]}
                                            touched={touched[FORM_FIELD_NAMES.lastName]}
                                            id={`${uniqueId}-name`}
                                            required
                                            fullWidth
                                        />
                                        <p className={classNames(styles.adminHelpText)}>
                                            By subscribing to this publication you consent to share
                                            your name and email address with
                                            {organizationTitle ? ' ' : ' the organization'}
                                            {organizationTitle}
                                            .
                                        </p>
                                    </section>
                                    <div
                                        className={classNames(styles.btnContainer)}
                                    >
                                        <Button
                                            color={COLORS.flat}
                                            size={SIZES.small}
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size={SIZES.small}
                                            type="submit"
                                        >
                                            Subscribe
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                {(isFormSubmitted)
                    ? (
                        <div
                            className={classNames(styles.btnContainer)}
                        >
                            <Button
                                size={SIZES.small}
                                onClick={onClose}
                            >
                                Okay
                            </Button>
                        </div>
                    )
                    : ('')}
            </div>
        </div>
    );
};

PublicationsSubscribeForm.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    contactInfo: ContactType.isRequired,
    organizationTitle: PropTypes.string,
    onClose: PropTypes.func,
    isFormSubmitted: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool,
    onSubmit: PropTypes.func,
};

PublicationsSubscribeForm.defaultProps = {
    onClose: false,
    organizationTitle: null,
    isSaving: false,
    onSubmit: false,
};

export default PublicationsSubscribeForm;
