import Box from '@mui/system/Box';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    useContext,
} from 'react';

import {
    COLORS,
    POL_THEME,
    ThemeContext,
} from '../../../../constants/theme';

import MassEmailsSubscription from '../../../mass-subscription/MassEmailsSubscription';
import OrganizationsNotification from '../../../organizations-notifications/OrganizationsNotification';
import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import EventManagement from '../../../shared/Events/EventManagement';
import { Tabs } from '../../../shared/Tabs';

import styles from './DigitalCommunicationsDrawer.scss';

const DigitalCommunicationsDrawer = ({
    isOpen,
    setIsOpen,
    organization,
    organizationContractIds,
    subscriberCount,
    updateSubscriberCount,
    events,
    addEventCallback,
    removeEventCallback,
    sponsor,
}) => {
    const themeContext = useContext(ThemeContext);

    return (
        <Drawer
            classes={{ root: styles.container }}
            open={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <Tabs isHidden={!isOpen}>
                {!!organization && (
                    <div
                        data-title="Email Subscriptions"
                        data-key="massEmails-subscription"
                    >
                        <div className={classNames('sheetForm')}>
                            <MassEmailsSubscription
                                salesforceId={organization.salesforce_id}
                                organization={organization.physical_title}
                                subscriberCount={subscriberCount}
                                updateSubscriberCount={updateSubscriberCount}
                                isHidden={!isOpen}
                            />
                        </div>
                    </div>
                )}
                {!!organization && themeContext === POL_THEME
                    && (
                        <div
                            key="organizations-notification"
                            data-title="Organization Notifications"
                            data-key="organizations-notification"
                        >
                            <div
                                className={classNames('sheetForm')}
                            >
                                <OrganizationsNotification
                                    salesforceId={organization.salesforce_id}
                                    pageTitle={organization.physical_title}
                                    organizationContractId={organizationContractIds[0]}
                                    isHidden={!isOpen}
                                />
                            </div>
                        </div>
                    )}
                <div data-title="Events" data-key="events">
                    <div className={classNames('sheetForm')}>
                        <EventManagement
                            events={events}
                            addEventCallback={addEventCallback}
                            removeEventCallback={removeEventCallback}
                            isSponsor={!!sponsor}
                            isHidden={!isOpen}
                        />
                    </div>
                </div>
            </Tabs>
            <Box mt={2}>
                <Button
                    color={COLORS.flat}
                    onClick={() => setIsOpen(false)}
                    disabled={!isOpen}
                >
                    Close
                </Button>
            </Box>
        </Drawer>
    );
};

DigitalCommunicationsDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    organization: PropTypes.object,
    organizationContractIds: PropTypes.arrayOf(PropTypes.string),
    subscriberCount: PropTypes.number,
    updateSubscriberCount: PropTypes.func,
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
    addEventCallback: PropTypes.func.isRequired,
    removeEventCallback: PropTypes.func.isRequired,
    sponsor: PropTypes.object,
};

DigitalCommunicationsDrawer.defaultProps = {
    organization: null,
    organizationContractIds: [],
    subscriberCount: 0,
    updateSubscriberCount: () => {},
    sponsor: null,
};

export default DigitalCommunicationsDrawer;
