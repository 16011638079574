export const HOME = '/home';
export const SEARCH = '/search';
export const SUPPORTERS = '/supporters';
export const FIND = '/find/:slug';
export const FIND_SUPPORTERS_PAGE = '/find/:slug/supporters';
export const FIND_BULLETIN_PAGE = '/find/:slug/bulletin/file/:filename';
export const ORGANIZATION_DETAIL = '/organization/:slug';
export const ORGANIZATION_DETAIL_VARIANT = '/organization/:slug/:variant';
export const ORGANIZATION_SUPPORTERS_PAGE = '/organization/:slug/supporters';
export const ORGANIZATION_EMAIL_CONTENT_PAGE = '/organization/:slug/email-content';
export const ORGANIZATION_BULLETIN_PAGE = '/organization/:slug/bulletin/file/:filename';
export const SPONSOR_DETAIL = '/supporter/:slug';
export const SETTINGS = '/settings';
export const ADVERTISE = '/advertise';
export const ABOUT = '/about';
export const HELP = '/help';
export const ERROR_500 = '/error500';
export const ERROR_404 = '/error404';
export const TERMSCONDITIONS = '/terms-conditions';
export const FLIPBOOK = '/flipbook';
export const PUBLICATION_WIDGET = '/publicationWidget';
export const ADVERTISEMENT_FORM = '/advertisement-form';

// Redirects to a separate publication page
export const PUBLICATION_PAGE = '/publication-page/:slug';

export const AUTH_REDIRECT = `${window.location.origin}${HOME}`;
