/* eslint no-restricted-imports: ["error", "fs"] */
import { useAuth0 } from '@auth0/auth0-react';
import { subject } from '@casl/ability';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import {
    faPen,
    faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useState,
} from 'react';
import { toast } from 'react-toastify';

import { getAuthToken } from '../../auth/authHelpers';
import { Can } from '../../auth/Can';
import {
    COLORS,
    SIZES,
} from '../../constants/theme';
import {
    customerDataMicroserviceRequest,
    customerDataReqCustom,
} from '../../services/api/customer-data';
import {
    getDisplayDate,
    sortPublicationByPublishDate,
} from '../../utils/date';
import { capitalizeFirstLetter } from '../../utils/string';

import Button from '../shared/Button';
import Loader from '../shared/Loader';
import DeleteModalDialog from '../shared/ModalDialog/Delete/DeleteModalDialog';

import {
    PublicationAddEdit,
    ADD,
    EDIT,
} from './publications-addEdit';

const USER = 'USER';
export const PUBLICATION_DISPLAY_LIMIT = 12;

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy));

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const headCells = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'publishDate',
        label: 'Publication Date',
    },
    {
        id: 'displayDate',
        label: 'User Display Date',
    },
    {
        id: 'type',
        label: 'Type',
    },
];

const EnhancedTableHead = ({
    order,
    orderBy,
    onRequestSort,
    userDisplayDateColumn,
    isHidden,
}) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow
                sx={{
                    height: '45px',
                }}
            >
                <TableCell />
                {headCells.map((headCell) => (userDisplayDateColumn || headCell.id !== 'displayDate')
                    && (
                        <TableCell
                            key={headCell.id}
                            align="left"
                            padding="normal"
                            sortDirection={false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                disabled={isHidden}
                                sx={{
                                    '&:focus-visible .MuiTableSortLabel-icon': {
                                        opacity: 0.5,
                                    },
                                }}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                <TableCell />
                <TableCell />
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    userDisplayDateColumn: PropTypes.bool.isRequired,
    isHidden: PropTypes.bool,
};

EnhancedTableHead.defaultProps = {
    isHidden: false,
};

const stylesProps = {
    root: {
        '& .MuiTableCell-head': {
            color: 'black',
            backgroundColor: '#F5F5F5',
            fontSize: '16px',
        },
        '& .MuiTableCell-root': {
            padding: '4px 0px 0px 2px',
            fontSize: '13.5px',
        },
    },
};

const PublicationsView = ({
    displayPublications,
    setManagePublicationsTitle,
    setIsDrawerOpen,
    salesforceId,
    loadPublications,
    isAddEditView,
    setIsAddEditView,
    isHidden,
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [publicationsData, setPublicationsData] = useState([]);
    const [showDisplayDateColumn, setShowUDisplayDateColumn] = useState(publicationsData.some((p) => !!p.displayDate));
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('publishDate');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedPublication, setSelectedPublication] = useState(null);
    const [actionType, setActionType] = useState(null);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setPublicationsData(sortPublicationByPublishDate(displayPublications, PUBLICATION_DISPLAY_LIMIT));
    }, [displayPublications]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleOpenDeleteModal = (publication) => {
        setSelectedPublication(publication);
        setIsDeleteModalOpen(true);
    };

    const handleCancel = (e) => {
        e.stopPropagation();
        setIsDeleteModalOpen(false);
        setSelectedPublication(null);
    };

    const deletePublication = async (publication) => {
        try {
            setShowLoader(true);
            setIsDeleteModalOpen(false);
            const {
                publicationId,
                type,
                name,
            } = publication;
            const trimmedFileName = name.replace(/\.[^/.]+$/, '');
            const token = await getAuthToken(getAccessTokenSilently);

            if (type && type.toUpperCase() === USER) {
                const apiUrl = `organizations/${salesforceId}/user-publication/${publicationId}`;

                await customerDataMicroserviceRequest('DELETE', process.env.USER_PUBLICATION_API_URL, apiUrl, token);
            } else {
                const url = `organizations/${salesforceId}/publications/?fileName=${trimmedFileName}`;

                await customerDataReqCustom('delete', url, token);
            }

            const filteredPublications = publicationsData.filter((p) => p?.publicationId !== publicationId);
            setPublicationsData(filteredPublications);
            setShowUDisplayDateColumn(filteredPublications.some((p) => !!p.displayDate));
            setShowLoader(false);
            setIsDrawerOpen(false);
            loadPublications();
            toast.success('Publication Deleted');
        } catch (err) {
            setShowLoader(false);
            toast.error('There was an error deleting publication');
        }
    };

    const handleDelete = async () => {
        await deletePublication(selectedPublication);
        setIsDeleteModalOpen(false);
    };

    const handleAddAction = () => {
        setManagePublicationsTitle('Add Publication');
        setActionType(ADD);
        setSelectedPublication(null);
        setIsAddEditView(true);
    };

    const handleEditAction = (publication) => {
        setManagePublicationsTitle('Edit Publication');
        setSelectedPublication(publication);
        setActionType(EDIT);
        setIsAddEditView(true);
    };

    const handleCancelAddAction = () => {
        setIsDrawerOpen(false);
        setSelectedPublication(null);
    };

    const isUserPublications = (type) => (!!type && type.toUpperCase() === USER);

    const deleteButton = (row) => (
        <>
            {' '}
            <Button
                color={COLORS.flat}
                size={SIZES.small}
                onClick={() => handleOpenDeleteModal(row)}
                disabled={isHidden}
            >
                <FontAwesomeIcon icon={faTrash} />
                {' '}
                <span>Delete</span>
            </Button>
        </>
    );

    const trimTimeStampName = (name, isUserType) => {
        if (isUserType) {
            const arr = name?.split('-');
            arr.shift();
            return arr.join('-');
        }
        return name;
    };

    return (
        <Box>
            {!isAddEditView && (
                <Paper
                    sx={{
                        float: 'right',
                        mb: 1,
                    }}
                >
                    <Button
                        size={SIZES.small}
                        type="add"
                        onClick={() => handleAddAction()}
                        disabled={isHidden}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        {' '}
                        Add Publications
                    </Button>
                    <Button
                        color={COLORS.flat}
                        size={SIZES.small}
                        onClick={() => handleCancelAddAction()}
                        disabled={isHidden}
                    >
                        Cancel
                    </Button>
                </Paper>
            )}
            {publicationsData.length < 1 && !isAddEditView
                && (
                    <h3>No Publications to Display</h3>
                )}
            {publicationsData.length > 0 && !isAddEditView
                && (
                    <Paper
                        sx={{
                            width: '100%',
                            mb: 2,
                            mt: 2,
                        }}
                    >
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size="medium"
                                sx={stylesProps.root}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={publicationsData.length}
                                    userDisplayDateColumn={showDisplayDateColumn}
                                    isHidden={isHidden}
                                />
                                <TableBody>
                                    {stableSort(publicationsData, getComparator(order, orderBy))
                                        .map((row, index) => (
                                            <TableRow
                                                key={row.publicationId ? row.publicationId : row.name}
                                            >
                                                <TableCell
                                                    padding="checkbox"
                                                    sx={{
                                                        fontSize: '40px',
                                                    }}
                                                >
                                                    <Avatar
                                                        alt={row.name}
                                                        variant="square"
                                                        src={row.smallThumbnailUrl}
                                                        sx={{
                                                            width: 56,
                                                            height: 56,
                                                            paddingRight: '24px',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={`enhanced-table-checkbox-${index}`}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {trimTimeStampName(row.name, isUserPublications(row.type))}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {getDisplayDate(row.publishDate)}
                                                </TableCell>
                                                {showDisplayDateColumn && (
                                                    <TableCell align="left">
                                                        {' '}
                                                        {getDisplayDate(row.displayDate)}
                                                        {' '}
                                                    </TableCell>
                                                )}
                                                <TableCell align="left">
                                                    {capitalizeFirstLetter(row.type)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {isUserPublications(row?.type) && (
                                                        <Button
                                                            color={COLORS.flat}
                                                            size={SIZES.small}
                                                            onClick={() => handleEditAction(row)}
                                                            disabled={isHidden}
                                                        >
                                                            <FontAwesomeIcon icon={faPen} />
                                                            {' '}
                                                            <span>Edit</span>
                                                        </Button>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {isUserPublications(row?.type)
                                                        ? deleteButton(row)
                                                        : (
                                                            <Can
                                                                I="admin"
                                                                this={subject('listing', { id: salesforceId })}
                                                            >
                                                                {!isUserPublications(row?.type)
                                                                    && deleteButton(row)}
                                                            </Can>
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <DeleteModalDialog
                            title="Delete this Publication?"
                            open={isDeleteModalOpen}
                            handleCancel={handleCancel}
                            handleDelete={handleDelete}
                        />
                    </Paper>
                )}
            {isAddEditView && (
                <PublicationAddEdit
                    salesforceId={salesforceId}
                    publication={selectedPublication}
                    actionType={actionType}
                    setIsDrawerOpen={setIsDrawerOpen}
                    loadPublications={loadPublications}
                    setIsAddEditView={setIsAddEditView}
                    setManagePublicationsTitle={setManagePublicationsTitle}
                    isHidden={isHidden}
                />
            )}
            {showLoader && <Loader isDrawerLoader />}
        </Box>
    );
};

PublicationsView.propTypes = {
    setManagePublicationsTitle: PropTypes.func.isRequired,
    salesforceId: PropTypes.string.isRequired,
    setIsDrawerOpen: PropTypes.func.isRequired,
    loadPublications: PropTypes.func.isRequired,
    isAddEditView: PropTypes.bool.isRequired,
    setIsAddEditView: PropTypes.func.isRequired,
    displayPublications: PropTypes.arrayOf(PropTypes.shape({
        publicationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fileUrl: PropTypes.string,
        name: PropTypes.string,
        publishDate: PropTypes.string,
        largeThumbnailUrl: PropTypes.string,
        smallThumbnailUrl: PropTypes.string,
        largeThumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        smallThumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        type: PropTypes.string,
    })),
    isHidden: PropTypes.bool,
};

PublicationsView.defaultProps = {
    displayPublications: [],
    isHidden: false,
};

export default PublicationsView;
