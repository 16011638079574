/**
 * Holds high level configuration
 */

export const SITE_NAME_MCO = 'My Community Online';

export const SITE_NAME_POL = 'Parishes Online';

export const LISTING_TYPE_SPONSOR = 'supporter';

export const LISTING_TYPE_ORGANIZATION = 'organization';

export const DFLIP_CSS_SRC = '/dflip/css/dflip.min.css';

export const PDFJS_SCRIPT_SRC_LIST = [
    '/dflip/js/libs/pdfjs/stable/pdf.worker.min.js',
    '/dflip/js/libs/pdfjs/stable/pdf.min.js',
];

export const DFLIP_SCRIPT_SRC_LIST = [
    ...PDFJS_SCRIPT_SRC_LIST,
    '/dflip/js/libs/three.min.js',
    '/dflip/js/libs/jquery.min.js',
    '/dflip/js/dflip.min.js',
];
