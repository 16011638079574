import {
    SITE_NAME_MCO,
    SITE_NAME_POL,
} from './site';

const mcoOrganizationView = (organization) => `${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} | ${SITE_NAME_MCO}`;

const polOrganizationView = (organization) => `${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} | ${SITE_NAME_POL}`;

const mcoPublicationView = (organizationTitle, publicationDate) => `${publicationDate} publication from ${organizationTitle} | ${SITE_NAME_MCO}`;

const polPublicationView = (organizationTitle, publicationDate) => `${publicationDate} publication from ${organizationTitle} | ${SITE_NAME_POL}`;

const mcoSupportersView = (name) => `${name} Supporters | ${SITE_NAME_MCO}`;

const polSupportersView = (name) => `${name} Supporters | ${SITE_NAME_POL}`;

const mcoSubscribeView = (organization) => `Subscribe to ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} | ${SITE_NAME_MCO}`;

const polSubscribeView = (organization) => `Subscribe to ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} | ${SITE_NAME_POL}`;

const mcoUnsubscribeView = (organization) => `Unsubscribe from ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} | ${SITE_NAME_MCO}`;

const polUnsubscribeView = (organization) => `Unsubscribe from ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} | ${SITE_NAME_POL}`;

const polEmailView = (organization, date) => `Email content ${date} from ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} | ${SITE_NAME_POL}`;

export const Titles = {
    MCO_404: `Page Not Found | ${SITE_NAME_MCO}`,
    MCO_500: `Something Went Wrong | ${SITE_NAME_MCO}`,
    MCO_ABOUT: `About Us | ${SITE_NAME_MCO}`,
    MCO_ADVERTISERS: `Advertise with Us | ${SITE_NAME_MCO}`,
    MCO_AD_FORM: `Advertising Interest Form | ${SITE_NAME_MCO}`,
    MCO_HELP: `Frequently Asked Questions | ${SITE_NAME_MCO}`,
    MCO_HOME: `${SITE_NAME_MCO} | The Online Directory of Communities`,
    MCO_ORGANIZATION: mcoOrganizationView,
    MCO_PUBLICATION: mcoPublicationView,
    MCO_SEARCH: `Organizations Search | ${SITE_NAME_MCO}`,
    MCO_SETTINGS: `Management Console | ${SITE_NAME_MCO}`,
    MCO_SUBSCRIBE: mcoSubscribeView,
    MCO_SUPPORTER_SEARCH: `Supporters Search | ${SITE_NAME_MCO}`,
    MCO_SUPPORTERS_LIST: mcoSupportersView,
    MCO_SUPPORTERS: `Supporter Search | ${SITE_NAME_MCO} | The Online Directory of Communities`,
    MCO_TERMS: `Terms and Conditions | ${SITE_NAME_MCO}`,
    MCO_UNSUBSCRIBE: mcoUnsubscribeView,
    MCO_WIDGET: `View publications | ${SITE_NAME_MCO}`,
    POL_404: `Page Not Found | ${SITE_NAME_POL}`,
    POL_500: `Something Went Wrong | ${SITE_NAME_POL}`,
    POL_ABOUT: `About Us | ${SITE_NAME_POL}`,
    POL_ADVERTISERS: `Advertise with Us | ${SITE_NAME_POL}`,
    POL_AD_FORM: `Advertising Interest Form | ${SITE_NAME_POL}`,
    POL_EMAIL_CONTENT: polEmailView,
    POL_HELP: `Frequently Asked Questions | ${SITE_NAME_POL}`,
    POL_HOME: `${SITE_NAME_POL} | Live Your Faith, Wherever Life Takes You`,
    POL_ORGANIZATION: polOrganizationView,
    POL_PUBLICATION: polPublicationView,
    POL_SEARCH: `Parishes Search | ${SITE_NAME_POL}`,
    POL_SETTINGS: `Management Console | ${SITE_NAME_POL}`,
    POL_SUBSCRIBE: polSubscribeView,
    POL_SUPPORTER_SEARCH: `Supporters Search | ${SITE_NAME_POL}`,
    POL_SUPPORTERS_LIST: polSupportersView,
    POL_SUPPORTERS: `Supporter Search | ${SITE_NAME_POL} | Live Your Faith, Wherever Life Takes You`,
    POL_TERMS: `Terms and Conditions | ${SITE_NAME_POL}`,
    POL_UNSUBSCRIBE: polUnsubscribeView,
    POL_WIDGET: `View publications | ${SITE_NAME_POL}`,
};
