import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowsMaximize,
} from '@fortawesome/pro-regular-svg-icons';
import Link from '@mui/material/Link';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import buttonStyles from '../../../../styles/PDFButtons.scss';

import Button from '../../Button/index';

import styles from './PDFViewer.scss';

const PDFViewer = ({
    fullscreen,
    filePath,
    onFullscreenButtonClick, // This is both the fullscreen close & open function.
}) => (
    <>
        <div
            className={classNames(styles.buttonContainer)}
        >
            <Button
                // onClick={() => onFullscreenButtonClick(true)}
                classes={{ root: classNames(buttonStyles.button, buttonStyles.fullscreenButton) }}
            >
                <Link
                    sx={{
                        color: '#FFFFFF',
                    }}
                    href={filePath}
                    target="_blank"
                >
                    <FontAwesomeIcon icon={faArrowsMaximize} />
                    {' '}
                    View Fullscreen
                </Link>
            </Button>

        </div>
        <iframe
            id="pdf-js-viewer"
            src={`/build/minified/web/viewer.html?file=${filePath}`}
            title="webviewer"
            frameBorder="0"
            style={{ minWidth: '1em' }}
            className={
                fullscreen ? classNames(styles.fullScreenPDFViewerStyles) : classNames(styles.pdfViewerIframe)
            }
        />

        {(fullscreen && (
            <Button
                content="Close"
                classes={{ root: classNames(buttonStyles.fullscreenCloseButton) }}
                onClick={() => onFullscreenButtonClick(false)}
            >
                Close
            </Button>
        ))
            || ''}
    </>
);

PDFViewer.defaultProps = {
    fullscreen: false,
};

PDFViewer.propTypes = {
    filePath: PropTypes.string.isRequired,
    fullscreen: PropTypes.bool,
    onFullscreenButtonClick: PropTypes.func.isRequired,
};

export default PDFViewer;
