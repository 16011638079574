const FONT_SIZES = {
    small: '1.3rem',
    medium: '1.36rem', // This size matches the 'homebrewed' TextFields we had prior to the MUI TextFields.
};

const getFontSize = (fontSize) => {
    if (FONT_SIZES[fontSize]) {
        return FONT_SIZES[fontSize];
    }

    return FONT_SIZES.medium;
};

const getFontCSS = (fontSizeCSSValue) => ({
    color: 'black',
    fontSize: fontSizeCSSValue,
    fontFamily: '"Open Sans", tahoma, verdana, sans-serif',
});

export {
    FONT_SIZES,
    getFontSize,
    getFontCSS,
};
