export const genCanonicalLink = (domain, pathname, query) => {
    const canonicalPatterns = [
        { pattern: /^\/find\/([^/]+)\/bulletin\/file\/([^/]+)$/, replace: `/publication-page/$1?selectedPublication=https://container.${domain}.com/bulletins/$2` },
        { pattern: /^\/organization\/([^/]+)\/bulletin\/file\/([^/]+)$/, replace: `/publication-page/$1?selectedPublication=https://container.${domain}.com/bulletins/$2` },
    ];
    const matchingPattern = canonicalPatterns.find(({ pattern }) => pattern.test(pathname));
    const fullDomain = `https://${domain}.com`;

    if (matchingPattern) {
        const { pattern, replace } = matchingPattern;
        const replacedPathname = pathname.replace(pattern, replace);
        const pathParts = replacedPathname.split('/bulletins/');

        return `${fullDomain}${pathParts[0]}/bulletins/${pathParts[1].replace(/-/g, '/')}`;
    }

    if (pathname.includes('publication-page') || pathname.includes('email-content')) {
        return `${fullDomain}${pathname}${query}`;
    }

    return `${fullDomain}${pathname}`;
};
