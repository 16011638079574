import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const FormField = ({ component: FieldComponent, ...props }) => {
    const [field, meta, helpers] = useField(props);

    return (
        <FieldComponent
            {...field}
            {...meta}
            {...helpers}
            {...props}
        />
    );
};

FormField.propTypes = {
    component: PropTypes.elementType.isRequired,
};

FormField.defaultProps = {};

export default FormField;
