import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug } from '@fortawesome/pro-regular-svg-icons';
import { faOutlet } from '@fortawesome/pro-light-svg-icons';
import {
    POL_THEME,
    MCO_THEME,
    ThemeContext,
} from '../../constants/theme';
import styles from './404-error.scss';
import * as routes from '../../constants/routes';
import { Titles } from '../../constants/titles';
import { AppContext } from '../../context/AppContext';
import { Descriptions } from '../../constants/Descriptions';

const Error404 = () => {
    const themeContext = useContext(ThemeContext);
    const { setPageTitle, setPageDescription } = useContext(AppContext);

    useEffect(() => {
        switch (themeContext) {
            case MCO_THEME:
                setPageTitle(Titles.MCO_404);
                setPageDescription(Descriptions.MCO_404);
                break;
            case POL_THEME:
                setPageTitle(Titles.POL_404);
                setPageDescription(Descriptions.POL_404);
                break;
            default:
                break;
        }
    }, [themeContext, setPageTitle, setPageDescription]);

    return (
        <div className={styles.outerContainer}>
            <main className={styles.container}>
                <div className={styles.iconContainer}>
                    <FontAwesomeIcon
                        icon={faPlug}
                        className={styles.plugIcon}
                    />
                    <FontAwesomeIcon icon={faOutlet} />
                </div>
                <div className={styles.textBlock}>
                    <h1 className={styles.errorHeadline}>404</h1>
                    <h2>Oh no, this page doesn&apos;t exist!</h2>
                    <p>
                        Please head
                        &nbsp;
                        <Link to={routes.HOME}>back to the home</Link>
                        &nbsp;
                        page.
                    </p>
                </div>
            </main>
        </div>
    );
};

export default Error404;
