import { toast } from 'react-toastify';
import customerDataRequest, {
    customerDataMicroserviceRequest,
} from './api/customer-data';

/**
 * Given a sponsor or organization, return the social data form it
 * @return SocialType
 */
export function getSocialDataFromListing(listingData) {
    return {
        websiteUrl: listingData.website || '',
        youtubeUrl: listingData.youtube_url || '',
        facebookUrl: listingData.facebook_url || '',
        instagramUrl: listingData.instagram_url || '',
        twitterUsername: listingData.twitter_url || '',
        linkedinUrl: listingData.linkedin_url || '',
        liveStreaming: !!listingData.live_streaming,
        donationUrl: listingData.donation_url || '',
    };
}

/**
 * Given a sponsor or organization, return the contact data for it
 * @param listingData
 * @return ContactType
 */
export function getContactDataFromListing(listingData) {
    return {
        contactName: listingData.physical_contact_name || '',
        contactEmail: listingData.physical_contact_email || '',
        contactPhone: listingData.physical_phone || '',
        contactRole: listingData.physical_contact_role || '',
        listingAddress1: listingData.physical_street || '',
        listingAddress2: listingData.physical_street2 || '',
        listingAddress3: listingData.physical_street3 || '',
        listingCity: listingData.physical_city || '',
        listingState: listingData.physical_state || '',
        listingZip: listingData.physical_postal_code || '',
    };
}

/**
 * Given a sponsor or organization, return the billing data for it
 * @param listingData
 * @return BillingType
 */
export function getBillingDataFromListing(listingData) {
    return {
        billingAddress1: listingData.billing_street || '',
        billingAddress2: listingData.billing_street2 || '',
        billingCity: listingData.billing_city || '',
        billingState: listingData.billing_state || '',
        billingPostalCode: listingData.billing_postal_code || '',
        billingPhone: listingData.billing_phone || '',
    };
}

const CATEGORY_OTHER = 'Other';

export async function loadSicCodes(setCategories, setLoadingCategories) {
    await customerDataRequest('get', '/sponsors/sic-codes')
        .then((res) => {
            const categoryDataList = res.data.data;
            const categoryDataObject = {};
            for (let i = 0; i < categoryDataList.length; i++) {
                // 'Other's are not included since we add it to the list at the end
                // for all SIC codes that would be classified as 'Other'
                if (categoryDataList[i].name.toLowerCase() !== CATEGORY_OTHER.toLowerCase()) {
                    // formatting data to object key/value  i.e. {<sic_code>:<name>}
                    const sicKey = categoryDataList[i].sic_code;
                    const sicValue = categoryDataList[i].name;
                    categoryDataObject[sicKey] = sicValue;
                }
            }
            setCategories(categoryDataObject);
            setLoadingCategories(false);
        })
        .catch(() => {
            setLoadingCategories(false);
            toast.error(`There was an error loading search categories.
            Please try again in a few minutes.`);
        });
}

export async function loadSicCodeParentCategories(setCategories, setLoadingCategories) {
    try {
        const {
            data: sicCodeCategories,
        } = await customerDataMicroserviceRequest('get', process.env.SPONSOR_MANAGEMENT_API_URL, '/sponsors/sic-code-categories');

        const parentCategoryDataObject = {};
        sicCodeCategories.forEach((category) => {
            // formatting data to object key/value - i.e. {<id>:<name>}
            parentCategoryDataObject[category.id] = category.name;
        });

        setCategories(parentCategoryDataObject);
    } catch (error) {
        toast.error(`There was an error loading search categories.
            Please try again in a few minutes.`);
    } finally {
        setLoadingCategories(false);
    }
}
