import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './SearchTag.scss';

const SearchTag = ({
    label,
    handleDelete,
}) => (
    <Chip
        className={styles.searchTag}
        label={label}
        variant="outlined"
        onDelete={() => handleDelete()}
    />
);

SearchTag.propTypes = {
    label: PropTypes.string,
    handleDelete: PropTypes.func,
};

SearchTag.defaultProps = {
    label: '',
    handleDelete: () => {},
};

export default SearchTag;
