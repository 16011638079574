import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const AppContext = createContext({
    isOwnershipRequest: false,
    setOwnershipRequest: () => null,
    pageTitle: null,
    setPageTitle: () => null,
});

export const AppProvider = ({ children }) => {
    const [isOwnershipRequest, setOwnershipRequest] = useState(false);
    const [pageTitle, setPageTitle] = useState('Online Directory');
    const [pageDescription, setPageDescription] = useState('An online directory where communities come together to connect, share, and thrive.');

    return (
        <AppContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                isOwnershipRequest,
                setOwnershipRequest,
                pageTitle,
                setPageTitle,
                pageDescription,
                setPageDescription,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

AppProvider.propTypes = {
    children: PropTypes.element.isRequired,
};
