import { toast } from 'react-toastify';
import { getAuthToken } from '../auth/authHelpers';
import { customerDataMicroserviceRequest } from '../services/api/customer-data';

const loadSponsorOwnersCallback = async (advertiserId, getAccessTokenSilently, setIsLoading,
    setOwners) => {
    if (advertiserId) {
        setIsLoading(true);
        const token = await getAuthToken(getAccessTokenSilently);
        customerDataMicroserviceRequest('get', process.env.SPONSOR_OWNERSHIP_API_URL, `sponsors/${advertiserId}/owners`, token)
            .then((res) => {
                setIsLoading(false);
                setOwners(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                toast.error(`There was an error loading the sponsor's
                owners. Please try again in a few minutes.`);
            });
    }
};

const removeSponsorOwnerCallback = async (advertiserId, ownerId, getAccessTokenSilently, setIsLoading, handleSuccess, handleError, payload) => {
    if (advertiserId && ownerId) {
        setIsLoading(true);
        const token = await getAuthToken(getAccessTokenSilently);
        customerDataMicroserviceRequest('patch', process.env.SPONSOR_OWNERSHIP_API_URL, `sponsors/${advertiserId}/owners/${ownerId}`, token, payload)
            .then((res) => {
                setIsLoading(false);
                handleSuccess(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                handleError();
            });
    }
};

const addSponsorOwnerCallback = async (advertiserId, email, getAccessTokenSilently, setIsLoading, handleSuccess, handleError, isPOL = true) => {
    if (advertiserId && email) {
        setIsLoading(true);
        const token = await getAuthToken(getAccessTokenSilently);
        customerDataMicroserviceRequest('post', process.env.SPONSOR_OWNERSHIP_API_URL, `sponsors/${advertiserId}/owners`, token, { email, isPOL })
            .then((res) => {
                setIsLoading(false);
                handleSuccess(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                handleError();
            });
    }
};

const addEventCallback = async (sponsorId, event, getAccessTokenSilently) => {
    if (!sponsorId || !event) {
        return {
            success: false,
        };
    }

    const token = await getAuthToken(getAccessTokenSilently);

    if (event.id) {
        const result = await customerDataMicroserviceRequest('put', process.env.EVENT_MANAGEMENT_API_URL, `sponsors/${sponsorId}/events/${event.id}`, token, event);
        if (result.status === 204) {
            toast.success('Event updated');
            return {
                success: true,
                data: { ...event, event_date: `${event.event_date.split(' ').join('T')}.000Z`, deleted_at: null },
            };
        }
    } else {
        const result = await customerDataMicroserviceRequest('post', process.env.EVENT_MANAGEMENT_API_URL, `sponsors/${sponsorId}/events`, token, event);
        if (result.status === 200) {
            toast.success('Event added');
            return {
                success: true,
                data: result.data,
            };
        }
    }

    toast.error('Error while saving event');
    return {
        success: false,
    };
};

const removeEventCallback = async (sponsorId, eventId, getAccessTokenSilently) => {
    if (!sponsorId || !eventId) {
        return {
            success: false,
        };
    }

    const token = await getAuthToken(getAccessTokenSilently);
    const result = await customerDataMicroserviceRequest('delete', process.env.EVENT_MANAGEMENT_API_URL, `sponsors/${sponsorId}/events/${eventId}`, token);
    if (result.status === 204) {
        return {
            success: true,
        };
    }

    return {
        success: false,
    };
};

export {

    loadSponsorOwnersCallback,
    removeSponsorOwnerCallback,
    addSponsorOwnerCallback,
    addEventCallback,
    removeEventCallback,

};
