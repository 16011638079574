import classNames from 'classnames';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';

import {
    COLORS,
    SIZES,
} from '../../../../constants/theme';

import Button from '../../Button';
import Divider from '../../Divider/Divider';
import FormField from '../../FormElements/FormField';
import MultiSelect from '../../FormElements/MultiSelect';
import SliderRadio from '../../FormElements/SliderRadio';
import ToggleGroup from '../../FormElements/ToggleGroup';

// eslint-disable-next-line no-unused-vars
import styles from './PublicationsSettingsForm.scss'; // DO NOT REMOVE, Must be imported for styles below to work.

const FORM_FIELD_NAMES = {
    exclusions: 'exclusions',
    showPublicationToggle: 'show_publication_toggle',
    publicationOffset: 'publication_offset',
};

export const TOGGLE_IMMEDIATELY_VALUE = 'immediately';
export const TOGGLE_SCHEDULE_VALUE = 'schedule';

const PUBLICATION_TOGGLE_STATES = [
    {
        label: 'Immediately On Upload',
        value: TOGGLE_IMMEDIATELY_VALUE,
    },
    {
        label: 'Schedule',
        value: TOGGLE_SCHEDULE_VALUE,
    },
];

export const OFFSET_IMMEDIATELY = null;
export const OFFSET_ON_DISTRIBUTION_DATE_VALUE = 0;

const OFFSET_PRESETS = [
    -3,
    -2,
    -1,
    OFFSET_ON_DISTRIBUTION_DATE_VALUE,
    1,
    2,
    3,
    15,
];

const sliderValueLabelFormat = (value) => {
    if (value === 0) {
        return 'On distribution date';
    }

    const isNegativeValue = value < 0;
    const afterOrBefore = isNegativeValue ? 'before' : 'after';
    const dayOrDays = (value === 1 || value === -1) ? 'day' : 'days';

    return `${value} ${dayOrDays} ${afterOrBefore} distribution date`;
};

const BasePublicationSettingsForm = ({
    classes,
    uniqueId,
    allExclusions,
    handleSubmit,
    values,
    errors,
    touched,
    updatePublicationSettings,
    onCancel,
    isHidden,
    ...otherProps
}) => {
    const shouldShowPublicationRange = useCallback(() => (values[FORM_FIELD_NAMES.showPublicationToggle] === TOGGLE_SCHEDULE_VALUE), [values]);

    const [showPublicationRange, setShowPublicationRange] = useState(shouldShowPublicationRange());

    const handleToggleChange = (field, value) => {
        otherProps.setFieldValue(field, value);
    };

    useEffect(() => {
        setShowPublicationRange(shouldShowPublicationRange());
    }, [shouldShowPublicationRange]);

    return (
        <div
            className={classNames(classes.root, 'sheetForm')}
        >
            <form
                // This is how to bring in regular classes
                className={classNames('sheetForm')}
                onSubmit={handleSubmit}
            >
                <h3>
                    LPI Publications
                </h3>
                <Divider
                    short
                />
                <FormField
                    component={MultiSelect}
                    name={FORM_FIELD_NAMES.exclusions}
                    id={`${uniqueId}-description-text-area`}
                    options={allExclusions}
                    required={false}
                    isMulti
                    helperText="Directories are automatically excluded."
                    errors={errors}
                    touched={touched}
                    label="Publication Exclusions"
                    disabled={isHidden}
                />
                <div className={classNames('scheduleContainer')}>
                    <div className={classNames('toggleContainer')}>
                        <FormField
                            component={ToggleGroup}
                            name={FORM_FIELD_NAMES.showPublicationToggle}
                            options={PUBLICATION_TOGGLE_STATES}
                            id={`${uniqueId}-publication-toggle`}
                            onChange={handleToggleChange}
                            errors={errors}
                            touched={touched}
                            label="Show Publication"
                            disabled={isHidden}
                        />
                        {showPublicationRange && (
                            <FormField
                                component={SliderRadio}
                                name={FORM_FIELD_NAMES.publicationOffset}
                                id={`${uniqueId}-display-offset`}
                                errors={errors}
                                touched={touched}
                                classes={{ container: 'formItem' }}
                                label="Number of Days to Offset"
                                isLabelShown={false}
                                step={null}
                                min={-3}
                                max={15}
                                marks={OFFSET_PRESETS.map((offset) => ({
                                    value: offset,
                                    label: offset.toString(),
                                }))}
                                track={false}
                                valueLabelFormat={sliderValueLabelFormat}
                                disabled={isHidden}
                            />
                        )}
                    </div>
                </div>
                <Button
                    size={SIZES.small}
                    type="submit"
                    disabled={isHidden}
                >
                    Save Settings
                </Button>
                <Button
                    color={COLORS.flat}
                    size={SIZES.small}
                    onClick={onCancel}
                    disabled={isHidden}
                >
                    Cancel
                </Button>
            </form>
        </div>
    );
};

export const PublicationSettingsForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => (
        props.values
    ),
    handleSubmit: (values, { props }) => {
        props.updatePublicationSettings(values.exclusions.map((exclusion) => (exclusion.label)), (values.show_publication_toggle === TOGGLE_IMMEDIATELY_VALUE) ? OFFSET_IMMEDIATELY
            : values.publication_offset);
    },
})(BasePublicationSettingsForm);

BasePublicationSettingsForm.propTypes = {
    classes: PropTypes.shape({
        form: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    updatePublicationSettings: PropTypes.func.isRequired,
    uniqueId: PropTypes.string.isRequired,
    values: PropTypes.shape({
        exclusions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })),
    }),
    allExclusions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    touched: PropTypes.shape({}).isRequired,
    errors: PropTypes.shape({}).isRequired,
    onCancel: PropTypes.func.isRequired,
    isHidden: PropTypes.bool,
};

BasePublicationSettingsForm.defaultProps = {
    classes: {
        form: null,
        root: null,
    },
    values: {
        exclusions: [],
    },
    isHidden: false,
};
