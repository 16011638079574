// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-styles-PDFButtons__button--xd19_{position:relative;width:12em;height:2.8em;text-align:center;font-size:1.2rem}.src-styles-PDFButtons__leftButton--jpD1n{margin-right:auto}.src-styles-PDFButtons__fullscreenCloseButton--t0Ns3{position:fixed;width:4.4em;height:2em;font-size:1.2em;top:4rem;right:3.5rem;z-index:300}`, "",{"version":3,"sources":["webpack://./src/styles/PDFButtons.scss"],"names":[],"mappings":"AACA,sCACI,iBAAA,CACA,UAAA,CACA,YAAA,CACA,iBAAA,CACA,gBAAA,CAGJ,0CACI,iBAAA,CAGJ,qDACI,cAAA,CACA,WAAA,CACA,UAAA,CACA,eAAA,CACA,QAAA,CACA,YAAA,CACA,WAAA","sourcesContent":["@import 'variables';\n.button {\n    position: relative;\n    width: 12em;\n    height: 2.8em;\n    text-align: center;\n    font-size: 1.2rem;\n}\n\n.leftButton {\n    margin-right: auto;\n}\n\n.fullscreenCloseButton {\n    position: fixed;\n    width: 4.4em;\n    height: 2em;\n    font-size: 1.2em;\n    top: 4rem;\n    right: 3.5rem;\n    z-index: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#ec9300`,
	"secondary": `#00a5aa`,
	"secondaryDark": `#264550`,
	"backgroundBlue": `#eaf6f7`,
	"button": `src-styles-PDFButtons__button--xd19_`,
	"leftButton": `src-styles-PDFButtons__leftButton--jpD1n`,
	"fullscreenCloseButton": `src-styles-PDFButtons__fullscreenCloseButton--t0Ns3`
};
export default ___CSS_LOADER_EXPORT___;
