export const FIREFOX = 'Mozilla Firefox';
export const SAMSUNG_BROWSER = 'Samsung Internet';
export const OPERA = 'Opera';
export const MICROSOFT_INTERNET_EXPLORER = 'Trident';
export const MICROSOFT_EDGE = 'Edge';
export const CHROME = 'Google Chrome or Chromium';
export const SAFARI = 'Apple Safari';

const getBrowserAgent = () => {
    const sUsrAg = navigator.userAgent;
    let sBrowser;

    if (sUsrAg.indexOf('Firefox') > -1) {
        sBrowser = FIREFOX;
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
        sBrowser = SAMSUNG_BROWSER;
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
        sBrowser = OPERA;
    } else if (sUsrAg.indexOf('Trident') > -1) {
        sBrowser = MICROSOFT_INTERNET_EXPLORER;
    } else if (sUsrAg.indexOf('Edge') > -1) {
        sBrowser = MICROSOFT_EDGE;
    } else if (sUsrAg.indexOf('Chrome') > -1) {
        sBrowser = CHROME;
    } else if (sUsrAg.indexOf('Safari') > -1) {
        sBrowser = SAFARI;
    } else {
        sBrowser = 'unknown';
    }

    return sBrowser;
};

const getIsMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export { getBrowserAgent, getIsMobile };
