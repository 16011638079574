// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4{margin-bottom:3rem}.src-components-shared-Forms-Contact-ContactForm__section--zB9cy:nth-child(1){margin-bottom:3rem}.src-components-shared-Forms-Contact-ContactForm__section--zB9cy:nth-child(2){margin-bottom:3rem}`, "",{"version":3,"sources":["webpack://./src/components/shared/Forms/Contact/ContactForm.scss"],"names":[],"mappings":"AACA,GACI,kBAAA,CAGJ,8EACI,kBAAA,CAGJ,8EACI,kBAAA","sourcesContent":["@import 'variables';\nh4 {\n    margin-bottom: 3rem;\n}\n\n.section:nth-child(1) {\n    margin-bottom: 3rem;\n}\n\n.section:nth-child(2) {\n    margin-bottom: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#ec9300`,
	"secondary": `#00a5aa`,
	"secondaryDark": `#264550`,
	"backgroundBlue": `#eaf6f7`,
	"section": `src-components-shared-Forms-Contact-ContactForm__section--zB9cy`
};
export default ___CSS_LOADER_EXPORT___;
