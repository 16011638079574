import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { toast } from 'react-toastify';

import { getAuthToken } from '../../auth/authHelpers';
import { customerDataMicroserviceRequest } from '../../services/api/customer-data';

const stylesProps = {
    groupsContainer: {
        display: 'flex',
    },
    listItemButton: {
        width: '400px',
    },
    checkbox: {
        minWidth: '24px',
    },
    listItemText: {
        fontSize: '14px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
    },
    list: {
        width: '50%',
        fontSize: '12px',
        fontWeight: 'bold',
        maxWidth: '50%',
        bgcolor: 'background.paper',
    },
    divider: {
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'left',
    },
    formControl: {
        width: '100%',
        m: 1,
    },
    input: {
        mb: 1.5,
    },
};

const GroupSelectionList = ({
    siteId,
    checkedGroups,
    setCheckedGroups,
    salesforceId,
    isHidden,
}) => {
    const { getAccessTokenSilently } = useAuth0();

    const [notificationGroups, setNotificationGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [groupName, setGroupName] = useState('');

    const siteIDReference = useRef(siteId);

    const fetchNotificationTopics = useCallback(async () => {
        const url = `organization-topics/${salesforceId}`;
        const apiBaseUrl = process.env.ONE_SIGNAL_NOTIFICATIONS_API_URL;

        try {
            const token = await getAuthToken(getAccessTokenSilently);
            const {
                data: notificationGroupsList = [],
            } = await customerDataMicroserviceRequest('get', apiBaseUrl, url, token);

            setNotificationGroups(notificationGroupsList);
        } catch (error) {
            toast.error('Error while fetching organization notification groups');
        }

        setIsLoading(false);
    }, [
        getAccessTokenSilently,
        salesforceId,
        setIsLoading,
    ]);

    // Fetch our notification topics once we receive a valid site ID from parent
    useEffect(() => {
        siteIDReference.current = siteId;

        if (siteId) {
            fetchNotificationTopics();
        }
    }, [
        fetchNotificationTopics,
        siteId,
    ]);

    const handleGroupSelection = (value) => () => {
        const currentIndex = checkedGroups.findIndex((item) => item === value);
        const newChecked = currentIndex === -1
            ? [...checkedGroups, value]
            : [
                ...checkedGroups.slice(0, currentIndex),
                ...checkedGroups.slice(currentIndex + 1),
            ];
        setCheckedGroups(newChecked);
    };

    const sanitizedMesg = (value) => (value ? value.replace(/ {2,}/g, ' ').trim() : '');

    const handleAddGroup = async () => {
        const url = `organization-topics/${salesforceId}`;
        const apiBaseUrl = process.env.ONE_SIGNAL_NOTIFICATIONS_API_URL;

        const requestPayload = {
            topicName: groupName,
        };

        try {
            const token = await getAuthToken(getAccessTokenSilently);

            await customerDataMicroserviceRequest('post', apiBaseUrl, url, token, requestPayload);

            setGroupName('');

            toast.success('Organization notification group created successfully');

            setIsLoading(true);

            await fetchNotificationTopics();
        } catch (error) {
            toast.error('Error while creating organization notification group');
        }
    };

    const renderListItem = (group, index) => {
        const labelId = `checkbox-list-label-${index}`;
        return (
            <ListItem
                key={`index-${group.name}-key`}
                disablePadding
            >
                <ListItemButton
                    role={undefined}
                    onClick={handleGroupSelection(group)}
                    sx={stylesProps.listItemButton}
                    divider
                    dense
                    disabled={isHidden}
                >
                    <ListItemIcon sx={stylesProps.checkbox}>
                        <Checkbox
                            edge="start"
                            checked={checkedGroups.some((item) => item === group)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            disabled={isHidden}
                        />
                    </ListItemIcon>
                    <ListItemText
                        sx={stylesProps.listItemText}
                        id={labelId}
                        primary={group.name}
                    />
                </ListItemButton>
            </ListItem>
        );
    };

    const notificationGroupsView = () => {
        const halfLength = Math.ceil(notificationGroups.length / 2);
        const firstColumn = notificationGroups.slice(0, halfLength);
        const secondColumn = notificationGroups.slice(halfLength);

        return (
            <>
                <div style={stylesProps.groupsContainer}>
                    <List sx={stylesProps.list}>
                        {firstColumn.map((group, index) => renderListItem(group, index))}
                    </List>
                    {secondColumn.length > 0 && (
                        <List sx={stylesProps.list}>
                            {secondColumn.map((group, index) => renderListItem(group, index + halfLength))}
                        </List>
                    )}
                </div>
                <Typography
                    mt={2}
                    variant="h2"
                >
                    Add new notification group
                </Typography>
                <TextField
                    fullWidth
                    id="standard-adornment-amount"
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        disabled={!sanitizedMesg(groupName) || isHidden}
                                        onClick={handleAddGroup}
                                        size="small"
                                        variant="contained"
                                    >
                                        Add
                                    </Button>
                                </InputAdornment>
                            ),
                        },
                    }}
                    label="Enter a new notification group name"
                    margin="normal"
                    onChange={(e) => setGroupName(e.target.value)}
                    value={groupName}
                    disabled={isHidden}
                />
            </>
        );
    };

    return isLoading ? <LinearProgress /> : notificationGroupsView();
};

GroupSelectionList.propTypes = {
    siteId: PropTypes.number,
    checkedGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    setCheckedGroups: PropTypes.func.isRequired,
    salesforceId: PropTypes.string.isRequired,
    isHidden: PropTypes.bool,
};

GroupSelectionList.defaultProps = {
    siteId: null,
    isHidden: false,
};

export default GroupSelectionList;
