import {
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

// Hook for getting page width when user resizes window
export const useWindowSize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useLayoutEffect(() => {
        function updateWidth() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWidth);

        updateWidth();

        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    return width;
};

// Hook for getting previous value of a variable
export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};
