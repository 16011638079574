import { useAuth0 } from '@auth0/auth0-react';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import {
    faLocationDot,
    faPhoneFlip,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
    Form,
    Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, {
    useMemo,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getAuthToken } from '../../../../auth/authHelpers';
import { AbilityContext } from '../../../../auth/Can';
import {
    LISTING_TYPE_ORGANIZATION,
    LISTING_TYPE_SPONSOR,
} from '../../../../constants/site';
import {
    ALIGN,
    COLORS,
    SIZES,
} from '../../../../constants/theme';
import customerDataRequest from '../../../../services/api/customer-data';
import { cacheInvalidation } from '../../../../utils/cacheInvalidation';
import { getUtcDateTime } from '../../../../utils/date';
import { redirectToSlug } from '../../../../utils/routes';

import Button from '../../../shared/Button';
import Drawer from '../../../shared/Drawer';
import FormField from '../../../shared/FormElements/FormField';
import TextField from '../../../shared/FormElements/TextField';
import ContactForm from '../../../shared/Forms/Contact';
import SettingsForm from '../../../shared/Forms/Settings';
import SocialForm from '../../../shared/Forms/Social';
import Loader from '../../../shared/Loader';
import ModalDialog from '../../../shared/ModalDialog/Base';
import { Tabs } from '../../../shared/Tabs';

import styles from './AccountSettingsDrawer.scss';

const AccountSettingsDrawer = ({
    billingInfo,
    classes,
    contactInfo,
    description,
    isDrawerOpen,
    listingId,
    listingType,
    loadOrganization,
    pageSlug,
    setPageSlug,
    pageTitle,
    profileImageUrl,
    salesforceId,
    setIsDrawerOpen,
    setProfileImageUrl,
    socialData,
    ...props
}) => {
    const {
        getAccessTokenSilently,
    } = useAuth0();
    const ability = useAbility(AbilityContext);
    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [updatedSlug, setUpdatedSlug] = useState(null);
    const [isRedirectDialogOpen, setIsRedirectDialogOpen] = useState(false);

    const FORM_FIELD_NAMES = {
        description: 'description',
    };

    const initialValues = useMemo(() => ({
        [FORM_FIELD_NAMES.description]: 'description',
    }), [FORM_FIELD_NAMES.description]);

    const baseUrl = (listingType === LISTING_TYPE_SPONSOR) ? 'sponsors' : 'organizations';

    const closeDrawer = () => {
        setIsDrawerOpen(false);
        props.setHours(() => { });
    };

    const generateUrl = () => {
        const id = salesforceId || listingId;
        return `/${baseUrl}/${id}`;
    };

    const updateSocials = async (socials) => {
        setIsLoading(true);

        const token = await getAuthToken(getAccessTokenSilently);

        try {
            const socialDataToUpdate = {
                website: socials.websiteUrl,
                youtube_url: socials.youtubeUrl,
                facebook_url: socials.facebookUrl,
                linkedin_url: socials.linkedinUrl,
                instagram_url: socials.instagramUrl,
                twitter_url: socials.twitterUsername,
            };

            if (listingType === LISTING_TYPE_ORGANIZATION) {
                socialDataToUpdate.live_streaming = socials.liveStreaming;
                socialDataToUpdate.donation_url = socials.donationUrl;
            }

            await customerDataRequest('patch', generateUrl(), token, socialDataToUpdate);

            toast.success('Social media data updated');
        } catch (error) {
            toast.error('There was an error saving social data.');
        } finally {
            // Cache is currently only enabled for organizations
            if (baseUrl === 'organizations') {
                await cacheInvalidation(`/${baseUrl}/slug/${pageSlug}`, token);
            }

            setIsLoading(false);
            setIsDrawerOpen(false);
            loadOrganization();
        }
    };

    const updateSettings = async (newPageTitle, newPageSlug, newDescription) => {
        setIsLoading(true);

        let isError = false;
        const fieldsToUpdate = {};
        if (newPageTitle !== pageTitle) {
            fieldsToUpdate.physical_title = newPageTitle;
        }
        if (newDescription !== description) {
            fieldsToUpdate.description = newDescription;
        }

        const token = await getAuthToken(getAccessTokenSilently);

        try {
            if (Object.keys(fieldsToUpdate).length > 0) {
                const response = await customerDataRequest('patch', generateUrl(), token, {
                    physical_title: newPageTitle,
                    description: newDescription,
                    updated_at: getUtcDateTime(new Date()),
                });

                if (response?.status >= 400) {
                    isError = true;
                }
            }

            if (ability.can('admin', subject('claimed', { id: listingId }))) {
                if (newPageSlug !== pageSlug) {
                    const response = await customerDataRequest('put', `${generateUrl()}/slug`, token, {
                        slug: newPageSlug,
                    });

                    if (response?.status >= 400) {
                        isError = true;
                    }
                }
            }

            if (isError) {
                toast.error('There was an error saving profile.');
            } else {
                if (newPageSlug !== pageSlug) {
                    // Cache is currently only enabled for organizations
                    if (baseUrl === 'organizations') {
                        await cacheInvalidation(`/${baseUrl}/slug/${newPageSlug}`, token);
                    }

                    setUpdatedSlug(newPageSlug);
                    await loadOrganization(newPageSlug);
                    setIsRedirectDialogOpen(true);
                } else {
                    // Cache is currently only enabled for organizations
                    if (baseUrl === 'organizations') {
                        await cacheInvalidation(`/${baseUrl}/slug/${pageSlug}`, token);
                    }

                    loadOrganization();
                }

                toast.success('Profile updated');
                setIsLoading(false);
                setIsDrawerOpen(false);
            }
        } catch (error) {
            toast.error('There was an error saving profile.');
            setIsLoading(false);
            setIsDrawerOpen(false);
        }
    };

    const updateContactInfo = async (newContactInfo) => {
        setIsLoading(true);

        const token = await getAuthToken(getAccessTokenSilently);

        try {
            await customerDataRequest('patch', generateUrl(), token, {
                physical_contact_name: newContactInfo.contactName,
                physical_contact_email: newContactInfo.contactEmail,
                physical_contact_role: newContactInfo.contactRole,
                physical_phone: newContactInfo.contactPhone,
                physical_street: newContactInfo.listingAddress1,
                physical_street2: newContactInfo.listingAddress2,
                physical_street3: newContactInfo.listingAddress3,
                physical_city: newContactInfo.listingCity,
                physical_state: newContactInfo.listingState,
                physical_postal_code: newContactInfo.listingZip,
            });

            toast.success('Contact updates successfully saved');
        } catch (error) {
            toast.error('There was an error saving contact info.');
        } finally {
            // Cache is currently only enabled for organizations
            if (baseUrl === 'organizations') {
                await cacheInvalidation(`/${baseUrl}/slug/${pageSlug}`, token);
            }

            setIsLoading(false);
            setIsDrawerOpen(false);
            loadOrganization();
        }
    };

    return (
        <>
            <Drawer
                className={classNames(classes.root, styles.container)}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            >
                {isLoading && <Loader align={ALIGN.center} color={COLORS.secondary} isDrawerLoader />}
                <Tabs isHidden={!isDrawerOpen}>
                    <div data-title="Contact" data-key="contact">
                        {contactInfo && (
                            <ContactForm
                                uniqueId="contactForm"
                                updateContactInfo={updateContactInfo}
                                contactInfo={contactInfo}
                                listingType={listingType}
                                onCancel={closeDrawer}
                                isHidden={!isDrawerOpen}
                            />
                        )}
                    </div>
                    {listingType === LISTING_TYPE_SPONSOR && (
                        <div data-title="Business Hours" data-key="businessHours">
                            <section style={{ paddingTop: '2.6rem' }}>
                                <Formik
                                    initialValues={initialValues}
                                >
                                    {({
                                        errors,
                                        touched,
                                    }) => (
                                        <Form>
                                            <div className={styles.textFieldContainer}>
                                                <FormField
                                                    label="Business Hours"
                                                    component={TextField}
                                                    name={FORM_FIELD_NAMES.description}
                                                    errors={errors[FORM_FIELD_NAMES.description]}
                                                    touched={touched[FORM_FIELD_NAMES.description]}
                                                    value={props.hours}
                                                    required={false}
                                                    onChange={(e) => {
                                                        props.setHours(e.target.value);
                                                    }}
                                                    className={styles.textField}
                                                    showOptional={false}
                                                    size="medium"
                                                    multiline
                                                    disabled={!isDrawerOpen}
                                                />
                                            </div>
                                            <div className={styles.buttonContainer}>
                                                <Button
                                                    size={SIZES.small}
                                                    type="submit"
                                                    onClick={props.updateBusinessHours(props.hours)}
                                                    disabled={!isDrawerOpen}
                                                >
                                                    Update Business Hours
                                                </Button>
                                                <Button
                                                    color={COLORS.flat}
                                                    size={SIZES.small}
                                                    onClick={closeDrawer}
                                                    disabled={!isDrawerOpen}
                                                >
                                                    Cancel
                                                </Button>
                                                <div className={classNames(styles.descriptionText)} />
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </section>
                        </div>
                    )}
                    <div data-title="Profile" data-key="profile">
                        <SettingsForm
                            uniqueId="settingsForm"
                            pageSlug={pageSlug}
                            pageTitle={pageTitle}
                            profileImageUrl={profileImageUrl}
                            setProfileImageUrl={setProfileImageUrl}
                            updateSettings={updateSettings}
                            description={description || ''}
                            listingType={listingType}
                            listingId={listingId || salesforceId}
                            onCancel={closeDrawer}
                            setIsLoading={setIsLoading}
                            isHidden={!isDrawerOpen}
                        />
                    </div>
                    <div data-title="Web &amp; Social" data-key="web-social">
                        <SocialForm
                            uniqueId="socialForm"
                            socialData={socialData}
                            updateSocials={updateSocials}
                            listingType={listingType}
                            onCancel={closeDrawer}
                            isHidden={!isDrawerOpen}
                        />
                    </div>
                    <div data-title="LPi billing" data-key="lpi-billing">
                        <div
                            className={classNames('sheetForm')}
                        >
                            <div className={styles.infoSection}>
                                <div className={styles.informationContainer}>
                                    <div className={styles.infoLabel}>
                                        <p
                                            className={styles.label}
                                        >
                                            Billing Address
                                        </p>
                                    </div>
                                    <div className={styles.infoIcon}>
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </div>
                                    <div className={styles.infoText}>
                                        {billingInfo.billingAddress1}
                                        {billingInfo.billingAddress2 && (
                                            <>
                                                <br />
                                                {billingInfo.billingAddress2}
                                            </>
                                        )}
                                        <br />
                                        {billingInfo.billingCity}
                                        &#44;&nbsp;
                                        {billingInfo.billingState}
                                        &nbsp;
                                        {billingInfo.billingPostalCode}
                                    </div>
                                </div>
                                <div className={styles.informationContainer}>
                                    <div className={styles.infoLabel}>
                                        <p
                                            className={styles.label}
                                        >
                                            Billing Phone
                                        </p>
                                    </div>
                                    <div className={styles.infoIcon}>
                                        <FontAwesomeIcon icon={faPhoneFlip} />
                                    </div>
                                    <div className={styles.infoText}>
                                        {billingInfo.billingPhone}
                                    </div>
                                </div>
                            </div>
                            <p className={styles.helpText}>
                                This information is not displayed to the public and is for
                                informational purposes only
                            </p>
                            <a
                                href="https://www.4lpi.com/contact-us"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Request Billing Change
                            </a>
                        </div>
                    </div>
                </Tabs>
            </Drawer>
            <ModalDialog
                title="You&apos;re Being Redirected"
                content="The page will now be directed to the new website address"
                open={isRedirectDialogOpen}
                buttons={(
                    <Button
                        onClick={() => {
                            redirectToSlug(updatedSlug, history, listingType, setIsRedirectDialogOpen);
                            setUpdatedSlug(null);
                        }}
                        color={COLORS.flat}
                        autoFocus
                    >
                        Confirm
                    </Button>
                )}
            />
        </>
    );
};

AccountSettingsDrawer.propTypes = {
    billingInfo: PropTypes.object.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    contactInfo: PropTypes.object.isRequired,
    description: PropTypes.string,
    hours: PropTypes.string,
    isDrawerOpen: PropTypes.bool.isRequired,
    listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    listingType: PropTypes.oneOf([LISTING_TYPE_ORGANIZATION, LISTING_TYPE_SPONSOR]).isRequired,
    loadOrganization: PropTypes.func.isRequired,
    pageSlug: PropTypes.string,
    setPageSlug: PropTypes.func,
    pageTitle: PropTypes.string.isRequired,
    profileImageUrl: PropTypes.string,
    salesforceId: PropTypes.string,
    setHours: PropTypes.func,
    setIsDrawerOpen: PropTypes.func.isRequired,
    setProfileImageUrl: PropTypes.func,
    socialData: PropTypes.object.isRequired,
    updateBusinessHours: PropTypes.func,
};

AccountSettingsDrawer.defaultProps = {
    classes: {
        root: null,
        title: null,
    },
    description: '',
    hours: undefined,
    listingId: null,
    pageSlug: null,
    setPageSlug: () => { },
    profileImageUrl: null,
    salesforceId: null,
    setHours: () => { },
    setProfileImageUrl: () => null,
    updateBusinessHours: null,
};

export default AccountSettingsDrawer;
