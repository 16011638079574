import { LISTING_TYPE_ORGANIZATION } from '../constants/site';
import * as routes from '../constants/routes';

const fillRouteParameters = (route = '', parameters = {}) => (
    Object.keys(parameters).reduce((builtRoute, currentKey) => (
        builtRoute.replace(`:${currentKey}`, parameters[currentKey])
    ), route)
);

const redirectToSlug = (slug,
    history,
    listingType,
    setOpenRedirectDialog = null,
    organizationRoute = routes.ORGANIZATION_DETAIL,
    sponsorRoute = routes.SPONSOR_DETAIL,
    filename = null,
    hash = null) => {
    let route = fillRouteParameters(listingType === LISTING_TYPE_ORGANIZATION
        ? organizationRoute : sponsorRoute, { slug, filename });

    if (setOpenRedirectDialog) {
        setOpenRedirectDialog(false);
    }

    if (hash) {
        route = `${route}${hash}`;
    }

    history(route);
};

// eslint-disable-next-line max-len
const getPublicationPage = (slug, fileUrl) => `${fillRouteParameters(routes.PUBLICATION_PAGE, { slug })}?selectedPublication=${fileUrl}`;

export {
    fillRouteParameters,
    redirectToSlug,
    getPublicationPage,
};
