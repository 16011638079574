// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-Forms-Social-SocialForm__container--L571H .src-components-shared-Forms-Social-SocialForm__sheetForm--q7RXn{margin-top:4rem}`, "",{"version":3,"sources":["webpack://./src/components/shared/Forms/Social/SocialForm.scss"],"names":[],"mappings":"AAEI,kIACI,eAAA","sourcesContent":["@import 'variables';\n.container {\n    .sheetForm {\n        margin-top: 4rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `#ec9300`,
	"secondary": `#00a5aa`,
	"secondaryDark": `#264550`,
	"backgroundBlue": `#eaf6f7`,
	"container": `src-components-shared-Forms-Social-SocialForm__container--L571H`,
	"sheetForm": `src-components-shared-Forms-Social-SocialForm__sheetForm--q7RXn`
};
export default ___CSS_LOADER_EXPORT___;
