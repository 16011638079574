import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import TextField from '../../FormElements/TextField';
import FormField from '../../FormElements/FormField';
import styles from './SubscriberRemove.scss';
import {
    COLORS,
    SIZES,
} from '../../../../constants/theme';
import Button from '../../Button';
import ContactType from '../../../../types/ContactType';
import Loader from '../../Loader';

const FORM_FIELD_NAMES = {
    contactEmail: 'contactEmail',
};

const SubscriberRemoveForm = ({
    uniqueId,
    contactInfo,
    organizationTitle,
    onClose,
    isSaving,
    isFormSubmitted,
    onSubmit,
}) => {
    // const themeContext = useContext(ThemeContext);
    const initialValues = {
        [FORM_FIELD_NAMES.contactEmail]: contactInfo.contactEmail,
    };

    const contactSchema = Yup.object().shape({
        [FORM_FIELD_NAMES.contactEmail]: Yup.string()
            .email('Must be a valid email address')
            .max(128, 'Email cannot be longer than 128 characters'),
    });
    return (
        <div
            className={classNames(styles.container)}
        >
            {isSaving && <Loader color={COLORS.secondary} />}
            <div
                className={classNames(styles.sheetForm)}
            >
                {(isFormSubmitted)
                    ? (
                        <p>
                            The user has been unsubscribed.
                        </p>
                    )
                    : (
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={contactSchema}
                        >

                            {({ errors, touched }) => (
                                <Form>
                                    <section
                                        className={classNames(styles.section)}
                                    >
                                        <p className={classNames(styles.adminInstruction)}>
                                            Remove a subscriber from
                                            {organizationTitle ? ' ' : ' the organization'}
                                            {organizationTitle}
                                            &apos;s email list.
                                        </p>
                                        <FormField
                                            label="Contact email"
                                            component={TextField}
                                            name={FORM_FIELD_NAMES.contactEmail}
                                            errors={errors[FORM_FIELD_NAMES.contactEmail]}
                                            touched={touched[FORM_FIELD_NAMES.contactEmail]}
                                            id={`${uniqueId}-email`}
                                            required
                                            fullWidth
                                        />
                                    </section>
                                    <div
                                        className={classNames(styles.btnContainer)}
                                    >
                                        <Button
                                            color={COLORS.flat}
                                            size={SIZES.small}
                                            onClick={onClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size={SIZES.small}
                                            type="submit"
                                        >
                                            Unsubscribe
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                {(isFormSubmitted)
                    ? (
                        <div
                            className={classNames(styles.btnContainer)}
                        >
                            <Button
                                size={SIZES.small}
                                onClick={onClose}
                            >
                                Okay
                            </Button>
                        </div>
                    )
                    : ('')}
            </div>
        </div>
    );
};

SubscriberRemoveForm.propTypes = {
    uniqueId: PropTypes.string.isRequired,
    contactInfo: ContactType.isRequired,
    organizationTitle: PropTypes.string,
    onClose: PropTypes.func,
    isFormSubmitted: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool,
    onSubmit: PropTypes.func,
};

SubscriberRemoveForm.defaultProps = {
    onClose: false,
    organizationTitle: null,
    isSaving: false,
    onSubmit: false,
};

export default SubscriberRemoveForm;
