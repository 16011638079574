import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, {
    useState,
} from 'react';

import {
    COLORS,
    SIZES,
} from '../../../constants/theme';

import Button from '../../shared/Button';

import styles from './OwnerHistoryView.scss';

const OwnerHistoryView = ({
    owners,
    isHidden,
}) => {
    const [isViewingOwnerDetails, setIsViewingOwnerDetails] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState(null);

    const viewOwnerDetails = (owner) => {
        setSelectedOwner(owner);
        setIsViewingOwnerDetails(true);
    };

    const getOwnerState = (owner) => {
        // First, return an empty string if the owner passed in is null
        if (!owner) {
            return '';
        }
        // Next, if the deleted_at date is set for any reason, this owner has been removed
        if (owner.deleted_at) {
            return 'Owner Removed';
        }
        // Next, if the reviewed_at date is set, this owner has been approved or denied
        if (owner.reviewed_at) {
            return owner.approved ? 'Request Approved' : 'Request Denied';
        }
        // Finally, if this has not yet been reviewed, either the owner has been added manually or is awaiting review
        return owner.approved ? 'Added Manually' : 'Awaiting Review';
    };

    const toFormattedDate = (dateString) => {
        if (dateString === '' || dateString === null || dateString === undefined) {
            return '';
        }
        const date = new Date(dateString);
        if (date.toString() === 'Invalid Date') {
            return '';
        }
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Date/Time</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {owners
                            .filter((owner) => owner.approved || owner.reviewed_at)
                            .map((owner) => (
                                <TableRow key={owner.id}>
                                    <TableCell>
                                        {`${owner.first_name || ''} ${owner.last_name || ''}`}
                                    </TableCell>
                                    <TableCell>
                                        {`${owner.email_address || ''}`}
                                    </TableCell>
                                    <TableCell>{toFormattedDate(owner.updated_at)}</TableCell>
                                    <TableCell>{getOwnerState(owner)}</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            size={SIZES.medium}
                                            color={COLORS.flat}
                                            onClick={() => viewOwnerDetails(owner)}
                                            disabled={isHidden}
                                        >
                                            Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={isViewingOwnerDetails}
                onClose={() => setIsViewingOwnerDetails(false)}
                maxWidth="lg"
            >
                <div style={{ position: 'absolute', top: 4, right: 4 }}>
                    <IconButton
                        aria-label="close"
                        size={SIZES.xlarge}
                        onClick={() => setIsViewingOwnerDetails(false)}
                        disabled={isHidden}
                    >
                        <FontAwesomeIcon size="2x" fixedWidth icon={faXmark} />
                    </IconButton>
                </div>
                <Stack
                    direction="row"
                    spacing={0}
                >
                    <Stack
                        direction="column"
                        sx={{
                            padding: '4rem',
                        }}
                        spacing={3}
                    >
                        {(selectedOwner?.firstname || selectedOwner?.lastname)
                            && (
                                <div>
                                    <div className={styles.detailTitle}>
                                        User
                                    </div>
                                    <div className={styles.detailText}>
                                        {`${selectedOwner?.firstname || ''} ${selectedOwner?.lastname || ''}`}
                                    </div>
                                </div>
                            )}
                        <div>
                            <div className={styles.detailTitle}>
                                User Email
                            </div>
                            <div className={styles.detailText}>
                                {selectedOwner?.email || ''}
                            </div>
                        </div>
                        <div>
                            <div className={styles.detailTitle}>
                                Date/Time
                            </div>
                            <div className={styles.detailText}>
                                {toFormattedDate(selectedOwner?.updated_at)}
                            </div>
                        </div>
                        <div>
                            <div className={styles.detailTitle}>
                                Action
                            </div>
                            <div className={styles.detailText}>
                                {getOwnerState(selectedOwner)}
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        padding="4rem"
                        bgcolor={COLORS.lightgray}
                    >
                        <div>
                            <div className={styles.detailTitle} style={{ marginBottom: '1rem' }}>
                                Action Approved By
                            </div>
                            <div className={styles.detailText}>
                                {
                                    // eslint-disable-next-line max-len
                                    `${selectedOwner?.reviewer_firstname || ''} ${selectedOwner?.reviewer_lastname || ''}`
                                }
                            </div>
                            <div className={styles.detailText}>
                                {selectedOwner?.reviewer_email || ''}
                            </div>
                        </div>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    );
};

OwnerHistoryView.propTypes = {
    owners: PropTypes.arrayOf(PropTypes.any),
    isHidden: PropTypes.bool,
};

OwnerHistoryView.defaultProps = {
    owners: [],
    isHidden: false,
};

export default OwnerHistoryView;
