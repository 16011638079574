import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { Descriptions } from '../../constants/Descriptions';
import {
    WIDGET_BACKGROUND_WHITE,
    WIDGET_DEFAULT_COLOR,
    WIDGET_MAX_WIDTH_MULTIPLE,
    WIDGET_MAX_WIDTH_SINGLE,
    WIDGET_NUM_PUBS_MULTIPLE,
    WIDGET_TEXT_LARGE,
    WIDGET_TEXT_NORMAL,
    WIDGET_TYPE_BULLETIN,
    WIDGET_TYPE_NEWSLETTER,
    WIDGET_TYPE_PUBLICATION,
    WIDGET_UNIQUE_ID,
} from '../../constants/publications';
import * as routes from '../../constants/routes';
import {
    COLORS,
    MCO_THEME,
    POL_THEME,
    SIZES,
    ThemeContext,
} from '../../constants/theme';
import { Titles } from '../../constants/titles';
import { AppContext } from '../../context/AppContext';
import { getPrettyPublishDate } from '../../utils/date';
import { loadPublicationsCallback } from '../../utils/organization';
import {
    fillRouteParameters,
    getPublicationPage,
} from '../../utils/routes';
import { capitalizeFirstLetter } from '../../utils/string';

import Button from '../shared/Button';
import Loader from '../shared/Loader';

import styles from './PublicationWidget.scss';

// New banner corner img
const NEW_BANNER_IMG = `${process.env.CUSTOMER_DATA_S3_URL}/widget/new-banner.png`;

const PublicationWidget = ({
    defaultBackground,
    defaultColor,
    defaultNumPubsShown,
    defaultSalesforceId,
    defaultTextSize,
    defaultType,
    isHidden,
}) => {
    const themeContext = useContext(ThemeContext);
    const {
        setPageTitle,
        setPageDescription,
    } = useContext(AppContext);
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const background = params.get('background') || defaultBackground;
    const color = params.get('color') || defaultColor;
    const salesforceId = params.get('id') || defaultSalesforceId;
    const numPubsShown = params.get('numPubsShown') || defaultNumPubsShown;
    const textSize = params.get('textSize') || defaultTextSize;
    const type = params.get('type') || defaultType;

    const [errorMessage, setErrorMessage] = useState(null);
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const [organizationURL, setOrganizationURL] = useState(null);
    const [publications, setPublications] = useState(null);
    const [slug, setSlug] = useState(null);
    const [widgetMaxWidth, setWidgetMaxWidth] = useState(WIDGET_MAX_WIDTH_MULTIPLE);

    // If MCO and type is 'Bulletin', update adjustedType to 'Newsletter'
    // Necessary for v1 publications widget, but not v2
    const adjustedType = themeContext === MCO_THEME && type === WIDGET_TYPE_BULLETIN
        ? WIDGET_TYPE_NEWSLETTER
        : type;

    const setData = (data) => {
        setPublications(data.publications);
        setSlug(data.slug);
        setOrganizationURL(fillRouteParameters(routes.ORGANIZATION_DETAIL, { slug: data.slug }));
    };

    const loadPublications = useCallback(async () => {
        // Load the first 4 publications
        const orgType = themeContext === POL_THEME ? 'Church' : 'Community';
        await loadPublicationsCallback(salesforceId, orgType, setData, null, 4, setErrorMessage, true);
    }, [salesforceId, themeContext]);

    useEffect(() => {
        loadPublications();
    }, [loadPublications]);

    useEffect(() => {
        if (location.pathname.includes(routes.PUBLICATION_WIDGET)) {
            switch (themeContext) {
                case MCO_THEME:
                    setPageTitle(Titles.MCO_WIDGET);
                    setPageDescription(Descriptions.MCO_WIDGET);
                    break;
                case POL_THEME:
                    setPageTitle(Titles.POL_WIDGET);
                    setPageDescription(Descriptions.POL_WIDGET);
                    break;
                default:
                    break;
            }
        }
    }, [themeContext, setPageTitle, setPageDescription, location.pathname]);

    useEffect(() => {
        if (!salesforceId) {
            setErrorMessage('An error occurred while loading the widget. Organization ID not specified.');
        } else if (
            type !== WIDGET_TYPE_PUBLICATION
            && type !== WIDGET_TYPE_BULLETIN
            && type !== WIDGET_TYPE_NEWSLETTER
        ) {
            setErrorMessage('An error occurred while loading the widget. Invalid type specified.');
        }
    }, [salesforceId, type]);

    useEffect(() => {
        setWidgetMaxWidth(numPubsShown === WIDGET_NUM_PUBS_MULTIPLE
            ? WIDGET_MAX_WIDTH_MULTIPLE
            : WIDGET_MAX_WIDTH_SINGLE);
    }, [numPubsShown]);

    const handleMouseEnter = () => setIsButtonHovered(true);
    const handleMouseLeave = () => setIsButtonHovered(false);

    return (
        <Paper
            elevation={background === WIDGET_BACKGROUND_WHITE ? 6 : 0}
            sx={{
                backgroundColor: background === WIDGET_BACKGROUND_WHITE ? '#fff' : 'transparent',
                maxWidth: {
                    xs: WIDGET_MAX_WIDTH_SINGLE,
                    sm: widgetMaxWidth,
                },
            }}
        >
            <Box p={4}>
                {/* Show error message and nothing else if present */}
                {errorMessage && (
                    <h2 className={styles.errorText}>
                        {errorMessage}
                    </h2>
                )}
                {/* Show loading circle while publications load */}
                {!publications && !errorMessage && (
                    <Loader />
                )}
                {/* Show content once publications are loaded */}
                {publications && !!publications.length && !errorMessage
                    && (
                        <Grid
                            container
                            spacing={4}
                        >
                            {/* First publication */}
                            <Grid
                                key={`${WIDGET_UNIQUE_ID}-${publications[0].name}`}
                                size={{
                                    xs: 12,
                                    sm: numPubsShown === WIDGET_NUM_PUBS_MULTIPLE ? 4.5 : 12,
                                }}
                            >
                                <a
                                    className={styles.largePubContainer}
                                    href={getPublicationPage(slug, publications[0].fileUrl)}
                                    target="_blank"
                                    rel="noreferrer"
                                    tabIndex={isHidden ? -1 : 0}
                                >
                                    <img
                                        className={styles.largeThumbnail}
                                        src={publications[0].extraLargeThumbnailUrl || publications[0].largeThumbnailUrl}
                                        alt="publication"
                                        width="155"
                                        height="200"
                                    />
                                    <img
                                        className={styles.newBannerImage}
                                        src={NEW_BANNER_IMG}
                                        alt="New"
                                        width="100"
                                        height="100"
                                    />
                                </a>
                                <h2 className={classNames(styles.displayDate, textSize === WIDGET_TEXT_LARGE && styles.largeText, numPubsShown !== WIDGET_NUM_PUBS_MULTIPLE && styles.negativeMargin)}>
                                    <span>NEW!</span>
                                    &nbsp;&nbsp;
                                    {getPrettyPublishDate(publications[0])}
                                </h2>
                            </Grid>
                            <Grid
                                container
                                size={{
                                    xs: 12,
                                    sm: numPubsShown === WIDGET_NUM_PUBS_MULTIPLE ? 7.5 : 12,
                                }}
                                spacing={4}
                            >
                                {/* Last 3 publications */}
                                {numPubsShown === WIDGET_NUM_PUBS_MULTIPLE
                                    && publications.slice(1).map((currentPublication) => (
                                        <Grid
                                            key={`${WIDGET_UNIQUE_ID}-${currentPublication.name}`}
                                            size={{
                                                xs: 12,
                                                sm: 4,
                                            }}
                                        >
                                            <a
                                                className={styles.smallPubContainer}
                                                href={getPublicationPage(slug, currentPublication.fileUrl)}
                                                target="_blank"
                                                rel="noreferrer"
                                                tabIndex={isHidden ? -1 : 0}
                                            >
                                                <img
                                                    className={styles.smallThumbnail}
                                                    // Large image is used intentionally here
                                                    src={currentPublication.largeThumbnailUrl}
                                                    alt="publication"
                                                    width="155"
                                                    height="200"
                                                />
                                            </a>
                                            <h2 className={classNames(styles.displayDate, textSize === WIDGET_TEXT_LARGE && styles.largeText)}>
                                                {getPrettyPublishDate(currentPublication)}
                                            </h2>
                                        </Grid>
                                    ))}
                                <Grid size={12}>
                                    <a
                                        className={styles.buttonLink}
                                        href={organizationURL}
                                        target="_blank"
                                        rel="noreferrer"
                                        tabIndex={isHidden ? -1 : 0}
                                    >
                                        <Button
                                            color={COLORS.lightgray}
                                            fullWidth
                                            otherClasses={classNames(styles.buttonBottomMargin, textSize === WIDGET_TEXT_LARGE && styles.largeText)}
                                            size={SIZES.large}
                                            tabIndex={-1}
                                        >
                                            <span className={styles.buttonTextSpan}>
                                                {`View Past ${capitalizeFirstLetter(adjustedType)}s`}
                                            </span>
                                        </Button>
                                    </a>
                                    <a
                                        className={styles.buttonLink}
                                        href={`${organizationURL}#subscribe`}
                                        target="_blank"
                                        rel="noreferrer"
                                        tabIndex={isHidden ? -1 : 0}
                                    >
                                        <Button
                                            color={COLORS.gray}
                                            fullWidth
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                            otherClasses={classNames(textSize === WIDGET_TEXT_LARGE && styles.largeText)}
                                            size={SIZES.large}
                                            style={{
                                                backgroundColor: isButtonHovered ? `${color}BF` : color,
                                                borderColor: isButtonHovered ? `${color}BF` : color,
                                            }}
                                            tabIndex={-1}
                                        >
                                            <span className={styles.buttonTextSpan}>
                                                Sign Up!
                                            </span>
                                        </Button>
                                    </a>
                                    <div className={classNames(styles.helpText, textSize === WIDGET_TEXT_LARGE && styles.largeText, numPubsShown !== WIDGET_NUM_PUBS_MULTIPLE && styles.singlePubShown)}>
                                        {`Get ${adjustedType}s delivered to you ${themeContext === MCO_THEME ? 'monthly' : 'weekly'}${numPubsShown === WIDGET_NUM_PUBS_MULTIPLE ? ' by signing up' : ''}.`}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
            </Box>
        </Paper>
    );
};

PublicationWidget.propTypes = {
    defaultBackground: PropTypes.string,
    defaultColor: PropTypes.string,
    defaultNumPubsShown: PropTypes.string,
    defaultSalesforceId: PropTypes.string,
    defaultTextSize: PropTypes.string,
    defaultType: PropTypes.string,
    isHidden: PropTypes.bool,
};

PublicationWidget.defaultProps = {
    defaultBackground: WIDGET_BACKGROUND_WHITE,
    defaultColor: WIDGET_DEFAULT_COLOR,
    defaultNumPubsShown: WIDGET_NUM_PUBS_MULTIPLE,
    defaultSalesforceId: null,
    defaultTextSize: WIDGET_TEXT_NORMAL,
    defaultType: WIDGET_TYPE_BULLETIN,
    isHidden: false,
};

export default PublicationWidget;
