import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { COLORS } from '../../../../constants/theme';
import ModalDialog from '../Base/ModalDialog';

const DeleteModalDialog = ({
    open,
    handleCancel,
    handleDelete,
    title,
    content,
    ...otherProps
}) => {
    const buttons = (
        <>
            <Button onClick={handleCancel} color={COLORS.flat}>
                Cancel
            </Button>
            <Button onClick={handleDelete} disabled={!open} color={COLORS.flat} autoFocus>
                Delete
            </Button>
        </>
    );

    return (
        <ModalDialog
            title={title}
            content={content}
            open={open}
            onClose={handleCancel}
            buttons={buttons}
            {...otherProps}
        />
    );
};

DeleteModalDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

DeleteModalDialog.defaultProps = {
    content: null,
};

export default DeleteModalDialog;
