import React, {
    useCallback,
    useEffect,
    useState,
    useContext,
} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import * as routes from '../../constants/routes';
import {
    COLORS,
    POL_THEME,
    ThemeContext,
} from '../../constants/theme';
import { fillRouteParameters } from '../../utils/routes';
import Loader from '../shared/Loader';
import styles from './AdsSponsorsSection.scss';
import customerDataRequest from '../../services/api/customer-data';

const AdsSponsorsSection = ({ organizationSalesforceId, slug }) => {
    const themeContext = useContext(ThemeContext);

    const [isLoading, setIsLoading] = useState(false);
    const [sponsors, setSponsors] = useState([]);

    const loadData = useCallback(async () => {
        setIsLoading(true);

        if (organizationSalesforceId) {
            try {
                const response = await customerDataRequest('get', `/organizations/${organizationSalesforceId}/sponsors/`);
                setSponsors(response.data.data);
            } catch {
                toast.error('There was an error loading sponsors.');
            }
        }

        setIsLoading(false);
    }, [organizationSalesforceId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const sorting = (title, title1) => (
        (title.physical_title > title1.physical_title) ? 1 : 0
    );

    const getSortedList = (title, title1) => (
        title.physical_title < title1.physical_title ? -1 : sorting(title, title1)
    );

    return (
        !!sponsors.length && (
            <div className={styles.container}>
                <div className={styles.header}>
                    Support
                    {themeContext === POL_THEME ? ' Your Parish Advertisers' : ' Our Sponsors'}
                </div>
                <div className={classNames(styles.sponsorsContainer, 'border-section')}>
                    {isLoading && <Loader color={COLORS.secondary} />}
                    <div className={styles.sponsorsList} data-testid="sponsors-list">
                        {sponsors.sort(getSortedList).map((currentSponsor) => {
                            const sponsorDetailRoute = fillRouteParameters(routes.SPONSOR_DETAIL, { slug: currentSponsor.slug });

                            return (
                                <div key={currentSponsor.advertiser_id}>
                                    <Link to={sponsorDetailRoute}>
                                        {currentSponsor.physical_title}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                    <Box
                        className={styles.viewAllContainer}
                        component="section"
                        sx={{
                            p: 1,
                        }}
                    >
                        <Link
                            to={fillRouteParameters(routes.ORGANIZATION_SUPPORTERS_PAGE, { slug })}
                        >
                            View All Supporters
                        </Link>
                    </Box>
                </div>
            </div>
        )
    );
};

AdsSponsorsSection.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    organizationSalesforceId: PropTypes.string,
    slug: PropTypes.string,
};

AdsSponsorsSection.defaultProps = {
    classes: {
        root: null,
    },
    organizationSalesforceId: null,
    slug: null,
};

export default AdsSponsorsSection;
