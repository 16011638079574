import { useAuth0 } from '@auth0/auth0-react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import { toast } from 'react-toastify';

import { getAuthToken } from '../../auth/authHelpers';
import { customerDataMicroserviceRequest } from '../../services/api/customer-data';
import { getDisplayDateTime } from '../../utils/date';

import DeleteModalDialog from '../shared/ModalDialog/Delete/DeleteModalDialog';

import GroupSelectionList from './GroupSelectionList';
import MessageComposer from './MessageComposer';
import { minimumDateTime } from './ScheduledDeliveryOptions';

const OrganizationsNotification = ({
    pageTitle,
    salesforceId,
    organizationContractId,
    isHidden,
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const apiBaseUrl = process.env.ONE_SIGNAL_NOTIFICATIONS_API_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [registeredSiteID, setRegisteredSiteID] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const [steps, setSteps] = useState([
        'Register your organization',
        'Prepare and send your message',
    ]);

    const [checkedGroups, setCheckedGroups] = useState([]);
    const [pastMessages, setPastMessages] = useState([]);
    const [futureMessages, setFutureMessages] = useState([]);
    const [selectedMessagesType, setSelectedMessagesType] = useState('pastMessages');

    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    // State management for message details
    const [messageDetails, setMessageDetails] = useState({
        messageContent: '',
        messageHeading: '',
        messageScheduleDate: minimumDateTime,
        messageSendType: 'now',
        messageURL: '',
        messageURLDescription: '',
        selectedImageID: undefined,
    });
    const [organizationImagesList, setOrganizationImagesList] = useState([]);
    const [isLoadingMessageComposer, setIsLoadingMessageComposer] = useState(true);

    const handleMessageTypeChange = (event, messageType) => {
        setSelectedMessagesType(messageType);
    };

    const stepsReference = useRef(steps);

    const fetchRegisteredOrganizationDetails = useCallback(async () => {
        try {
            const token = await getAuthToken(getAccessTokenSilently);
            const url = `organization/${salesforceId}`;

            // We'll receive a 404 if a register site isn't found
            const {
                data: {
                    siteID,
                },
            } = await customerDataMicroserviceRequest('get', apiBaseUrl, url, token);

            const updatedSteps = [...stepsReference.current];
            updatedSteps[0] = `${pageTitle} is registered for notifications`;

            // Progress to second step
            setActiveStep(1);

            // Rename first step title
            setSteps(updatedSteps);

            setRegisteredSiteID(siteID);
        } catch (error) {
            setRegisteredSiteID(null);
        }

        setIsLoading(false);
    }, [
        apiBaseUrl,
        getAccessTokenSilently,
        pageTitle,
        salesforceId,
        setActiveStep,
        setSteps,
    ]);

    const fetchRegisteredOrganizationMessages = useCallback(async () => {
        try {
            const token = await getAuthToken(getAccessTokenSilently);
            const url = `messages/${salesforceId}`;

            const {
                data: sentMessagesList = [],
            } = await customerDataMicroserviceRequest('get', apiBaseUrl, url, token);

            const now = new Date();

            const futureSendMessagesList = [];

            for (let index = sentMessagesList.length - 1; index >= 0; index--) {
                const {
                    [index]: {
                        sendAt,
                    },
                } = sentMessagesList;

                if (new Date(sendAt) > now) {
                    futureSendMessagesList.push(...sentMessagesList.splice(index, 1));
                }
            }

            setFutureMessages(futureSendMessagesList);
            setPastMessages(sentMessagesList);
        } catch (error) {
            toast.error('Error while fetching organization messages');
        }
    }, [
        apiBaseUrl,
        getAccessTokenSilently,
        salesforceId,
    ]);

    const fetchOrganizationImages = useCallback(async () => {
        try {
            const token = await getAuthToken(getAccessTokenSilently);
            const url = `images/${salesforceId}`;

            const {
                data: fetchedOrganizationImagesList = [],
            } = await customerDataMicroserviceRequest('get', apiBaseUrl, url, token);

            const preparedOrganizationImagesList = [];

            fetchedOrganizationImagesList.forEach((albumDetails) => {
                const {
                    imagesList = [],
                } = albumDetails;

                preparedOrganizationImagesList.push(...imagesList);
            });

            preparedOrganizationImagesList.sort((image1, image2) => image1.imageID - image2.imageID);

            setIsLoadingMessageComposer(false);

            return setOrganizationImagesList(preparedOrganizationImagesList);
        } catch (error) {
            toast.error('Unable to fetch available messaging images at this time');
        }

        setIsLoadingMessageComposer(false);

        return setOrganizationImagesList([]);
    }, [
        apiBaseUrl,
        getAccessTokenSilently,
        salesforceId,
        setOrganizationImagesList,
    ]);

    // Once we have a valid organization ID, we can try to fetch messages
    useEffect(() => {
        if (registeredSiteID) {
            fetchRegisteredOrganizationMessages();
            fetchOrganizationImages();
        }
    }, [
        fetchOrganizationImages,
        fetchRegisteredOrganizationMessages,
        registeredSiteID,
    ]);

    // Upon page load, try to fetch our registered organization details
    useEffect(() => {
        fetchRegisteredOrganizationDetails();
    }, [
        fetchRegisteredOrganizationDetails,
        salesforceId,
        organizationContractId,
    ]);

    useEffect(() => {
        stepsReference.current = steps;
    }, [steps]);

    const handleRegisterOrganization = async (event) => {
        event.preventDefault();

        try {
            const token = await getAuthToken(getAccessTokenSilently);
            const url = `organization/${salesforceId}`;

            const body = {
                siteName: pageTitle,
                siteTimeZone: timezone,
            };

            await customerDataMicroserviceRequest('post', apiBaseUrl, url, token, body);

            await fetchRegisteredOrganizationDetails();
        } catch (error) {
            toast.error('Please contact an LPi representative to register your organization');
        }
    };

    const sanitizeTextInput = (text) => text.trim().replace(/\s{2,}/g, ' ');

    const handleMessageSubmit = async (event) => {
        event.preventDefault();

        const {
            messageContent,
            messageHeading,
            messageScheduleDate,
            messageURL,
            messageURLDescription,
            selectedImageID,
            messageSendType,
        } = messageDetails;

        if (!checkedGroups.length) {
            return toast.error('Please select at least one notification group.');
        }

        try {
            setIsLoadingMessageComposer(true);

            const url = `message/${salesforceId}`;

            const sanitizedMessageURL = sanitizeTextInput(messageURL);

            const payload = {
                messageContent: sanitizeTextInput(messageContent),
                messageHeading: sanitizeTextInput(messageHeading),
                messageImageID: selectedImageID,
                messageScheduleDate: messageSendType === 'later' ? messageScheduleDate : undefined,
                messageTopicsList: checkedGroups,
                messageURL: sanitizedMessageURL || undefined,
                messageURLDescription: sanitizedMessageURL ? sanitizeTextInput(messageURLDescription) : undefined,
            };

            const token = await getAuthToken(getAccessTokenSilently);

            await customerDataMicroserviceRequest('post', apiBaseUrl, url, token, payload);

            const updatedSteps = [...steps];
            updatedSteps[1] = 'Your message has been sent!';

            setActiveStep(2);
            setSteps(updatedSteps);

            await fetchRegisteredOrganizationMessages(salesforceId, organizationContractId, token);
        } catch (error) {
            toast.error('Error while sending message');
        }

        return setIsLoadingMessageComposer(false);
    };

    const handleDeleteMessage = async (messageId) => {
        setIsDeleteModalOpen(true);
        setSelectedMessageId(messageId);
    };

    const deleteMessage = async () => {
        try {
            setIsDeleteModalOpen(false);
            const url = `message/${salesforceId}/${selectedMessageId}`;
            const token = await getAuthToken(getAccessTokenSilently);
            await customerDataMicroserviceRequest('delete', apiBaseUrl, url, token);
            await fetchRegisteredOrganizationMessages(salesforceId, organizationContractId, token);
        } catch (error) {
            toast.error('Error while deleting message');
        }
    };

    const notificationStepStyle = {
        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
        {
            color: 'green',
            fontSize: '14px',
        },

        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
        {
            fontSize: '14px',
        },
        '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel':
        {
            fontSize: '14px',
        },
        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fontSize: '14px',
            color: 'red',
        },
        '& Mui-disabled .MuiStepIcon-text': {
            fontSize: '14px',
            color: 'red',
        },
    };

    const paperStyles = {
        width: '100%',
        overflow: 'hidden',
    };

    const tableContainerStyles = {
        maxHeight: 600,
    };

    const tableStyles = {
        width: '100%',
    };

    const tableHeadStyles = {
        backgroundColor: 'black',
    };

    const tableRowStyles = {
        '& th': {
            fontSize: '12px',
            fontWeight: 'bolder',
        },
    };

    const lastRowStyles = {
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    };

    const getTableView = (messages) => (
        <Paper sx={paperStyles}>
            <TableContainer
                sx={tableContainerStyles}
                component={Paper}
            >
                <Table
                    stickyHeader
                    sx={tableStyles}
                >
                    <TableHead sx={tableHeadStyles}>
                        <TableRow sx={tableRowStyles}>
                            <TableCell>Message</TableCell>
                            <TableCell>Groups</TableCell>
                            {
                                selectedMessagesType === 'pastMessages' ? (
                                    <TableCell align="right">Sent At</TableCell>
                                ) : (
                                    <>
                                        <TableCell align="right">Scheduled For</TableCell>
                                        <TableCell align="right">Delete Message</TableCell>
                                    </>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages.map((sentMessageDetails) => (
                            <TableRow
                                key={sentMessageDetails.id}
                                sx={lastRowStyles}
                                align="right"
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                >
                                    {sentMessageDetails.messageContent}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                >
                                    {sentMessageDetails.topicNames}
                                </TableCell>
                                <TableCell align="right">
                                    {getDisplayDateTime(sentMessageDetails.sendAt)}
                                </TableCell>
                                {selectedMessagesType === 'scheduledMessages' && (
                                    <TableCell align="right">
                                        <Chip
                                            label="Delete"
                                            color="warning"
                                            onClick={() => handleDeleteMessage(sentMessageDetails.id)}
                                            variant="outlined"
                                            disabled={isHidden}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeleteModalDialog
                title="Delete this Message?"
                open={isDeleteModalOpen}
                handleCancel={() => setIsDeleteModalOpen(false)}
                handleDelete={() => deleteMessage()}
            />
        </Paper>
    );

    return (
        <div>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
            >
                {steps.map((label) => (
                    <Step
                        key={label}
                        sx={notificationStepStyle}
                    >
                        <StepLabel className="notification-step-label">{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {!isLoading && activeStep === 0 && (
                <form onSubmit={handleRegisterOrganization}>
                    <FormControl
                        margin="normal"
                        fullWidth
                    >
                        <TextField
                            disabled
                            fullWidth
                            label="Organization name"
                            margin="normal"
                            name="name"
                            required
                            value={pageTitle}
                        />
                        <Autocomplete
                            disableClearable
                            disablePortal
                            fullWidth
                            id="timezone-selector"
                            margin="normal"
                            name="timezone"
                            onChange={(event) => setTimezone(event.target.value)}
                            options={Intl.supportedValuesOf('timeZone')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Timezone"
                                    variant="outlined"
                                    margin="normal"
                                />
                            )}
                            value={timezone}
                            disabled={isHidden}
                        />
                        <ButtonGroup sx={{ marginTop: '20px' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isHidden}
                            >
                                Register Organization
                            </Button>
                        </ButtonGroup>
                    </FormControl>
                </form>
            )}
            {activeStep === 1 && (
                <>
                    <Typography
                        variant="h2"
                        mt={3}
                    >
                        Notification Groups
                    </Typography>
                    <GroupSelectionList
                        siteId={registeredSiteID}
                        checkedGroups={checkedGroups}
                        setCheckedGroups={setCheckedGroups}
                        salesforceId={salesforceId}
                        isHidden={isHidden}
                    />
                    {isLoadingMessageComposer ? (
                        <LinearProgress />
                    ) : (
                        <MessageComposer
                            handleMessageSubmit={handleMessageSubmit}
                            imagesList={organizationImagesList}
                            messageDetails={messageDetails}
                            setMessageDetails={setMessageDetails}
                            isHidden={isHidden}
                        />
                    )}
                </>
            )}
            <Box mt={5}>
                <Tabs
                    value={selectedMessagesType}
                    onChange={handleMessageTypeChange}
                    aria-label="wrapped label tabs example"
                >
                    <Tab
                        value="pastMessages"
                        label="Organization Past Messages"
                        disabled={isHidden}
                    />
                    <Tab
                        value="scheduledMessages"
                        label="Organization Scheduled Messages"
                        disabled={isHidden}
                    />
                </Tabs>
            </Box>
            <br />
            <Divider
                sx={{
                    fontSize: '12px',
                    textAlign: 'left',
                }}
            >
                {selectedMessagesType === 'pastMessages' ? 'Past' : 'Future'}
                {' '}
                Messages
            </Divider>

            {selectedMessagesType === 'pastMessages' ? getTableView(pastMessages) : getTableView(futureMessages)}
        </div>

    );
};

OrganizationsNotification.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    salesforceId: PropTypes.string.isRequired,
    organizationContractId: PropTypes.string,
    isHidden: PropTypes.bool,
};

OrganizationsNotification.defaultProps = {
    organizationContractId: undefined,
    isHidden: false,
};

export default OrganizationsNotification;
