import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Option = ({
    classes,
    value,
    ...otherProps
}) => (
    <option
        className={classNames(classes.root)}
        value={value}
        {...otherProps}
    />
);

Option.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Option.defaultProps = {
    classes: {
        root: null,
    },
};

export default Option;
