import { customerDataReqCustom } from '../services/api/customer-data';

const cacheInvalidation = async (url, token) => {
    await customerDataReqCustom('get', url, token, undefined, undefined, undefined, { 'Cache-Control': 'max-age=0' }).then(() => {
    }).catch((err) => {
        throw err;
    });
};

export { cacheInvalidation };
