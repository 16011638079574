export const PUBLICATION_FORMAT = 'ccc, LLL d';
export const FULL_PUBLICATION_FORMAT = 'cccc, LLL d, yyyy';

export const WIDGET_BACKGROUND_TRANSPARENT = 'transparent';
export const WIDGET_BACKGROUND_WHITE = 'white';
export const WIDGET_DEFAULT_COLOR = '#727272';
export const WIDGET_MAX_WIDTH_PX_MULTIPLE = '800px';
export const WIDGET_MAX_WIDTH_PX_SINGLE = '320px';
export const WIDGET_MAX_WIDTH_MULTIPLE = '80rem';
export const WIDGET_MAX_WIDTH_SINGLE = '32rem';
export const WIDGET_NUM_PUBS_MULTIPLE = '4';
export const WIDGET_NUM_PUBS_SINGLE = '1';
export const WIDGET_TEXT_LARGE = 'large';
export const WIDGET_TEXT_NORMAL = 'normal';
export const WIDGET_TYPE_BULLETIN = 'bulletin';
export const WIDGET_TYPE_NEWSLETTER = 'newsletter';
export const WIDGET_TYPE_PUBLICATION = 'publication';
export const WIDGET_UNIQUE_ID = 'PublicationWidget';

export const ALL_PUB_EXCLUSIONS = [
    // hide 'Bulletin' selection from customers
    // {
    //     publication_type_abbreviation: 'B',
    //     publication_type: 'Bulletin',
    // },
    {
        publication_type_abbreviation: 'C',
        publication_type: 'Cover',
    },
    // hide 'Directory' selection from customers
    // {
    //     publication_type_abbreviation: 'D',
    //     publication_type: 'Directory',
    // },
    {
        publication_type_abbreviation: 'F',
        publication_type: 'Flyer',
    },
    {
        publication_type_abbreviation: 'I',
        publication_type: 'Insert',
    },
    {
        publication_type_abbreviation: 'N',
        publication_type: 'Newsletter',
    },
    {
        publication_type_abbreviation: 'O',
        publication_type: 'Other',
    },
    {
        publication_type_abbreviation: 'R',
        publication_type: 'Resource Center',
    },
    {
        publication_type_abbreviation: 'S',
        publication_type: 'Special',
    },
    {
        publication_type_abbreviation: 'RV',
        publication_type: 'Varied Resource Center',
    },
];
