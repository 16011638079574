import { createContext } from 'react';

export const POL_THEME = 'POL';
export const MCO_THEME = 'MCO';

// Theme context, see https://reactjs.org/docs/context.html for details
export const ThemeContext = createContext(undefined);

export const ANCHOR_POSITIONS = {
    bottom: 'bottom',
    left: 'left',
    right: 'right',
    top: 'top',
};

export const COLORS = {
    default: 'default',
    primary: 'primary',
    secondary: 'secondary',
    flat: 'flat',
    gray: 'gray',
    lightgray: 'lightgray',
    white: 'white',
    outlined: 'outlined',
    colorSecondary: '#447e83',
    colorSecondaryDark: '#264550',
    colorAccent: '#008085',
    colorBondiBlue: '#00a5aa',
    colorSidebar: '#f0f0f0',
};

export const BACKGROUND_COLORS = {
    white: 'white',
    vistaWhite: 'vistaWhite',
};

export const SIZES = {
    xsmall: 'xsmall',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xlarge: 'xlarge',
    xxlarge: 'xxlarge',
};

export const ALIGN = {
    baseline: 'baseline',
    center: 'center',
    end: 'end',
    right: 'right',
    relative: 'relative',
};
