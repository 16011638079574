import {
    faMagnifyingGlass,
    faSliders,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import classNames from 'classnames';
import {
    Form,
    Formik,
} from 'formik';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import * as Yup from 'yup';

import * as routes from '../../../constants/routes';
import {
    LISTING_TYPE_ORGANIZATION,
    LISTING_TYPE_SPONSOR,
} from '../../../constants/site';
import {
    COLORS,
    MCO_THEME,
    POL_THEME,
    SIZES,
    ThemeContext,
} from '../../../constants/theme';
import customerDataRequest from '../../../services/api/customer-data';
import { loadSicCodeParentCategories } from '../../../services/listing';
import { useWindowSize } from '../../../utils/hooks';

import Button from '../Button';
import Autocomplete from '../FormElements/Autocomplete';
import FormField from '../FormElements/FormField';
import Select from '../FormElements/Select';
import TextField from '../FormElements/TextField';
import ModalDialog from '../ModalDialog/Base';
import Option from '../Option';
import SearchTag from '../SearchTag';

import styles from './SearchForm.scss';

export const CATEGORY_ALL = 'All';
export const CATEGORY_ALL_SUPPORTERS = 'All Supporters';
export const CATEGORY_COMMUNITY = 'Community';
export const CATEGORY_CATHOLIC = 'Catholic';
export const CATEGORIES_MCO = ['Lutheran', 'Jewish', 'Baptist', 'Synagogue', 'Temple', 'Orthodox', 'Eastern Orthodox',
    'Episcopal', 'Greek Orthodox', 'Non-denominational', 'Ukrainian Orthodox', 'Presbyterian',
    'Secular', 'United Church of Christ', 'Methodist', 'Pentecostal', 'Chamber of Commerce',
    'Community Center', 'Membership Organization', 'Municipality', 'Retirement Community',
    'Senior Agency', 'Senior Center',
];

const availableTypes = ['postcode', 'city', 'town', 'village', 'county', 'state', 'state_code'];

const QUERY_STRING_FIELDS = {
    searchLocation: 'searchLocation',
    searchName: 'searchName',
    searchCategory: 'searchCategory',
    pathName: 'pathName', // Used to reinitialize the form when the page changes
    searchType: 'searchType',
    timestamp: 'timestamp', // Used to allow the user to resubmit the form without changing values
};

const validLocationTest = [
    'validLocationTest',
    'Please enter a City, State, or Zip',
    function test() {
        const searchLocationFilled = Boolean(this.parent.searchLocation);
        return searchLocationFilled;
    },
];

const SearchForm = ({
    classes,
    isOnDarkBackground,
    isSupporterSearch,
    uniqueId,
    isHomeOrSupporters,
    isHidden,
}) => {
    const themeContext = useContext(ThemeContext);
    const history = useNavigate();
    const location = useLocation();
    const windowWidth = useWindowSize();
    const isSmallScreen = windowWidth < 770;

    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const locationSearchType = params.get(QUERY_STRING_FIELDS.searchType);

    const [parentCategories, setParentCategories] = useState({});
    const [isLoadingCategories, setLoadingCategories] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [locationOptions, setLocationOptions] = useState([]);
    const [isMounted, setIsMounted] = useState(true);
    const [isLoadingLocations, setLoadingLocations] = useState(false);
    const [isFiltersDialogOpen, setIsFiltersDialogOpen] = useState(false);

    const isSupporterSearchPageOrResults = isSupporterSearch || locationSearchType === LISTING_TYPE_SPONSOR;

    const nameInputLabel = () => {
        const supportersLabel = 'Business Name or Keyword';
        const organizationLabel = themeContext !== POL_THEME ? 'Community Name' : ' Church Name';

        return isSupporterSearchPageOrResults ? supportersLabel : organizationLabel;
    };

    // Alternate classnames for Home or Supporters page
    const formContainerClass = isHomeOrSupporters ? styles.formContainer__home : styles.formContainer;

    const loadCategoriesData = useCallback(async () => {
        if (isSupporterSearchPageOrResults) {
            await loadSicCodeParentCategories(setParentCategories, setLoadingCategories);
        }
    }, [isSupporterSearchPageOrResults]);

    // Disabled this rule due to debounce not working with deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getLocation = useCallback(debounce(async (text) => {
        if (!isMounted) return;
        setLoadingLocations(true);

        const searchParams = new URLSearchParams();

        searchParams.set('location', text);
        searchParams.set('limit', '5');
        searchParams.set('countrycode', 'US,CA');

        const apiUrl = `/location/?${searchParams.toString()}`;

        const response = await customerDataRequest('get', apiUrl, null, null, 15000);
        if (!isMounted) return;
        if (response && response.data) {
            const Data = response.data.data;
            // type in the response has dangling underscore
            // eslint-disable-next-line no-underscore-dangle
            const fiterData = Data.filter((el) => availableTypes.indexOf(el.components._type) !== -1 && el);
            const formatedData = fiterData.map((
                (d) => (d.components.country === 'United States' ? d.formatted.replace(/(.*),.*/, '$1') : d.formatted)
            ));
            setLocationOptions(formatedData);
        } else {
            setLocationOptions([]);
        }
        setLoadingLocations(false);
    }, 600), [setLocationOptions]);

    useEffect(() => {
        setIsMounted(true);
        return () => { setIsMounted(false); };
    }, []);

    useEffect(() => {
        loadCategoriesData();
    }, [loadCategoriesData]);

    useEffect(() => {
        if (searchText && searchText.length > 2) {
            getLocation(searchText);
        } else {
            setLocationOptions([]);
        }
    }, [searchText, getLocation]);

    const onSubmit = (values) => {
        const cleanName = values[QUERY_STRING_FIELDS.searchName]
            ? values[QUERY_STRING_FIELDS.searchName].trim()
            : '';
        const cleanLocation = values[QUERY_STRING_FIELDS.searchLocation].trim();
        const searchCategory = values[QUERY_STRING_FIELDS.searchCategory];

        if (!cleanName && !cleanLocation) {
            return;
        }

        const searchParams = new URLSearchParams();

        searchParams.set(QUERY_STRING_FIELDS.searchCategory, searchCategory);
        searchParams.set(QUERY_STRING_FIELDS.searchLocation, cleanLocation);
        searchParams.set(QUERY_STRING_FIELDS.searchName, cleanName);
        searchParams.set(QUERY_STRING_FIELDS.searchType, values[QUERY_STRING_FIELDS.searchType]);
        searchParams.set(QUERY_STRING_FIELDS.timestamp, Date.now().toString());

        const queryParams = searchParams.toString();

        history(`${routes.SEARCH}?${queryParams}`, { fromDashboard: true });
    };

    const initialValues = useMemo(() => {
        const queryParams = {};

        params.forEach((value, key) => {
            if (value === 'true') {
                queryParams[key] = true;
            } else if (value === 'false') {
                queryParams[key] = false;
            } else {
                queryParams[key] = value;
            }
        });

        let searchCategory = CATEGORY_CATHOLIC;
        let searchType = LISTING_TYPE_ORGANIZATION;

        if (themeContext === MCO_THEME) {
            searchCategory = CATEGORY_COMMUNITY;
        }

        if (isSupporterSearchPageOrResults) {
            searchCategory = queryParams[QUERY_STRING_FIELDS.searchCategory]
                ? queryParams[QUERY_STRING_FIELDS.searchCategory]
                : '';
            searchType = LISTING_TYPE_SPONSOR;
        }

        return {
            [QUERY_STRING_FIELDS.searchLocation]: queryParams[QUERY_STRING_FIELDS.searchLocation] || '',
            [QUERY_STRING_FIELDS.searchName]: queryParams[QUERY_STRING_FIELDS.searchName] || '',
            [QUERY_STRING_FIELDS.searchCategory]: searchCategory,
            [QUERY_STRING_FIELDS.pathName]: location.pathname, // Used to reinitialize the form when the page changes
            [QUERY_STRING_FIELDS.searchType]: searchType,
        };
    }, [
        params,
        themeContext,
        isSupporterSearchPageOrResults,
        location.pathname,
    ]);

    const searchSchema = Yup.object().shape({
        [QUERY_STRING_FIELDS.searchLocation]: Yup.string()
            .test(...validLocationTest),
    });

    return (
        <div
            className={classNames(styles.container, classes.root, {
                [styles.isOnDarkBackground]: isOnDarkBackground,
            })}
        >
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={searchSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onSubmit}
            >
                {({
                    errors,
                    setFieldValue,
                    touched,
                    values,
                }) => (
                    <Form
                        className={classes.form}
                        data-testid={`${uniqueId}-search-form`}
                    >
                        <div className={classNames(formContainerClass, isSupporterSearchPageOrResults && styles.supportersForm)}>
                            {isSupporterSearchPageOrResults && (
                                <>
                                    <Button
                                        otherClasses={styles.filtersButton}
                                        fullWidth
                                        id={`${uniqueId}-filters-button`}
                                        onClick={() => setIsFiltersDialogOpen(true)}
                                        disabled={isHidden}
                                        color={(!isHomeOrSupporters && !isSmallScreen) ? COLORS.white : COLORS.default}
                                    >
                                        <FontAwesomeIcon icon={faSliders} />
                                        <span className={styles.filtersButtonText}>
                                            {' '}
                                            Filter
                                            {!parentCategories[values.searchCategory] ? '' : ' (1)'}
                                        </span>
                                    </Button>
                                    <ModalDialog
                                        open={isFiltersDialogOpen}
                                        onClose={() => setIsFiltersDialogOpen(false)}
                                        title="Filter by Business Type:"
                                        content={(
                                            <Grid
                                                container
                                                spacing={{
                                                    xs: 1,
                                                    sm: 2,
                                                }}
                                            >
                                                <Grid
                                                    size={{
                                                        xs: 12,
                                                        sm: 9,
                                                    }}
                                                >
                                                    <FormField
                                                        component={Select}
                                                        fullWidth
                                                        id={`${uniqueId}-search-name-input`}
                                                        name={QUERY_STRING_FIELDS.searchCategory}
                                                        size={SIZES.large}
                                                        classes={{
                                                            root: styles.selectContainer,
                                                            loader: styles.selectLoader,
                                                        }}
                                                        marginBottom={false}
                                                        label="Business Type"
                                                        showLabel={false}
                                                        isLoading={isLoadingCategories}
                                                        onChange={(event) => {
                                                            setFieldValue('searchCategory', event.target.value);
                                                            setIsFiltersDialogOpen(false);
                                                        }}
                                                    >
                                                        <Option value="">
                                                            Select a category
                                                        </Option>
                                                        {Object.keys(parentCategories)
                                                            .sort((a, b) => parentCategories[a].localeCompare(parentCategories[b]))
                                                            .map((currentCategory) => (
                                                                <Option
                                                                    key={currentCategory}
                                                                    value={currentCategory}
                                                                >
                                                                    {parentCategories[currentCategory]}
                                                                </Option>
                                                            ))}
                                                    </FormField>
                                                </Grid>
                                            </Grid>
                                        )}
                                        showCloseButton
                                    />
                                </>
                            )}
                            <FormField
                                component={TextField}
                                fullWidth
                                id={`${uniqueId}-search-name-input`}
                                label={nameInputLabel()}
                                name={QUERY_STRING_FIELDS.searchName}
                                type="text"
                                marginBottom={false}
                                variant="filled"
                                showOptional={false}
                                disabled={isHidden}
                            />
                            <FormField
                                label={(
                                    <>
                                        City, State, or Zip
                                        {' '}
                                        <span style={{ fontStyle: 'italic' }}>(Required)</span>
                                    </>
                                )}
                                component={Autocomplete}
                                id={`${uniqueId}-search-location-input`}
                                fontSize={SIZES.small}
                                isLoading={isLoadingLocations}
                                name={QUERY_STRING_FIELDS.searchLocation}
                                errors={errors[QUERY_STRING_FIELDS.searchLocation]}
                                touched={touched[QUERY_STRING_FIELDS.searchLocation]?.toString() || 'false'}
                                type="text"
                                variant="filled"
                                options={locationOptions}
                                onChange={() => {}}
                                onInputChange={(newValue) => setSearchText(newValue?.trim())}
                                callGivenOnChangeWithFormik
                                disabled={isHidden}
                            />
                            <Button
                                otherClasses={styles.searchButton}
                                fullWidth
                                type="submit"
                                id={`${uniqueId}-search-button`}
                                aria-label="Search"
                                disabled={isHidden}
                                color={(!isHomeOrSupporters && !isSmallScreen) ? COLORS.white : COLORS.default}
                            >
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                <span className={styles.searchButtonText}>&nbsp;Submit</span>
                            </Button>
                        </div>
                        {isSupporterSearchPageOrResults
                            && (isHomeOrSupporters || isSmallScreen)
                            && (
                                !!parentCategories[values.searchCategory] && (
                                    <Box className={styles.appliedFilters}>
                                        <SearchTag
                                            label={parentCategories[values.searchCategory]}
                                            handleDelete={() => setFieldValue('searchCategory', '')}
                                        />
                                    </Box>
                                )
                            )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

SearchForm.propTypes = {
    classes: PropTypes.shape({
        form: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    isOnDarkBackground: PropTypes.bool,
    isSupporterSearch: PropTypes.bool,
    uniqueId: PropTypes.string.isRequired,
    isHomeOrSupporters: PropTypes.bool,
    isHidden: PropTypes.bool,
};

SearchForm.defaultProps = {
    classes: {
        form: null,
        root: null,
    },
    isOnDarkBackground: false,
    isSupporterSearch: false,
    isHomeOrSupporters: false,
    isHidden: false,
};

export {
    QUERY_STRING_FIELDS,
};

export default SearchForm;
