import { bool, shape, string } from 'prop-types';

const SocialType = shape({
    websiteUrl: string,
    youtubeUrl: string,
    facebookUrl: string,
    instagramUrl: string,
    twitterUsername: string,
    liveStreaming: bool,
});

export default SocialType;
