import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ModalDialog.scss';

const ModalDialog = ({
    open,
    onClose,
    title,
    content,
    buttons, // If passing a node, the node must contain the buttons as children.
    showCloseButton,
    ...otherProps
}) => {
    const buttonsArray = React.Children.toArray(buttons);

    // Make buttons uppercase and add styles
    const styledButtons = buttonsArray.map((button) => React.cloneElement(button, {
        otherClasses: styles.button,
        key: button.key || Math.random().toString(), // Ensure each button has a unique key
    }));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="xs"
            {...otherProps}
        >
            <DialogTitle>{title}</DialogTitle>
            {showCloseButton && (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </IconButton>
            )}
            {!!content && (
                <DialogContent>
                    {content}
                </DialogContent>
            )}
            {!!buttons && (
                <DialogActions>
                    {styledButtons}
                </DialogActions>
            )}
        </Dialog>
    );
};

ModalDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string.isRequired,
    buttons: PropTypes.oneOfType([
        PropTypes.node, // If passing a node, the node must contain the buttons as children.
        PropTypes.arrayOf(PropTypes.node),
    ]),
    showCloseButton: PropTypes.bool,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

ModalDialog.defaultProps = {
    buttons: null,
    content: null,
    onClose: () => {},
    showCloseButton: false,
};

export default ModalDialog;
