import {
    SITE_NAME_MCO,
    SITE_NAME_POL,
} from './site';

const mcoOrganizationView = (organization) => `Get updates on the latest happenings at ${organization.physical_title} in ${organization.physical_city} ${organization.physical_state}. Find the latest newsletters and more.`;

const polOrganizationView = (organization) => `Get updates on the latest happenings at ${organization.physical_title} in ${organization.physical_city} ${organization.physical_state}. Find the latest bulletins, weekly mass times, and more.`;

const mcoPublicationView = (organizationTitle, publicationDate) => `View the newsletter for ${publicationDate} from ${organizationTitle}`;

const polPublicationView = (organizationTitle, publicationDate) => `View the bulletin for ${publicationDate} from ${organizationTitle}`;

const mcoSupportersView = (name) => `View supporters for ${name}. Find businesses, organizations, and individuals supporting your local community.`;

const polSupportersView = (name) => `View supporters for ${name}. Find businesses, organizations, and individuals supporting your local parishes.`;

const mcoSubscribeView = (organization) => `Subscribe to ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} to receive organization publications and updates.`;

const polSubscribeView = (organization) => `Subscribe to ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} to receive parish publications and updates.`;

const mcoUnsubscribeView = (organization) => `Unsubscribe from ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} to stop receiving organization publications and updates.`;

const polUnsubscribeView = (organization) => `Unsubscribe from ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state} to stop receiving parish publications and updates.`;

const polEmailView = (organization, date) => `Email articles for ${date} from ${organization.physical_title} - ${organization.physical_city}, ${organization.physical_state}. Stay subscribed to receive future bulletins.`;

export const Descriptions = {
    MCO_404: 'The page you\'re looking for could not be found.',
    MCO_500: 'The server encountered an error while processing your request.',
    MCO_ABOUT: `Staying connected with your local organization has never been easier. View latest newsletters and sign up for e-mail updates from your community. Discover local businesses you can trust, every business listed with ${SITE_NAME_MCO} supports your local community.`,
    MCO_ADVERTISERS: `Explore advertising opportunities with ${SITE_NAME_MCO}. Reach your local community and connect with potential customers.`,
    MCO_AD_FORM: `Interested in advertising on ${SITE_NAME_MCO}? Fill out this form and we'll reach out to get you started!`,
    MCO_HELP: `Explore the FAQ section to find answers to frequently asked questions. Find answers to what you want to know about ${SITE_NAME_MCO}.`,
    MCO_HOME: `Discover ${SITE_NAME_MCO}, the comprehensive online directory of communities. Connect with local organizations, businesses, and members of your community.`,
    MCO_ORGANIZATION: mcoOrganizationView,
    MCO_PUBLICATION: mcoPublicationView,
    MCO_SEARCH: 'Search our database of organizations and connect to your local community.',
    MCO_SETTINGS: `Access the Management Console on ${SITE_NAME_MCO} to manage your community settings efficiently.`,
    MCO_SUBSCRIBE: mcoSubscribeView,
    MCO_SUPPORTERS: `Discover supporters of ${SITE_NAME_MCO}. Explore the directory to find businesses, organizations, and individuals who contribute to your local community.`,
    MCO_SUPPORTERS_LIST: mcoSupportersView,
    MCO_SUPPORTERS_SEARCH: 'Search our database of supporters and find businesses in your local community.',
    MCO_TERMS: `Terms and conditions of using the ${SITE_NAME_MCO} platform`,
    MCO_UNSUBSCRIBE: mcoUnsubscribeView,
    MCO_WIDGET: 'Explore the latest newsletters from your local communities and stay connected.',
    POL_404: 'The page you\'re looking for could not be found.',
    POL_500: 'The server encountered an error while processing your request.',
    POL_ABOUT: `Staying connected with your local parish has never been easier. View latest bulletins and sign up for e-mail updates from your church. Discover local businesses you can trust, every business listed with ${SITE_NAME_POL} supports your local community.`,
    POL_ADVERTISERS: `Discover how you can advertise with ${SITE_NAME_POL} to reach local communities through church bulletins and more.`,
    POL_AD_FORM: `Interested in advertising on ${SITE_NAME_POL}? Fill out this form and we'll reach out to get you started!`,
    POL_EMAIL_CONTENT: polEmailView,
    POL_HELP: `Explore the FAQ section to find answers to frequently asked questions. Find answers to what you want to know about ${SITE_NAME_POL}.`,
    POL_HOME: `Explore ${SITE_NAME_POL} to stay connected with your faith community, no matter where life takes you. Discover church bulletins, parish events, and more.`,
    POL_ORGANIZATION: polOrganizationView,
    POL_PUBLICATION: polPublicationView,
    POL_SEARCH: 'Search our database of organizations and connect to your local parishes.',
    POL_SETTINGS: `Access the Management Console on ${SITE_NAME_POL} to manage your account settings efficiently.`,
    POL_SUBSCRIBE: polSubscribeView,
    POL_SUPPORTERS: `Discover supporters of ${SITE_NAME_POL}. Explore the directory to find businesses, organizations, and individuals who contribute to your local parishes.`,
    POL_SUPPORTERS_LIST: polSupportersView,
    POL_SUPPORTERS_SEARCH: 'Search our database of supporters and find businesses in your local community.',
    POL_TERMS: `Terms and conditions of using the ${SITE_NAME_POL} platform.`,
    POL_UNSUBSCRIBE: polUnsubscribeView,
    POL_WIDGET: 'Explore the latest bulletins from your local parishes and stay connected.',
};
