import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './RecentPublicationsSection.scss';
import { COLORS } from '../../constants/theme';
import Loader from '../shared/Loader';
import PDFViewer from '../shared/PDFViewer';
import { getPrettyPublishDate, sortPublicationByPublishDate } from '../../utils/date';
import { getPublicationPage } from '../../utils/routes';
import { PUBLICATION_DISPLAY_LIMIT } from '../publications-manager/publications-view';
import { capitalizeFirstLetter } from '../../utils/string';

function getPublicationUrl(publication, slug, isPOLDomain) {
    const mcoPublicationUrl = publication.fileUrl.replace('parishesonline', 'mycommunityonline');
    return getPublicationPage(slug, (isPOLDomain ? publication.fileUrl : mcoPublicationUrl));
}

const RecentPublicationsSection = ({
    publications,
    viewerPublication,
    setViewerPublication,
    isLoading,
}) => {
    const displayPublications = sortPublicationByPublishDate(publications, PUBLICATION_DISPLAY_LIMIT);
    const [isBulletinFullscreen, setIsBulletinFullscreen] = useState(false);
    const { slug } = useParams();

    let { hostname } = window.location;
    hostname = hostname.toLowerCase();
    hostname = hostname.replace('www.', '');

    const isPOLDomain = hostname === process.env.POL_HOSTNAME;

    return (
        <div className={styles.container}>
            {isLoading && <Loader color={COLORS.secondary} />}
            {viewerPublication && (
                <PDFViewer
                    filePath={viewerPublication && viewerPublication.fileUrl}
                    fullscreen={isBulletinFullscreen}
                    onFullscreenButtonClick={setIsBulletinFullscreen}
                    onCloseViewerButtonClick={setViewerPublication}
                />
            )}
            <h2 className={styles.header}>Recent Publications</h2>
            <div className={styles.publicationsContainer}>
                {displayPublications.map((currentPublication) => (
                    <div key={currentPublication.publicationId
                        || `${currentPublication.name}-${currentPublication.uploadedDate}`}
                    >
                        <a
                            href={getPublicationUrl(currentPublication, slug, isPOLDomain)}
                            rel="noreferrer"
                        >
                            <img
                                alt="publication"
                                src={currentPublication.smallThumbnailUrl}
                                width="307"
                                height="397"
                            />
                        </a>
                        <div className={styles.publicationMetaContainer}>
                            <div className={styles.publishDate}>
                                {getPrettyPublishDate(currentPublication)}
                            </div>
                            <div>{capitalizeFirstLetter(currentPublication.type)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

RecentPublicationsSection.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
    }),
    publications: PropTypes.arrayOf(PropTypes.shape({
        fileUrl: PropTypes.string,
        name: PropTypes.string,
        publishDate: PropTypes.string,
        largeThumbnailUrl: PropTypes.string,
        smallThumbnailUrl: PropTypes.string,
        largeThumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        smallThumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
        type: PropTypes.string,
    })),
    viewerPublication: PropTypes.shape({ fileUrl: PropTypes.string.isRequired }),
    setViewerPublication: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

RecentPublicationsSection.defaultProps = {
    classes: {
        root: null,
    },
    publications: [],
    viewerPublication: null,
    isLoading: false,
};

export default RecentPublicationsSection;
